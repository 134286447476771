
// import {MatRadioModule} from '@angular/material/radio';



import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injectable } from '@angular/core';
// import { StorageServiceModule} from 'angular-webstorage-service';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';


// import {TrainModule} from './train/train.module';

import {MobileModule} from './mobile/mobile.module';
import {VerticalImageComponent} from './vimage.component';
// import { ConfigModule } from './config/config.module';
//import { MaterialModule } from "@app/material-module/material.module";

import {CoreModule} from './core/core.module'
import { SettingsModule } from './settings';
import { StaticModule } from './static';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent} from './app.component';
// import { SignupDialog } from './login/signup.component';
// import { LoginDialog  } from './login/login.component';
import { SignupComponent  } from './signup/signup.component';


import { ContactustComponent} from './contact-us/contactus.component';

import { SubscriptionsComponent } from './subscription/subscriptions/subscriptions.component';
import { CheckoutComponent } from './subscription/checkout/checkout.component';

// import { BbjComparionChartComponent } from './bbj-comparion-chart/bbj-comparion-chart.component';


// import {TraincopyComponent} from './train-copy/traincopy.component';
import {videoComponent} from './video-component/video.component';
// import { MatVideoModule } from 'mat-video';
 import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceWorkerModule } from '@angular/service-worker';
//import * as Hammer from 'hammerjs';
import { HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG}  from '@angular/platform-browser';
import { environment } from '../environments/environment';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  //overrides = <any> {
 override  overrides =  {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}
@NgModule({
    imports: [
        BrowserAnimationsModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgOverlayPlayModule,
        VgCoreModule,

        BrowserModule,
        // StorageServiceModule,
        CoreModule,
        // features
        StaticModule,
        SettingsModule,
        AppRoutingModule,
        MobileModule,
        NgbModule,
        ShareButtonsModule,
        ShareIconsModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        HammerModule,
    ],
    declarations: [
      // StorageServiceModule,
        VerticalImageComponent,
        AppComponent,
        SubscriptionsComponent,
        CheckoutComponent,
        ContactustComponent,
        SignupComponent,
        videoComponent,
    ],
    providers: [
        // UserService,
        // SocketIoService,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig
        },
        //  AnimationListComponent,
        // { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
