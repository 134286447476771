import { Action } from '@ngrx/store';
import { type } from '../core/util';
import { User } from '../models/user';


export const ActionTypes = {
  AUTHENTICATE: type('[users] Authenticate'),
  AUTHENTICATE_ERROR: type('[users] Authenticate Error'),
  AUTHENTICATE_SUCCESS: type('[users] Authenticate Success'),
  AUTHENTICATED: type('[users] Authenticated'),
  AUTHENTICATED_ERROR: type('[users] Authenticated Error'),
  AUTHENTICATED_SUCCESS: type('[users] Authenticated Success'),
  SIGN_OUT: type('[users] sign out'),
  SIGN_OUT_ERROR: type('[users] sign out error'),
  SIGN_OUT_SUCCESS: type('[users] sign out success'),
  SIGN_UP: type('[users] sign up'),
  SIGN_UP_ERROR: type('[users] sign up error'),
  SIGN_UP_SUCCESS: type('[users] sign up success'),

};

export interface ActionIF {
  type: string;
  payload?: any;
}

export class AuthenticateAction implements Action {

  public type: string = ActionTypes.AUTHENTICATE;

  constructor ( public payload: { email: string, password: string }) {}

}

export class AuthenticatedAction implements Action {

  public type: string = ActionTypes.AUTHENTICATED;

  constructor( public payload?: { token? : string  } ) {}
}


export class AuthenticatedSuccessAction implements Action {

  public type: string = ActionTypes.AUTHENTICATED_SUCCESS;

  constructor( public payload: { authenticated: boolean, user: User  }) { }
}

export class AuthenticatedErrorAction implements Action {

  public type: string = ActionTypes.AUTHENTICATED_ERROR;

  constructor( public payload?: any ) { }
}

export class AuthenticateErrorAction implements Action {

  public type: string = ActionTypes.AUTHENTICATE_ERROR;

  constructor ( public payload?: any ) { }
}

export class AuthenticateSuccessAction implements Action {

  public type: string = ActionTypes.AUTHENTICATE_SUCCESS;

  constructor( public payload: { user: User }) { }
}

export class SignOutAction implements Action {

  public type: string = ActionTypes.SIGN_OUT;

  constructor( public payload?: any ) { }
}

export class SignOutErrorAction implements Action {

  public type: string = ActionTypes.SIGN_OUT_ERROR;

  constructor( public payload?: any ) { }
}

export class SignOutSuccessAction implements Action {

  public type: string = ActionTypes.SIGN_OUT_SUCCESS;

  constructor( public payload?: any ) { }
}

export class SignUpAction implements Action {

  public type: string = ActionTypes.SIGN_UP;

  constructor( public payload: { user: User } ) { }

}

export class SignUpErrorAction implements Action {

  public type: string = ActionTypes.SIGN_UP_ERROR;

  constructor( public payload?: any ) { }

}

export class SignUpSuccessAction implements Action {

  public type: string = ActionTypes.SIGN_UP_SUCCESS;

  constructor( public payload: { user: User } ) { }

}

export type Actions =
  AuthenticateAction
    | AuthenticatedAction
    | AuthenticatedErrorAction
    | AuthenticatedSuccessAction
    | AuthenticateErrorAction
    | AuthenticateSuccessAction
    | SignOutAction
    | SignOutErrorAction
    | SignOutSuccessAction
    | SignUpAction
    | SignUpErrorAction
    | SignUpSuccessAction;
