
     

     <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"  >
        <div ngxSlickItem class="slide">

                 <img style="margin-top:10px" src="{{images[0]}}">
                 <mat-icon class="arrows-left"> navigate_before </mat-icon>
                 <mat-icon class="arrows-right"> navigate_next </mat-icon>
        </div>
        <div ngxSlickItem class="slide" *ngIf="slideit" >
               <img style="margin-top:10px" src="{{images[1]}}">
                 <mat-icon class="arrows-left"> navigate_before </mat-icon>
                 <mat-icon class="arrows-right"> navigate_next </mat-icon>
        </div>
        <div ngxSlickItem class="slide" *ngIf="slideit" >
               <img style="margin-top:10px" src="{{images[2]}}">
                 <mat-icon class="arrows-left"> navigate_before </mat-icon>
                 <mat-icon class="arrows-right"> navigate_next </mat-icon>
        </div>

    </ngx-slick-carousel>
