import { MobileBetDataUnit} from './bet.model';
import { Action } from '@ngrx/store';

const INSERT = 'insert';

 class InsertMobileBetAction implements Action {
    readonly type = INSERT;
    constructor(public payload: MobileBetDataUnit) { }
}

export function MobileBetReducer(State = {}, action: InsertMobileBetAction) {
    return action.type==INSERT? action.payload: {};
};

export function MobileBetAction (store: any, hands: any, count: any) {
  store.dispatch( new InsertMobileBetAction ({hands: hands, count: count,  type:'MobileBetDataUnit'}));
}
