import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription}  from 'rxjs';
import { SendUserHaveCredit } from '../../store/gen-setting.action.reducer';
import {KeyPressedDm} from '../../mobile/store//bottom.model'

import { RouterService, globalVars} from '../../services/global.service';
import {MobileSendKeyRoute, BottomAppleModify} from '../../mobile/store/bottom.action.reducer';
import { UserService} from '../../services/user.service';

import { Store } from '@ngrx/store';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { map, filter } from 'rxjs/operators';
import {GenericTopTopAction} from '../../mobile/store/generic.action.reducer';

const SIX_MONTHS_SUBSCRIPTION=false;

@Component({
  selector: 'anms-subscriptions',
  templateUrl: 'subscriptions.component.html',
  styleUrls: ['subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit, OnDestroy {
@ViewChild('slickModal') slickModal: SlickCarouselComponent;


 prodObj: any;
 categories=[];
 slideConfig = {
  "slidesToShow": 1,
  "slidesToScroll": 1,
  "dots": false,
  "infinite": false,
  'arrows': false,
  'swipeToSlide': true,
  // centerMode:true
};
duration:'';
paymentState='';
cpage=SIX_MONTHS_SUBSCRIPTION? 5: 0;
checkoutIcon='add_circle';
// the index here represents the page number
productMap={
  0:'SixMonths',
  1: 'Monthly',
  2:'2-Weeks',
  3: 'Weekly',
  4: 'Daily',
  5:'Hourly'
};
productId= {
  'SixMonths':'price_1Pa2NDGHzOYpdSZnCH5tVXF0',
  'Monthly':  'price_1Pa2xQGHzOYpdSZnsBP7Ykbp',
  'Weekly':   'price_1Pa2uuGHzOYpdSZnz4HTzvNg',
  '2-Weeks':  'price_1Pa2pnGHzOYpdSZnomFEK5Yw',
  'Daily':    'price_1Pa2sCGHzOYpdSZnlcvRp2YF',
  'Hourly':   'price_1Pa2kvGHzOYpdSZnFaviZlBw'
};

subscriptsKeys=['type', 'desc', 'price'];
products= {
  SixMonths:{
    "type": "monthly",
    "desc": "Six-months subscription",
    "price": 199.99,
  },
    Monthly:
     {
       "type": "monthly",
       "desc": "One-month subsctiption",
       "price": 49.99,
     },
     Weekly:
     {
       "type": "weekly",
       "desc": "One-week subscription",
       "price": 19.99,

     },
     '2-Weeks':
     {
       "type": "2-weeks",
       "desc": "Two-week subscription",
       "price": 29.99,

     },
     Daily:
     {
       "type": "daily",
       "desc": "One-day subscription",
       // "price": 14.99,
       "price": 5.99,
     },
     Hourly:
     {
       "type": "hourly",
       "desc": "Two-hour subscription",
       // "price": 14.99,
       "price": 1.99,
  }

};
checkout=false;
confirmation= {
  Desc:'',
  Access:'',
  Total:''
};
productsLength=0;
productKeys = [];
// ckeys=['Desc', 'Total'];
// ekeys=['package',  'availablity',  'price'];
// addingTemp = "Adding xxx access";
// device='desktop';
 finalProduct={};
 paymentMessage='';
 subscriptions=[];
 vars=globalVars;
 disabled={forward:false,backward:true, done:false};
 constructor(
             private store : Store<any>,
             private routerService: RouterService,
             private userService: UserService
            ) {
               this.productKeys = Object.keys(this.products);
               this.productsLength = this.productKeys.length-1;
              }


  ngOnInit1() :void{
    setTimeout(() => {
      GenericTopTopAction(this.store, ['Choose your class recommended'], 'tigger');
    }, 200);
      // if (globalVars['credit']>0) { // this should never execute
      //     alert("You already have a credit.");
      //     this.routerService.navigate('mobile/train');
      // }
      this.subscriptions.push(
              this.store
                .select('mobileTopBottom')
                .pipe(filter(event => event.typeof=== 'KeyPressedDm'))
                .subscribe((data: KeyPressedDm) => {
                  if (data.key=='backward') {
                    this.routerService.navigate('mobile/train');
                     return;
                  }
                })
              );
      BottomAppleModify(this.store, { disable:['forward'], enable:['menu', 'backward']});
      // this.productSelected=this.products['both'];

   }

   ngOnInit() :void {
     const keys = Object.keys(this.products);
     this.disabled['forward'] = this.productsLength  == this.cpage;
     setTimeout(() => {
       this.slickModal.slickGoTo(this.cpage);

     }, 100);
     // alert(`length:${keys.length}, cpage:${this.cpage}, disabled:${this.disabled['forward']}`)
     setTimeout(() => {
       GenericTopTopAction(this.store, ['Choose your class recommended'], 'tigger');
     }, 200);
       // if (globalVars['credit']>0) { // this should never execute
       //     alert("You already have a credit.");
       //     this.routerService.navigate('mobile/train');
       // }
       this.subscriptions.push(
               this.store
                 .select('mobileTopBottom')
                 .pipe(filter(event => event.typeof=== 'KeyPressedDm'))
                 .subscribe((data: KeyPressedDm) => {
                   if (data.key=='backward') {
                     this.routerService.navigate('mobile/train');
                      return;
                   }
                 })
               );
       BottomAppleModify(this.store, { disable:['forward'], enable:['menu', 'backward']});
       // this.productSelected=this.products['both'];

    }
  ngOnDestroy() :void{
    for (const subs of this.subscriptions) {
	       // this.subscriptions[key].unsubscribe();
         subs.unsubscribe();

     }
  }


  confirm(){
    const confirmation=this.confirmation;
    const product        = this.finalProduct;
    confirmation.Desc    = product['desc'];
    confirmation.Access  = "Mobile, desktop, tablet, laptop";
    confirmation.Total   = `$${product['price']}`;

  }




// Create a Checkout Session as soon as the page loads

  clicked(key) {
     switch(key) {
       case 'done': { // plus is pressed
         // this.disabled.backward=true;
         // this.disabled.forward=true;
              this.disabled.done=true;
              // alert(`page:${this.cpage}, productmap:${this.productMap[this.cpage]}`);
              this.finalProduct=this.products[this.productMap[this.cpage]];
              globalVars['product']=this.productId[this.productMap[this.cpage]];
              // alert(`product:${globalVars['product']}, cpage:${this.cpage}`)
              this.routerService.navigate('checkout');
      	     return ;

       }
       case 'forward': {
         this.checkout=false;
         this.cpage++;
          this.slickModal.slickNext();
    	    if (this.cpage==this.productsLength) {
            this.disabled.forward=true;
          }
          this.disabled.backward=false;
    	    break;

       }
       case 'backward': {
	    // if (this.checkout) {
	    //     this.checkout=false;
	    //     this.slickModal.slickGoTo(0);
	    //   	return ;
	    // }
      this.checkout=false;
	    this.cpage--;
      if (this.cpage==0) {
        this.disabled.backward=true;
      }
      this.disabled.forward=false;
      this.slickModal.slickPrev();
        this.checkoutIcon='add_circle';
	    break;

       }
     }
  }

}
