import { Component} from '@angular/core';
@Component({
  selector: 'anms-nl-cells',
  templateUrl: './numbercells.component.html',
   styleUrls: ['./styles.scss'],
})

export class NumbercellsComponent  {

  constructor() {}

}
