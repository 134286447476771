import { Component, Input} from '@angular/core';
// border-bottom: 5px solid  #696969 !important;

@Component({
  selector: 'underline',
  template: `{{label}}:<span class="border" style="color:{{color}}; font-size:{{font}}; margin-left:10px"> <i>{{value}}</i> </span>`,
  styles: [
		`
		   .border {
          text-align:center;
          width:300px;
          height:200px;
	     }

			 `
		],
})

export class UnderlineComponent  {
  @Input() label: string;
  @Input() value: string;
  @Input() color: string;
  @Input() font : string;

  constructor() { }
}
