
import {CardDm} from '../../../store/switchcards.model';
import { timer, Observable,Subscription, Subject  } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
// import * as _ from 'lodash';
import {Flash} from '../../../services/common.service';
import { globalVars, isJack} from '../../../services/global.service';
const IDLE=0;

const    str= (item) => { return `${item[0]}${item[1]}`; }
const get_range = ()=> {
  let range=0;
  switch(globalVars['ndecks']) {
    // case 1: return 19;
    case 1: return 9;

    case 2: return 29;
    case 6: return 49;
    default: return 29;
  }
}

export interface NetCountRandNums {
	count: string;
	cvalue:string;
	stmt:Array<string>;
	list: Array<string>;
};
const NonZeroRandomInRange = (range, constraint=0) => {
     let rvalue;
     while(true) {
	       rvalue= rand_range(range);
         if (rvalue==constraint) continue;
	      if (rvalue) break;
     }
     return rvalue;
};
const rand_range=(range) => {
  return Math.floor(Math.random() * range);
}

const getJackList=(low, high) => {
  if (low+high==low) {
     // high=0
     return [join(low, high), `${low+1}L`, `${low}H`, `${low-1}L`];
  }   if (low+high==high) {
       // low=0
          return [join(low, high), `${high+1}H`, `${high}L`, `${low-1}H`]
    }
  //
  let minus;
  if (low==1 && high==1) {
    minus=rand_range(2)? join(low+2, high): join(low, high+2);
  } else if (low==1){
      minus = join(low, high-1);
  } else if (high==1){
      minus = join(low-1, high);
  } else {
    minus=rand_range(2)? join(low-1, high): join(low, high-1);
  }
  return [join(low,high), join(low+1,high), join(low,high+1), minus];
}
const getJackNetList=(net) => {
  if (net=='0') {
    return [net, '1L', '1H',rand_range(2)?'2L':'2H'];
  }
  if (net.includes('L')) {
    let [value, sign] = net.split('L');
    value=+value;
    return [net, `${value+1}L`, `${value-1}L`, `${value}H`];
  }
  let [value, sign] = net.split('H');

    value=+value;
    return [net, `${value+1}H`, `${value-1}H`, `${value}L`];
}
const netcountMultiplechoiceJack = (low,high): NetCountRandNums => {

	const diff=Math.abs(low-high);
	const count = `${high}${globalVars['join']}${low}`;
	const Low='L';
	const High='H';
	if (!diff) {
	  return {count: count,
		       stmt:[`${low}-${high}`, '0'],
		       cvalue:'0', list:shuffleList(['0', '1'+High, '1'+Low,rand_range(2)? '2'+High: '2'+Low])};
	}
	const sign=low<high? High: Low;
	const rsign=sign==Low? High: Low;
	let randSign= rand_range(2)? High: Low;
	let randOperation= rand_range(2)? 'Minus': 'Add';
	if ( randOperation=='Minus') {
	   randOperation=(low==1 && randSign==Low) || (high==1 && randSign==High)? 'Add': randOperation;
	}
	const _diff=randOperation=='Minus'? diff-1: diff+1;
	const rrandSign=randSign==Low? High: Low;
  return {count: count,
          stmt:  low>high? [`${low}-${high}`,`${diff}${sign}`]: [`${high}-${low}`,`${diff}${sign}`],
          cvalue: `${diff}${sign}`,
		      list:shuffleList([`${diff}${sign}`, `${diff}${rsign}`, `${_diff}${randSign}`, `${_diff}${rrandSign}`])};
};
// for single deck
const netcountMultiplechoiceSingle = (low,high): NetCountRandNums => {
  high=high%10;
  low=low%10;
	const diff=Math.abs(low-high);
	const count = `${high*10+low}`;
	if (!diff) {
	  return {count: count,
		       stmt:[`${low}-${high}`, '0'],
		       cvalue:'0', list:shuffleList(['0', '10', '1',rand_range(2)? '20': '2'])};
	}
	let xvalue=[];
	let cvalue;
	if (high>low) {
	   xvalue.push(diff==1? diff+1*10: (diff-1)*10, (diff+2)*10);
	   cvalue=diff*10;
	} else {
	   xvalue.push(diff==1? diff+1: diff-1, diff+2);
	   cvalue=diff;

	}
        return {count: count,
          stmt:  low>high? [`${low}-${high}`,`${diff}`]: [`${high}-${low}`,`${diff*10}`],
          cvalue: `${cvalue}`,
		      list:shuffleList([`${diff}`, `${diff*10}`, `${xvalue[0]}`, `${xvalue[1]}`])};
};

const netcountMultiplechoice= (low,high): NetCountRandNums => {
	return isJack()? netcountMultiplechoiceJack(low,high): netcountMultiplechoiceSingle(low,high);
};



export const CreateUniqueRandomCount = (size=16) => {
  const list=[];
  const range = get_range();
  while (size--) {
    const low = NonZeroRandomInRange(range);
    const high = NonZeroRandomInRange(range, low);
    list.push(netcountMultiplechoice(low,high));
  }
  return list;
}

// export const RandomCardCount = () => {
//   const range = get_range();
//   let low = rand_range(range);
//   let high = rand_range(range);
//   if (!low) low=1;
//   if (!high) high=1;
//   return join(low, high);
// }



export class Button {
  disabled: boolean;
  status: number;
  color: string;
  icon: boolean;
  name: string;
  flash: Flash;
  changed?: boolean;
  reset() {
    this.disabled = true;
    this.status=IDLE;
    this.color='primary';
    this.icon=false;
  }
  constructor(name: string) {
    this.reset();
    this.name = name;
    this.flash  = new Flash();
  }

  flashit() { this.flash.flash(this);}
  unflash() { this.flash.unflash();}
  assign(obj) {
      for (const key in obj) {
        switch(key) {
          case 'disabled' : { this.disabled = obj[key]; break;}
          case 'status' : { this.status = obj[key]; break;}
          case 'name' : { this.name = obj[key]; break;}
          case 'color' : { this.color= obj[key]; break;}
          case 'icon' : { this.icon = obj[key]; break;}

        }
      }
 }

}
export enum GroupButtonsEnum {one, all};

export class GroupButtons {
  buttons:any;
  length:number;
  mode: GroupButtonsEnum;
  flashed: string;

  constructor(buttons, mode, keys=[]) {
    this.buttons={};
    for(const button of buttons) {
      if (keys.length) {// if button names are not unique
         this.buttons[keys.shift()] = button;
      } else {
        this.buttons[button.name] = button;
      }
    }
    this.mode = mode;
    this.flashed='';
  }

  get(key) { return this.buttons[key];}

  flash(name) {
    if (this.flashed == name) return;
    const button = this.buttons[name];
    switch (this.mode) {
      case GroupButtonsEnum.one: {
        let timeout=0;
        if (this.flashed !='') {
          this.buttons[this.flashed].unflash();
          timeout=1000;
        } setTimeout(() => {
          button.flashit();
        }, timeout);
          break;
      } default : {
        let timeout=0;
        if (this.flashed!='') {
          for (const key in this.buttons) {
              this.buttons[key].unflash();
          }
          timeout=1000;
        } setTimeout(() => {
          for (const key in this.buttons) {
              this.buttons[key].flashit();
          }
        }, timeout);
         break;
      }
    }
    this.flashed = name;
  }
  unflash(name) {
    switch (this.mode) {
      case GroupButtonsEnum.one: {
        this.buttons[name].unflash();
        break;
      } default : {
        for (const key  in this.buttons) {
            this.buttons[key].unflash();
        } break;
      }
    }
  }
  setLength(value) { this.length = value;}
  reset() {
    for (let key of ['correct', 'wrong']) {
      let button = this.get(key);
      button.name='0';
    }
  }
  track(compared) {
    const key = compared ? 'correct': 'wrong';
    const okey = compared ? 'wrong': 'correct';
    let button = this.get(key);
    const value = +button.name;
    button.name = `${value+1}`;
    button.changed = true;
    button = this.get(okey);
    button.changed = false;
    button.icon=false;
    // if (button.icon) {
    //
    // }
    this.flash(key);
  }
}

export class Shuttle {
   terminate: any;
   keys=0;
   callbacks={};
   observable:any;
   subscribed:any;
   _halt = false;

  constructor(freq) {
    this.terminate = new Subject<any>();
    this.observable = timer(100, freq).pipe(takeUntil(this.terminate));

    this.subscribed = this.observable.subscribe(
        tick => {
          if (!this._halt) {
            for (const key in this.callbacks) {
              this.callbacks[key]();
            }
          }
        });
  }

  toggle() {
    this._halt = this._halt? false: true;
  }
  start() {
    this._halt=false;
  }
  stop() {
    this.terminate.next();
  }
  // use the id to remove the callback
  add(callback) {
    const id=this.keys;
    this.callbacks[this.keys++] = callback;
    return id;
  }
  remove(id) {
    delete this.callbacks[id];
  }
  destroy() {
    this.stop();
    this.subscribed.unsubscribe();
  }
}
const increment=(value) => {
  // this is for net count generation the value returned should be in net-count format
  const decks=globalVars['ndecks'];
  let bar={low:decks==6?100:10, high:decks==6?9900:90};
  if (value < bar.low) {
    if (value==(bar.low-1)) {
      return value-3;
    }
    return value+1;
  }
  if (decks==6) {
    return value+100;
  }
  if (value==bar.high) {
    return value-30;
  }
  return value+10;
};
const decrement= (value) => {
  // this is for net count generation the value returned should be in net-count format
  const decks=globalVars['ndecks'];
  if (value < 10) {
    if (value==0) {
      return 3;
    }
    return value-1;
  }
  return decks==6? value-100: value-10;
};
const modifyBy=(func, value, N)=> {
  const list=[];
  while(N) {
    N-=1;
    value=func(value);
    list.push(value);
  }
  return list;
};
const incrementBy=(value, N)=> { return modifyBy(increment, value, N);};
const decrementBy=(value, N)=>{ return modifyBy(decrement, value, N);};

const RandomIndexFromList = (list) => {
  return list.splice(rand_range(list.len),1)[0];
};


const adjacent=(value, sign, add) => {
  value= +value+add;
  if (value)
      return value<0? `${value}`: `+${value}`;
  return '0';
}
const GetCountMit = (exp) => {
  const signed = exp[0]=='-' || exp [0] == '+';
  const sign=signed?exp[0]:'+';
  exp=sign=='+'?`+${exp}`: exp;
  // console.log(JSON.stringify(rlist))
  return {list:shuffleList([adjacent(exp,sign, 1), adjacent(exp,sign,-1), adjacent(exp,sign, rand_range(2)?2:-2), `${exp}`]), clist:[`${exp}`]};
};
//
// export const splitCount =(count) => {
//  if (count.includes(globalVars['join']) == -1) return {high:-1, low:-1};
//   const myRe=/(\d*)o(\d*)/;
//   const a = myRe.exec(count);
//   return {high:+a[1], low: +a[2]};
// }

const range = (value) => {
  switch(value) {
    case 0 : return [1,2];
    case 1 : return [0,2];
    case 2 : return [1,3];
    default: return [value-1, value-2];
  }
}
export const shuffleList = (list) => {
    const rvalue=[];
    while(list.length) {
      rvalue.push(list.splice(rand_range(list.length),1)[0]);
    }
    return rvalue;
}

const GetCountTen = (exp) => {
  let nexp=+exp;
  //console.log(`GetCountTen: exp:${exp}`);
   return {list:shuffleList(nexp? [nexp-1, nexp+1, nexp+2, exp].map((val)=>`${val}`):[nexp+1, nexp+2, nexp+3, exp].map((val)=>`${val}`)), clist:[exp]};
};


const binaryRandom = () => Math.floor(Math.random() * 2)? true: false;

const split = (value:number) => {
  return [value%100, Math.floor(value/100)];
};
const join=(lo,hi) => {
  if (lo==hi) return '0';
  return `${hi}${globalVars['join']}${lo}`;
}
const checkCountForOne=(count) => {
   const highOne=count.high==1;
   const lowOne=count.low==1;

   if (highOne || lowOne) {
      if (highOne && lowOne) {
         return binaryRandom()? join(count.low+2, count.high): join(count.low, count.high+2);
      }
      if (highOne) return join(count.low-1, count.high);
      return join(count.low, count.high-1);
   }
   return binaryRandom()? join(count.low-1, count.high): join(count.low, count.high-1);
}

const checkCountForOneSingle=(high, low) => {
   const highOne=high==1;
   const lowOne=low==1;

   if (highOne || lowOne) {
      if (highOne && lowOne) {
         return binaryRandom()? join(low+2, high): join(low, high+2);
      }
      if (highOne) return join(low-1, high);
      return join(low, high-1);
   }
   return binaryRandom()? join(low-1, high): join(low, high-1);
}



const GetCountEasyJack=(exp) => {
 if (exp=='0') return {list:shuffleList(['0','1L','1H','2L']), clist:['0']};
  if (exp.includes(globalVars['join'])) {
    let[high,low] = exp.split(globalVars['join']);
    return {list:shuffleList(getJackList(+low, +high)), clist:[exp]};
  }
  return {list:shuffleList(getJackNetList(exp)), clist:[exp]};
}

const GetCountEasySingle=(exp) => {
 if (exp=='0') return {list:shuffleList( ['0', '1', '10', '2']), clist:['0']};
  const high=Math.floor(+exp/10);
  const low=+exp%10;
  if (high && low) {
    return {list:shuffleList([exp,
               join(low+1, high),
               join(low, high+1), checkCountForOneSingle(high,low)]), clist:[exp]};

  } else {
    const icount = +exp;
    return GetCountEasyReducedSingle(exp);
  }

}


const GetCountEasy=(exp)=> {
	return isJack() ? GetCountEasyJack(exp): GetCountEasySingle(exp);
 };


const GetCountEasyReducedJack=(count) => {
  return {list:shuffleList(getJackNetList(count)), clist:[count]}
};

const GetCountEasyReducedSingle=(count) => {
//	alert(`GetEasyReducedSingle, count:${count}`);
  if (count=='0'){
      return {list:shuffleList(['0','1','20','2']), clist:['0']};
  }
  const high=Math.floor(+count/10);
  const low=+count%10;

  if (high==1 || low==1) {
     return  {list:shuffleList(['0',count,'2','20'] ), clist:[count]};
  }
  if (high) {
      return {list:shuffleList([`${(high+1)*10}` ,count,`${(high-1)*10}`,`${(high+2)*10}`]), clist:[count]}
  }
  return {list:shuffleList([`${low+1}` ,count,`${low-1}`,`${low+2}`]), clist:[count]}
};

const GetCountEasyReduced=(count) => {
  return isJack()? GetCountEasyReducedJack(count): GetCountEasyReducedSingle(count);
};


export const GetCount = (exp, net=false) =>{

  switch( globalVars['method']) {
    case 'ten': return GetCountTen(exp);
    case 'mit': {
      return GetCountMit(exp);
    } default: {
      return net? GetCountEasyReduced(exp):GetCountEasy(exp);
    }
  }
}
