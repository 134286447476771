import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { CoreModule } from '@app/core';

// import { AboutComponent } from './about/about.component';
// import { FeaturesComponent } from './features/features.component';

const routes: Routes = [
  // { path: 'about', component: AboutComponent, data: { title: 'About' } },
  // {
  //   path: 'features',
  //   component: FeaturesComponent,
  //   data: { title: 'Features' }
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StaticRoutingModule {}
