


    <div style="margin-left:{{gvars['center-width']}}px;margin-top:20px" >

     <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"    style='height:100%;width:100%;'>
       <div ngxSlickItem class="slide">
            <mat-card class="example-card">
                   <mat-card-actions>
                     <div class="flex-column">
                        <ng-container *ngFor="let button of buttons">
                          <button mat-raised-button
                                  class="{{mouseClass[button]}} curvey-border"
                          (click)="clicked(button)" class='max-width margin-top-10px'>{{button}}</button>

                        </ng-container>
                    </div>
                   </mat-card-actions>
           </mat-card>
       </div>

     </ngx-slick-carousel>
 </div>
