import { Component, OnInit, ViewChild} from '@angular/core';
import { CommonListAnimations} from '../shared/animations/mobile-animations';
import {globalVars} from '../../services/global.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import {GenericTopTopAction} from '../store/generic.action.reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'anms-mobile-cards-struct',
  templateUrl: './cards.component.html',
  animations: [
    CommonListAnimations.drop
  ]
})
export class CardStructComponent {
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  images={
    num: {
      low:'Five',
      high: 'Five',
      ignored: 'Three'
    },
    low:[
    '/assets/cards/2C',
    '/assets/cards/3D',
    '/assets/cards/4H',
    '/assets/cards/5S',
    '/assets/cards/6C'
   ],
   high:['/assets/cards/10C',
         '/assets/cards/JD',
         '/assets/cards/QH',
         '/assets/cards/KS',
         '/assets/cards/AC'
       ],
   high_4:['/assets/cards/10C',
         '/assets/cards/JD',
         '/assets/cards/QH',
         '/assets/cards/KS'
         ],
   low_4:[
   '/assets/cards/2C',
   '/assets/cards/3D',
   '/assets/cards/4H',
   '/assets/cards/5S'
  ],
   ignored:['/assets/cards/7C',
               '/assets/cards/8D',
               '/assets/cards/9S'],
       };
  show=false;


  constructor(private store:Store<any>) {}

  roundNumber(n1, n2) {
    return (n1/n2).toFixed(2);
  }


  gvars = globalVars;
  slideConfig= {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "dots": false,
   "infinite": false,
   arrows: false,
   'swipeToSlide': false,
 };

 page = 0;
 top_msg = {
   0: ['13 unique cards', 'Repeated 4 times in single-deck', 'Repeated 8 times in double-deck', 'Repeated 24 times in six-deck' ],
   1: ["High-cards:", 'Consider 10, Jack, Queen, King', " As a '10'"],
   2: ['Low-cards:', 'Consider 2, 3, 4, 5', "As a '4'"],
   3: ['Class of low and high cards', '8 our of 13 considered', 'Not complete'],
   4: ['include Ace and 6 to the mix', 'Ace into high-cards', '6 into low-cards'],
   5: ['ignored for simplicity', 'in a near feature we are going to consider them']

 }
  ngOnInit() {
    setTimeout(() => {
      const image=globalVars['image'];
      for (const key in this.images) {
        if (key=='num') continue;
        this.images[key] = this.images[key].map(img=> `${img}${image.dims}.png`);
      }
      this.show=true;
      GenericTopTopAction(this.store, this.top_msg[this.page]);
    }, 100);
  }

  fmargin() {return this.page? 20: 260;}

  clicked(direction) {
    this.page += direction=='forward'? 1: -1;
    if (this.page == 6) {
      this.page = 0;
    }
    GenericTopTopAction(this.store, this.top_msg[this.page]);
    this.slickModal.slickGoTo(this.page);
  }

}
