import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable,Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { GenericDataUnitEnum, FlipDataUnit} from '../store/generic.model';
import {trigger,state,style,transition,animate,keyframes,query,stagger} from '@angular/animations';


@Component({
  selector: 'anms-mobile-flip-card',
  templateUrl: './flip.component.html',
  // styleUrls: ['./flip.component.scss', '../../styles.scss'],
  styleUrls: ['./flip.component.scss'],

  animations: [
    trigger('cardFlip', [
      state('default', style({
        transform: 'none'
      })),
      state('flipped', style({
        transform: 'rotateY(180deg)'
      })),
      transition('default => flipped', [
        //animate('400ms')
        animate('1000ms')
      ]),
      transition('flipped => default', [
        //animate('200ms')
        animate('500ms')
      ])
    ])
  ]
})

export class FlipCardComponent implements OnInit {
  subscriptions=[];
@Input('id') id: number;
state: 'default'| 'flipped';
data={
  front: {class:'', title:''},
  back: {class:'', title:'', stmt:[]},
}
fclass:any;
bclass:any;
///////
// constructor
///////
  constructor(
    public store: Store<any>,
  ) {
    this.state='default';
  }
////////

  ngOnDestroy() :void{
   for (const subscription of this.subscriptions) {
       subscription.unsubscribe();
     }
  }
 flipit() {
     this.state= this.state=='default'? 'flipped': 'default';
 }
  ngOnInit() :void{
    this.bclass='leave';
    this.fclass=this.id? 'leave':'enter';
    this.subscriptions.push(
          this.store
            .select('flipCardReducer')
            .pipe(filter(event =>event.typeof=== 'FlipDataUnit'))
            .subscribe((data: FlipDataUnit) => {
                const action = data.action;
                if ('reset' in action) {
                    this.state='default';
                    return;
                }
                if ('focus' in action) {
                  this.fclass=data.id==this.id? 'enter':'leave';
                }
              if (data.id==this.id) {
                  // console.log(`data:${JSON.stringify(data)}`)
                if ('trigger' in action) {
                    this.flipit();
                    return;
                }
                for (const key of ['front', 'back']) {
                      if(key in action) {
                        this.data[key]={...action[key]};
                      }
                    }
                }

              }));
  }
}
