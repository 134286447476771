import { VerticalImageDm } from './vimage.model';
import { Action } from '@ngrx/store';

const INSERT= 'vimages';


class InsertVerticalImageAction implements Action {
    readonly type = INSERT;
    constructor(public payload: VerticalImageDm) {
      // console.log(`insertVerticalAction class instantiated:payload:${JSON.stringify(payload)}`)
     }
}


export function verticalImageReducer(State = {}, action: InsertVerticalImageAction) {
  // console.log(`verticalImageReducer:${JSON.stringify(action.payload)}`)
    return action.type==INSERT? action.payload: {};
};

export function insertVerticalImageAction (store: any, id:any, casino:string, slide=false) {
  // console.log(`insertVerticalImageAction called:: with:${id}, images:${JSON.stringify(images)}`)
  store.dispatch( new InsertVerticalImageAction ({casino:casino, id:id, slide:slide,  typeof:'VerticalImageDm'}));
}
export function VerticalImageSlidAction (store: any, id:any, slide) {
  // console.log(`insertVerticalImageAction called:: with:${id}, images:${JSON.stringify(images)}`)
  store.dispatch( new InsertVerticalImageAction ({id:id, slide:slide,  typeof:'VerticalImageDm'}));
}
