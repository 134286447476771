import { Component, OnInit, Input, ViewChild, EventEmitter, Output, OnDestroy } from '@angular/core';
import { isJack,globalVars,MobileRouterService} from '../../../services/global.service';
import { Store } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';
import { BottomQuesButtons,BottomMenuReset} from '../../store/bottom.model';
import { BottomQuesButtonsPressedSend, MobileSendSubNavigationInfo} from '../../store/bottom.action.reducer';
import { FlashButtons, FilterType, START_FLASH,  FADEOUT, FADEIN,Flash} from '../../../services/common.service';
import {trigger,state,style,transition,animate} from '@angular/animations';


const IDLE = 0;
const DEFAULT_COLOR = 'primary';

@Component({
  selector: 'anms-bottom-numbers',
  templateUrl: './component.html',
  styleUrls: ['../../styles.scss'],
  animations: [
    trigger('flash', [
      transition(`* => ${FADEIN}`, [
        style({ opacity: 0 }),
        animate(1200, style({ transform: 'translateX(0) scale(1)', opacity: 1 }))
      ]),
      transition(`* => ${FADEOUT}`, [
        animate(1900, style({ transform: 'translateX(0) scale(0.5)', opacity: 0 }))
      ])
    ])
  ]
})


export class BottomNumbersComponent implements OnInit, OnDestroy{
  @Output() timerEvent = new  EventEmitter();
  @Output() thumbsEvent = new EventEmitter();
  subscriptions = [];
  flashed='';
  die='show';
  qbuttons = {
    buttons:[],
    flashinfo: [{flash:new Flash(), status:{status:IDLE}},{flash:new Flash(), status:{status:IDLE}},
                {flash:new Flash(), status:{status:IDLE}},{flash:new Flash(), status:{status:IDLE}}],
    cvalue:'',
    // timeout:0,
    enabled:{}
  };
  keys=['lesson', 'continuous-test', 'reset', 'test', 'speed', 'cards'];
  lesson=false;
  numberMargin=0;
  jcount=true;
  control={
    speed:false,
    lesson:false,
    cards:false
  };
  mleft=0
  testkey="continuous-test";

  constructor(
    private router: MobileRouterService,
   private store: Store<any>) {
     // this.disableAll();

   }
   tevent() {
     // alert(`numbers event triggered`)
     this.timerEvent.emit('');
   }
   _unflash(dict:any):void {
     dict.flash.unflash();

     // if (dict.name != 'home') {
     //   dict.disabled= true;
     // }
     dict.flashed=false;
     dict.color= DEFAULT_COLOR;
   }

///////////////////////
  ngOnInit():void {
    // this.unflash('all');
        this.jcount=isJack();
        let rows=3;
        let cols=8;
        this.mleft=globalVars['mobileDevice']? 50:100;
        this.subscriptions.push(
          this.store
            .select('mobileTopBottom')
            .pipe(filter(data => data && data.typeof=== 'BottomQuesButtons'))
            .subscribe((bottomQuesButtons: BottomQuesButtons) => {
	             this.die='show';
                const continuous=bottomQuesButtons.timeout;
                const buttons = bottomQuesButtons.buttons;
                // this.lesson='lesson' in bottomQuesButtons;
                // this.lesson=globalVars['mode'] == 'license' || globalVars['math-test'] ? false: true;
                this.lesson='lesson' in bottomQuesButtons;
                // const key = globalVars['cach'] ? 'continuous-test' : 'test';
                this.testkey = continuous? 'test': 'continuous-test';

                this.qbuttons.buttons = buttons;
                for (const button of buttons) {
                     this.qbuttons.enabled[button]=true;
                }
                this.qbuttons.cvalue=bottomQuesButtons.cvalue;
                // this.qbuttons.timeout=bottomQuesButtons.timeout;
                // console.log(`received BottomQuesButtons, buttons:${JSON.stringify(bottomQuesButtons.buttons)}`)
                for (let  i=0;i!=4;i++) {
                  const f =   this.qbuttons.flashinfo[i];
                  f.flash.flash(f.status);
                }
              }
            ));


            this.subscriptions.push(
              this.store
                .select('mobileTopBottom')
                .pipe(filter(data => data && data.typeof=== 'BottomMenuReset'))
                .subscribe((nodata: BottomMenuReset) => {
                    this.resetQbuttons(nodata.blank);
                  }
                ));
                let cout=globalVars['cards-out'];
                if (cout>4) {
                  const dims=globalVars['image']['dims'];
                  const pixel=dims.includes('80')? 69:79;
                  const cols=globalVars['image']['cols'];
                  let cout=globalVars['cards-out'];
                      cout= cout < cols ? cout: cols;
                  this.numberMargin = Math.floor((pixel*cout)/3);
                }


  }
//////////////////////
ngOnDestroy():void {
    for (const subscriber of this.subscriptions) {
      subscriber.unsubscribe();
    }
      this.resetQbuttons();
}

 resetQbuttons(blank=false):void {
   const info = this.qbuttons.flashinfo;
    for (let i=0;i!=4;i++) {
           info[i].status.status=IDLE;
    }
    this.qbuttons.buttons=[];
    this.qbuttons.enabled=[];
    this.die='hide';

 }
////////////////
  clicked(button:any):void {
    // console.log(`bottom comp. received key: ${key}`);
     // send it up to application
    if (this.keys.includes(button)) {
        // this.menulist=this.availList[button]
        BottomQuesButtonsPressedSend(this.store,button);
        return;
    }
    let timeout=0;

    if (this.qbuttons.cvalue) { // cross out the
      if (button != this.qbuttons.cvalue) {
        const buttons = this.qbuttons.buttons;
        // let lindex=0; let index=0;
         for (const _button of buttons) {
           if (_button == this.qbuttons.cvalue) {
             // lindex=index;
             continue;
           }
           // index++;
           this.qbuttons.enabled[_button] = false;
         }
      }
    }
    this.thumbsEvent.emit(button==this.qbuttons.cvalue? 'up': 'down');
    for(let i=0;i!=4;i++) {
      this.qbuttons.flashinfo[i].flash.unflash();
    }
    setTimeout(() => {
      this.resetQbuttons(button.includes('Insu')|| ['hit', 'stand', 'double', 'split'].includes(button));
      BottomQuesButtonsPressedSend(this.store,button);
    }, timeout);

  }
}
