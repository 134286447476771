import { Component, ChangeDetectionStrategy, OnInit,OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import { DealerService} from '../../../services/deck.service';
//import { MatMenuModule} from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { filter, tap } from 'rxjs/operators';
import { print } from '../../../services/common.service';
import { globalVars,getImage} from '../../../services/global.service';

import { Subscription } from 'rxjs';
import { PlayCardsDataUnit, MobileCountCardDataUnit} from '../../store/cards.model';
import {MobileInsertCardAction,MobileReplaceCardAction,MobileCardsPeerErase,MobileCardsMode, MobileCardsBackgroundOff } from '../../store/cards.action.reducer';
import { SendPlayerCardDrawnAction } from '../../../store/switchcards.action.reducer';

import {MobileSendKeyRoute,MobileSendUnFlash} from '../../store/bottom.action.reducer';
import { UPDATE_COUNT,DROP, DEALER_DISPLAY_ID, DISPLAY_BADGE, END_OF_HAND, DEALER, DEALER_FACEUP}  from '../../../store/switchcards.model';

enum State {
  first, // insert blank
  second, // second card after blank
  third, // unblank and show the rest
  fourth // end state

};
@Component({
  selector   : 'mobile-dealer-cards-display',
  templateUrl: './playing.cards.component.html',
  styleUrls: ['../../styles.scss'],
  // host: {
  //   '(@clist.done)': 'Clist($event)',
  // },
//  changeDetection: ChangeDetectionStrategy.OnPush,
  })

export class MobileDealerCardsComponent  implements OnInit, OnDestroy {
  subscriptions=[];
  @Output() event = new EventEmitter(); //currently only one event type raised when all the cards are dropped
  @Input() id ;
  @Input() label='';
  @Input() mode='flat';

  state: State = State.first;
  firstCard:any;
  count=0;
  cardsSend=0;


  constructor(private dealerService: DealerService,    private store: Store<any>) {

  }
/////////////////////////////////////

  sendAfterBlank(cards:any):void {
    const list=[];
    for (const card of cards) {
      list.push( {card: getImage(card), badge:false, value:0});
    }
    setTimeout(() => {
      MobileInsertCardAction(this.store, [list.shift()],this.id);
    }, 200);
    setTimeout(() => {
      MobileInsertCardAction(this.store, list,this.id);
    }, 1400);

    this.cardsSend = cards.length;
  }
  send(cards:any):void {
    const list=[];
    for (const card of cards) {
      list.push( {card: getImage(card), badge:false, value:0});
    }
    MobileInsertCardAction(this.store, list,this.id); // use panel 0
    this.cardsSend = cards.length;
  }

  sendBlank():void {
    MobileInsertCardAction(this.store,[{card: `/assets/cards/Gray_back${globalVars['image'].dims}.png`, badge:false, value:0}] ,this.id); // use panel 0
  }
  replaceBlank() :void{
    MobileReplaceCardAction(this.store,[{card: getImage(this.firstCard), badge:false, value:0}] ,this.id); // use panel 0
  }
  ngOnInit():void {
    const service = this.dealerService;
    setTimeout(() => {
      MobileCardsMode(this.store, this.id);
    }, 500);
    MobileCardsBackgroundOff(this.store, this.id);
    this.subscriptions.push(
      this.store
        .select('mobileCard')
        .pipe(filter(event =>event.typeof=== 'PlayCardsDataUnit' && event.id==DEALER_DISPLAY_ID))
        .subscribe((data: PlayCardsDataUnit) => {
          // console.log(`received dealer card request label:${data.label}`)
        const cards = data.cards;
        switch(data.label) {
          case DROP: {
            // console.log(`dealer received drop request`)
            MobileCardsPeerErase(this.store, this.id);
            break;
          } case UPDATE_COUNT: case DISPLAY_BADGE: {
            break;
          } default: {
            switch (this.state) {
              case State.first: {
                this.firstCard = cards[0];
                // send the blank as a first card
                this.sendBlank();
                this.state = State.second;
                break;
              } case State.second: {
                // send the card to display the second card
                this.send(cards);
                this.state = State.third;
                break;
              } case State.third: {
                  setTimeout(() => {
                      this.dealerService.adjustCardCount();
                      this.replaceBlank();
                    // this.dealerService.popCardCount(); // shows the face-up count
                  }, 500);
                  // send the first-card to the displayer
                   setTimeout(() => {
                            if (cards.length) {
                              this.send(cards);
                            }
                            this.state = State.first
                   }, 1200);
                   break;
                   // this.state=State.fourth;
              }
            }
            break;
          }
        }
      }
    )
  );
  }

  cardsEvent(e:any):void {
    // this.event.emit(event);
    if (e.id != this.id) return;
    if (e.action=='drawn') {
       // console.log('dealer drawn recvd')
      SendPlayerCardDrawnAction(this.store);
      // if (this.state != State.second)
      this.dealerService.popCardCount();
    }
  }
  ngOnDestroy():void {
    for (const subs of this.subscriptions) {
      subs.unsubscribe();
    }
    this.dealerService.shuffle();
  }
}
