
      <!-- <div style="margin-left:{{gvars['center-width']}}px; width:80%"> -->
      <div class="flex-column"  style="margin-top:30px" *ngIf="show">
          <div class="flex-row " style="margin-top:10px" *ngFor="let card of ['low', 'high']">
                <div class='flex-row' style="width:420px">
                  <img  src="{{image}}"  *ngFor="let image of images[card]; let i=index" class="cards-spacing">
                </div>
                 <div class="flex-column" style="margin-left:15px;width:50px; height:100px; border:4px dotted black" class="curvey-border">
                   <button mat-mini-fab  [color]="'warn'" style="margin-left:2px; margin-top:2px" (click)="xclicked(card, '-')"><span style='font-size:25px'>-</span></button>
                   <button mat-mini-fab  [disabled]="stack[card].length==0" [color]="'primary'" (click)="xclicked(card,'+')" style="margin-left:2px;margin-top:10px"><span style='font-size:25px'>+</span></button>
                 </div>
          </div>
          <div class="flex-column">
                <mat-card  class="small-first-panel" >
                      <mat-card-header>
                            <span style="margin-left:10px">high-low ratio:  {{cards.high}}/{{cards.low}} = {{highLowRatio}}</span>
                      </mat-card-header>
                </mat-card>

                <mat-card  class="small-rest-panel" >
                      <mat-card-header>
                            <span style="margin-left:10px">low-high ratio:  {{cards.low}}/{{cards.high}} = {{lowHighRatio}}</span>
                      </mat-card-header>
                </mat-card>
          </div>

      </div>
