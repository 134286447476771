import { trigger, state, style, transition, animate, query, stagger, keyframes } from '@angular/animations';
export const FADEOUT="FadeOut";
export const FADEIN="FadeIn";
export const CommonListAnimations = {
    buttons:  trigger('buttons', [
        // Transition from any state to any state
        transition('* => *', [
          // Initially the all cards are not visible
          query(':enter', style({ opacity: 0 }), { optional: true }),

          // Each card will appear sequentially with the delay of 300ms
          query(':enter', stagger('300ms', [
            animate('.5s ease-in', keyframes([
              style({ opacity: 0, transform: 'translateY(-50%)', offset: 0 }),
              style({ opacity: .5, transform: 'translateY(20%) scale(1.1)', offset: 0.3 }),
              style({ opacity: 1, transform: 'translateY(0)', offset: 1 }),
            ]))]), { optional: true }),

          // Cards will disappear sequentially with the delay of 300ms
          query(':leave', stagger('300ms', [
            animate('300ms ease-out', keyframes([
              style({ opacity: 1, transform: 'scale(1.1)', offset: 0 }),
              style({ opacity: .5, transform: 'scale(.5)', offset: 0.3 }),
              style({ opacity: 0, transform: 'scale(0)', offset: 1 }),
            ]))]), { optional: true })

        ]),
      ]),
    drop:trigger('drop', [
      transition(':enter', [
        // Initially the all cards are not visible
         animate('2s ease-in', keyframes([
            style({ opacity: 0, transform: 'translateY(-120px)', offset: 0 }),
            style({ opacity: .4, transform: 'translateY(120px) scale(1.2)', offset: 0.4 }),
            style({ opacity: .8, transform: 'translateY(10px)', offset: 0.7 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1 })
          ]))]),
          transition(':leave', [
             animate('1s ease-in', keyframes([
                style({ opacity: .2, transform: 'translateX(70%)', offset: 0.8}),
                // style({ opacity: .2, transform: 'translateX(70%)', offset: 0.7 }),
                style({ opacity: 0, transform: 'translateX(100%)', offset: 1 })
              ]))]),

      ]),
    flash: trigger('flash', [
      transition(`* => ${FADEIN}`, [
        style({ opacity: 0 }),
        animate(1200, style({ transform: 'translateX(0) scale(1.4)', opacity: 1 }))
      ]),

      transition(`* => ${FADEOUT}`, [
        animate(1900, style({ transform: 'translateX(0) scale(0.9)', opacity: 0 }))
      ])
    ]),
    anim: trigger('anim', [
          transition('* => *', [
             // Initially the all cards are not visible
             // query(':enter', style({ opacity: 0 }), { optional: true }),
               query(':enter', stagger('300ms', [
                 animate('1s ease-in', keyframes([
                   style({ opacity:  0, transform: 'translateY(50px)', offset: 0 }),
                   style({ opacity:  0.5, transform: 'translateY(-20px)', offset: 0.3 }),
                   style({ opacity:  1, transform: 'translateY(0)', offset: 1 })
                 ]))]), { optional:  true }),

               // query(":leave", [
               //             animate(".3s cubic-bezier(.4,0,.21,1)", style({opacity: 0}))
               //           ], {optional: true})
               query(':leave', stagger('300ms', [
                 animate('300ms ease-in', keyframes([
                   style({ opacity:  0.3, transform: 'translateX(20px)', offset: 0 }),
                   style({ opacity:  0.1, transform: 'translateX(100%)', offset: 0.9 }),
                   style({ opacity:  0, transform: 'translateX(0)', offset: 1 })
                 ]))]), { optional:  true }),
             ])
     ]),
     hanim: trigger('hanim', [
           transition('* => *', [
              // Initially the all cards are not visible
              // query(':enter', style({ opacity: 0 }), { optional: true }),
                query(':enter', stagger('300ms', [
                  animate('1s ease-in', keyframes([
                    style({ opacity:  0.2, transform: 'translateX(50px)', offset: 0 }),
                    style({ opacity:  0.4, transform: 'translateX(25px)', offset: 0 }),
                    style({ opacity:  0.7, transform: 'translateY(-20px)', offset: 0.3 }),
                    style({ opacity:  1, transform: 'translateX(0)', offset: 1 })
                  ]))]), { optional:  true }),

                // query(":leave", [
                //             animate(".3s cubic-bezier(.4,0,.21,1)", style({opacity: 0}))
                //           ], {optional: true})
                query(':leave', stagger('300ms', [
                  animate('300ms ease-in', keyframes([
                    style({ opacity:  0.3, transform: 'translateX(20px)', offset: 0 }),
                    style({ opacity:  0.1, transform: 'translateX(100%)', offset: 0.9 }),
                    style({ opacity:  0, transform: 'translateX(0)', offset: 1 })
                  ]))]), { optional:  true }),
              ])
      ]),

     manim: trigger('manim', [
           transition('* => *', [
              // Initially the all cards are not visible
              query(':enter', style({ opacity: 0 }), { optional: true }),
                query(':enter', stagger('300ms', [
                  animate('1s ease-in', keyframes([
                    style({ opacity:  0, transform: 'translateY(50px)', offset: 0 }),
                    style({ opacity:  0.5, transform: 'translateY(-20px)', offset: 0.3 }),
                    style({ opacity:  1, transform: 'translateY(0)', offset: 1 }),
                  ]))]), { optional:  true }),

              query(":leave", [
                          animate(".3s cubic-bezier(.4,0,.21,1)", style({opacity: 0}))
                        ], {optional: true})
              ])

      ]),

      vertical_vanish: trigger('suggestion', [
             state('open', style({ maxHeight: '200px', overflow: 'auto' })),
             state('closed', style({ maxHeight: '0px' })),
             transition('* => closed', animate('0.5s')),
             transition('* => open', animate('100s')),
     ]),

      flip: trigger('flip', [
          // note,
          // having state here makes the effect of animation permanent. That means after annotation the
          // object doesn't go to it's original State
          //
            state('flip', style({transform: 'rotateY(180deg)'})),
            state('default', style({transform: 'rotateY(0)'  })),
            // transition('*=> flip', animate('800ms ease-out')),
            // transition('flip => default', animate('800ms ease-in'))
            transition('*=> flip', animate('600ms')),
            transition('flip => default', animate('300ms'))
    ]),

      cards: trigger('cards', [
             transition("*=>*", [
                      query(':enter', style({ opacity: 0 }), { optional: true }),
                      query(':enter', stagger('300ms', [
                             animate(500, keyframes([
                             style({ opacity:  0.3, transform: 'translateX(+10%)',  offset: 0.3 }),
                             style({ opacity:  0.6, transform: 'translateX(-10%)',  offset: 0.7 }),
                             style({ opacity:  1,   transform: 'translateX(0)',  offset: 1 }),
                             ]))]), { optional:  true }),
                      query(':leave', stagger('300ms', [
                             animate('.5s ease-in', keyframes([
                             style({ opacity:  0.6, transform: 'translateX(20%)',  offset: 0.3 }),
                             style({ opacity:  0.3, transform: 'translateX(40%)',  offset: 0.7 }),
                             style({ opacity:  0,   transform: 'translateX(60%)',  offset: 1 }),
                             ]))]), { optional:  true }),
             ]) // end of transition
       ]),  // end of trigger cards
       right2leftYanim: trigger('right2leftYanim', [
         transition('* <=> *', [
           query(':enter, :leave', [
             style({
               position: 'absolute',
               left: 0,
               width: '100%',
               opacity: 0,
               transform: 'scale(0) translateY(100%)'
             })
           ], { optional: true }),
           query(':enter', [
             animate('600ms ease', style({
               opacity: 1, transform: 'scale(1.5) translateY(0)'
             })),
           ], { optional: true }),
           query(':leave', [
             animate('3ms ease', style({
               opacity: 0
             })),
           ], { optional: true })
         ])
       ]),



}
