import { Component, ViewChild } from '@angular/core';
import {ThumbComponent} from '../thumb/component'
@Component({
  selector: 'anms-thumbs',
  templateUrl: './component.html',
  styleUrls: [],
})



export class ThumbsComponent {


  @ViewChild('thumbsup') thumbsup: ThumbComponent;
  @ViewChild('thumbsdown') thumbsdown: ThumbComponent;
  constructor() { }

  display='show'
  thumbsevent(event) {
    if (event=='up')
      this.thumbsup.incr();
     else
        this.thumbsdown.incr();
  }

}
