
export class Split {
  set: number [];
  activeSet: number [];
  factor = 16;
  count = 0;
  pid: number;
  mask: number;
  constructor(mask=7) {
    this.set = [];
    this.activeSet = [];
    this.mask = mask;
  }
  str() { return `Split: set: ${this.set}, activeSet: ${this.activeSet} }`; }
  display() { console.log(this.str()); }
  push(id: number) {
    this.activeSet.push(id);
    this.pid = id & this.mask; // 3 hands max
  }
  newId() {
    return ++this.count * this.factor + this.pid;
  }
  realId(value) {
    return value%this.factor;
  }
  pop(id: number) {
    // console.log(`split::pop id:${id} activeSet:${this.activeSet}`);
     this.activeSet.splice(this.activeSet.indexOf(id), 1);
     this.set.push(id);
    //  console.log(`split::pop activeset:${this.activeSet}, set:${this.set}`);
  }
  exists(id: number) {
    // exists: split - incoming - id: 16, set:, activeSet:
    return this.set.includes(id) || this.activeSet.includes(id) ;
  }
  isLastElem(id: number) {
    return this.activeSet.length === 0 && this.set.slice(-1).includes(id);
  }
  lastElem() {
    return this.activeSet.slice(-1)[0];
  }
  doneWithSplit(table: number[]) {
    const list = [...this.set, ...this.activeSet];
    for (const id of list) {
      if (table.includes(id)) {
        // console.log(`table includes id:${id}`);
        return false;
      }
    }
    return true;
  }
  complete() {
    return this.count === this.set.length;
  }
  ids() { return this.set;}
  length() { return this.activeSet.length;}
  getCount() { return this.count;}
  reset() {
    this.set = [];
    this.activeSet = [];
    this.count =0;
    // console.log(`split reseted`);
  }
  isSplitHand() { return this.set.length > 0;}
}
