import { Component, OnInit, OnDestroy,Input,ViewChild} from '@angular/core';
import { Store } from '@ngrx/store';
import { VerticalImageDm } from './store/vimage.model';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import {  filter } from 'rxjs/operators';
const SLICE=7;
@Component({
  selector: 'anms-vertical-img',
  templateUrl: './vimage.component.html',
  // styleUrls: ['../styles.scss'],
  styleUrls: ['./vimage.component.scss'],


})
export class VerticalImageComponent  implements  OnInit  {
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  @Input() id: number; // comma seperated bet items
  interval:any;
  keys=['front', 'room', 'food'];
  images=[];
  subscriptions=[];
  delay:number;
  slideConfig= {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "dots": false,
   "infinite": false,
   arrows: false,
   'swipeToSlide': false,
 };
  slideit=false;
  maxCount=3;
  cpage=0;
  state:'on'|'off'='off';
  slideMode:'forward'|'backward'='forward';
  constructor(private store:Store<any>) {
  }


  init(casino):void {

    this.images=this.keys.map((key:any)=>`/assets/casinos/${casino}/${key}.jpg`);
    // console.log(`images:${JSON.stringify(this.images)}, inputs:${JSON.stringify(this.inputs)}`);
  }
  // .pipe(filter(event => event && event.typeof=== 'ApptopModel' && event.proto === ApptopEnum.request))
  slideshow() {
    if (this.slideMode=='forward') {
      this.slickModal.slickNext();
      if(++this.cpage == this.maxCount) {
        this.slideMode='backward';
      }
      return;
    }
    this.slickModal.slickPrev();
    if(--this.cpage == 0) {
      this.slideMode='forward';
    }
  }
  ngOnInit() :void{
  this.subscriptions.push(
    this.store
      .select('vimages')
      .pipe(filter(data => data && data.id==this.id && data.typeof === 'VerticalImageDm' ))
      // .pipe(filter(data => data  && data.typeof === 'VerticalImageDm' ))
      .subscribe(data => {
            if ('casino' in data) {
              this.init(data.casino);
            }
            if ('slide' in data) {
              this.slideit=data['slide'];
              if (this.slideit) {
                this.interval=setInterval(()=>this.slideshow(), 1000);
                this.state='on';
                return;
              }
              if (this.state=='on') {
                clearInterval(this.interval);
                this.state='off';
              }
            }
      }));

  }
 ngOnDestroy() :void{
   clearInterval(this.interval);
   for (const subscriber of this.subscriptions) {
     subscriber.unsubscribe();
   }
 }

}
