import { Component, ViewChild } from '@angular/core';
import {ThumbsTimerComponent} from '../thumbs-timer/component';
@Component({
  selector: 'anms-thumbs-timer-number',
  templateUrl: './component.html',
  styleUrls: [],
})



export class ThumbsTimerNumberComponent {
  @ViewChild("thumbs_timer") thumbsTimer: ThumbsTimerComponent;
  constructor() { }
  tevent(event) {
    this.thumbsTimer.thumbsevent(event);
  }
}
