
<div style="margin-left:{{vars['center-width']}}px; height:100%; width:100%">
        <div  style="margin-top:{{margin.top}}px">
                  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (afterChange)="afterChange($event)" style='width:100%'>
                    <div ngxSlickItem *ngFor="let stmtList of statements[method]" class="slide">
                      <div class="margin-side-10">

                         <Lp  *ngFor="let stmt of stmtList" text="{{stmt}}"> </Lp>

                      </div>
                    </div>   

                 </ngx-slick-carousel>
                 <div class="flex-row"  style="margin-left:10%;">
                         <button mat-flat-button  (click)="clicked('backward')"
                           [disabled]="disabled.backward" [color]="'warn'" class="scale-p8"> <mat-icon> skip_previous</mat-icon></button>

                        <button mat-flat-button  style='margin-left:5px' (click)="clicked('forward')"
                             [disabled]="disabled.forward" [color]="'warn'"  class="scale-p8"> <mat-icon > skip_next </mat-icon></button>
                 </div>

        </div>
</div>
