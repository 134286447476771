import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable,Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { globalVars } from '../../services/global.service';
import {trigger,state,style,transition,animate,keyframes,query,stagger} from '@angular/animations';
import { FlashButtons, FilterType, START_FLASH,  FADEOUT, FADEIN,nFlash} from '../../services/common.service';
const IDLE = 0;
const DEFAULT_COLOR = 'primary';
@Component({
  selector: 'anms-online-betting',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
  animations: [
      trigger('flash', [
        transition(`* => ${FADEIN}`, [
          style({ opacity: 0 }),
          animate(1200, style({ transform: 'translateX(0) scale(1)', opacity: 1 }))
        ]),

        transition(`* => ${FADEOUT}`, [
          animate(1900, style({ transform: 'translateX(0) scale(0.5)', opacity: 0 }))
        ])
      ]),
      trigger('cardFlip', [
        state('default', style({
          transform: 'none'
        })),
        state('flipped', style({
          transform: 'rotateY(180deg)'
        })),
        transition('default => flipped', [
          animate('600ms')
        ]),
        transition('flipped => default', [
          animate('300ms')
        ])
      ])
    ]
})

export class OnlineBettingComponent implements OnInit {
  subscriptions=[];
@Input('id') id: number;


disabled = {forward: false, backward: true};
hand='first';
price="25";
bet=25;
value=0;
map={
  first: 1,
  second:2,
  third:3,
  fourth:4,
  fifth:5,
  sixth:6
};
max=3;
keys=['first', 'second', 'third', 'fourth', 'fifth', 'sixth'];
flashes={};
numbers={
  1:['25', '50', '100'],
  2:['500', '1K', '10K']
};
ops = ['+', '-'];
back="refresh";
gvars=globalVars;
///////
// constructor
///////
  constructor(
    public store: Store<any>) {
      globalVars['online-bet']=[];
      for (const e of [...this.numbers[1], ...this.numbers[2]]) {
        this.flashes[e] = new nFlash;
      }
      for (const e of this.ops) {
        this.flashes[e]= new nFlash;
      }
    }
flashNumbers() {
  for (const e of [...this.numbers[1], ...this.numbers[2]]) {
    this.flashes[e].flash();
  }
}
unflashNumbers() {
  for (const e of [...this.numbers[1], ...this.numbers[2]]) {
    this.flashes[e].unflash();
  }
}
flashOps() {
  for (const e of this.ops) {
    this.flashes[e].flash();
  }
}
unflashOps() {
  for (const e of this.ops) {
    this.flashes[e].unflash();
  }
}

  ngOnDestroy() :void{
   for (const subscription of this.subscriptions) {
       subscription.unsubscribe();
     }
  }

  ngOnInit() :void{
    globalVars['online-bet'] = [25];
    this.max=globalVars['mobileDevice']|| (globalVars['status']=='train-play')?3:6;
    this.flashNumbers();
    if (globalVars['mobileDevice']) this.max=2;
  }
  handIndex() {
    const index=this.map[this.hand]-1;
    // globalVars['online-bet-n'] = index;
    return index;
  }
  include(hand) {
    const bet=globalVars['online-bet'];
    const index=this.handIndex();
    if (index>=bet.length) {
       bet[index]=0;
    }
  }
  nexthand() {
    const nxt=this.map[this.hand] +1;
    this.hand=this.keys[nxt-1];
    return nxt;
  }
  prevhand() {
    const current=this.map[this.hand] -1;
    const prev = current - 1;
    this.hand=this.keys[prev];
    return prev;
  }
  clicked(key) {
    let bet=globalVars['online-bet'];
    switch(key) {
      case 'forward': {
        if (bet[this.handIndex()]==0) {
          return;
        }
        const hand = this.nexthand();
        this.include(hand);
        this.disabled.forward = hand ==this.max;
        this.disabled.backward=false;
        this.back="arrow_back";
        break;
      } case 'refresh' : {
          globalVars['online-bet'] = [0];
          bet=globalVars['online-bet'];
          this.hand='first';
          break;
      }  case 'arrow_back': {
          const hand = this.prevhand();
          if (!hand) {
            this.back="refresh";
          }
          this.disabled.forward = false;

          break;
      } case 'add': {
            this.flashNumbers();
            this.unflashOps();
            bet[this.handIndex()] += this.value;
            break;
      } case 'minus': {
           const index = this.handIndex();
           this.flashNumbers();
           this.unflashOps();
            if (this.value>bet[index]) {
              bet[index] = 0;
            } else {
              bet[index] -= this.value;
            }
            break;
      } default: {
        this.unflashNumbers();
        this.flashOps();
        if (key.includes('K')) {
          key=key.replace('K','');
          this.value=+key*1000;
        } else {
          this.value=+key;
        }
        break;
      }
    }
    this.price=bet[this.handIndex()];
  }
}
