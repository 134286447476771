import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../core/local-storage/local-storage.service';
import { AUTH_KEY } from '../core/auth/auth.reducer';
@Injectable({providedIn: 'root'})
export class AuthGuard  {
    externalRouteEnabled = true;
    constructor(private localStorage: LocalStorageService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
 //       console.log(`canActivate is called with externalRoute set to ${this.externalRouteEnabled}`);
         return this.localStorage.getItem(AUTH_KEY).isAuthenticated && this.externalRouteEnabled;
    }

    enable(route: boolean) {
        this.externalRouteEnabled = route;
//        console.log(`AuthGuard enable is called with route: ${route}`);
    }
}
