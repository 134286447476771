import { Component, OnInit, OnDestroy, ViewChild,Output,Input, EventEmitter,Pipe, PipeTransform} from '@angular/core';
import { CardswitchMobileService } from '../../services/cardswitch.service';
import { DealerService, CardCut } from '../../../services/deck.service';
import { isJack, globalVars,   MobileRouterService,decksMapping,imagesAttr, lpull } from '../../../services/global.service';
import { timer, Observable, Subscription, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
// import * as lodash from 'lodash';

import { MobilePlayerResultAction} from '../../../store/switchcards.action.reducer';

import {  MobileDropCardAction, MobileFlashPlayerLabel,MobileUnflashPlayerLabel} from '../../store/cards.action.reducer';
import {MobileBetDataUnit} from '../../store/bet.model';
import {BottomQuesButtonsPressed} from '../../store/bottom.model';
// import {MobileSendKeyRoute, MobileSendFlash, MobileSendUnFlash,BottomQuesButtonsSend,BottomResetMenuPressed} from '../../store/bottom.action.reducer';
import {MobileSendKeyRoute, BottomResetMenuPressed, BottomAppleModify} from '../../store/bottom.action.reducer';

import { Cntrl2SwitchSetupDm, Cntrl2switchDecisionDm,
  Switch2CntrlDm, GET_DECISION, END_OF_HAND, FAKE_CARD, IGNORE, MAX_NUMBER_OF_HANDS, DISABLE_EXTENDED,
  RESET, INSURANCE_YES, INSURANCE_NO, SPLIT, STAND, DOUBLE, HIT, Hand,  DISPLAY_ARBITER, ENABLE_EXTENDED,
  DEALER, DROP, PLAYER, NONE, CHANGE_BORDER, Switch2CardDisplayDm, CardDm, UPDATE_COUNT,
  DEALER_DISPLAY_ID, EXTENDED_DISPLAY_ID, CardDisplay2SwtichDm
} from '../../../store/switchcards.model';
import { InsertC2SSetupAction, InsertC2SAction, InsertSwitch2CardDisplayAction, SendPlayerCardDrawnAction } from '../../../store/switchcards.action.reducer';
//import { SocketIoService } from '../../../services/socket.io.service';
// import { print, FlashButtons, FilterType, START_FLASH, FADEIN, FADEOUT, Flash} from '../../../services/common.service';
import {KeyPressedDm} from '../../store/bottom.model';
import { GenericDataUnitEnum, GenericDataUnit} from '../../store/generic.model';
import { GenericTopTopAction,GenericAddCardCount,GenericRemoveCardCount,GenericSlideConceptTrain,GenericSlidePlayTrain,GenericSettingRestoreFromCach} from '../../store/generic.action.reducer';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import {  MatTableDataSource } from '@angular/material/table';
import {  MatPaginator } from '@angular/material/paginator';
import { FlashButtons, FilterType, START_FLASH,  FADEOUT, FADEIN,nFlash} from '../../../services/common.service';
import {trigger,state,style,transition,animate,keyframes,query,stagger} from '@angular/animations';
import {GenericUpdateRule,GenericCardStrengthAction} from '../../store/generic.action.reducer'

// import { CommonListAnimations} from '../../shared/animations/mobile-animations';
//
// import {
//   trigger,
//   state,
//   style,
//   transition,
//   query,
//   keyframes,
//   stagger,
//   animate,
// } from '@angular/animations';
//import * as io from 'socket.io-client';
import { environment as env } from '@env/environment';
import { UserService } from '@app/services/user.service';

const DRAW = 'Draw';
const BET = 'New-bet';
const SAME = 'Old-bet';
const RESULT = 'Result';
const SHUFFLE = 'Shuffle';
const DECISION = 'decision';
const CONTROL = 'control';
const INSURANCE='insurance';
const IDLE = 0;
const DEFAULT_COLOR = 'primary';

// @Pipe({name: 'strength'})
// export class StrengthPipe implements PipeTransform {
//   transform(value: string): string {
//    switch(value) {
//        case 'W-high': return 'WEAK-HIGH';
//        case 'W-low': return 'WEAK-LOW';
//        case 'S-high': return 'STRONG-HIGH';
//        case 'S-low': return 'STRONG-LOW';
//        case 'M-high': return 'MEDIUM-HIGH';
//        case 'M-low': return 'MEDIUM-LOW';
//        default: return '';
//    }
//   }
// }

interface NgClass {
  width: string;
  height: string;
  border:string;
  font:string;
};

enum PageEnum {
  bet=0,
  play=1,
  result=2,
  setting=3,
  control=0,
  decision=1,
  insurance=2
};

interface ResultTableIf {
	Hand: number;
	Player: string;
	Dealer: string;
	Won: string;
	Lost: string;
	Net: number;
};
@Component({
  selector: 'anms-mobile-train-play',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.scss'],
  // animations: [CommonListAnimations.flip]
  animations: [
    trigger('flash', [
    transition(`* => ${FADEIN}`, [
      style({ opacity: 0 }),
      animate(1200, style({ transform: 'translateX(0) scale(1)', opacity: 1 }))
    ]),

    transition(`* => ${FADEOUT}`, [
      animate(1900, style({ transform: 'translateX(0) scale(0.5)', opacity: 0 }))
    ])
  ])
]
})

export class MobilePlayComponent implements OnInit, OnDestroy {
   // config = {
   //   level: { chosen: 'basic, single-deck',
   //           list: ['basic, single-deck', 'intermediate, double-decks', 'Expert, six-decks'], disabled: false},
   //   };

  //{{vars.cut}}{{gvars['cut']}}{{space}}{{vars.strength}}{{gvars['strength']|strength}}{{space}}{{vars.count}}{{gvars['cardCount']}}</span>
  @Output() public playEvent = new EventEmitter();
  @Input() public mode: string = 'play';
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  @ViewChild('slickModalButtons') slickModalButtons: SlickCarouselComponent;
  @ViewChild('paginator') paginator: MatPaginator;
  id = 5;
  //vars = {} ;
  colors= { primary: 'primary', warn: 'warn', accent: 'accent' };
  userEntry = 'none';
  playedHands=false;
  topEnabled=true;
  drawMode = 'None';
  count = 0;
  debug = false;
  state='none';
  hcount = 0;
  prevBorderIndex=[];
  handKeys= [0, 1, 2, DEALER_DISPLAY_ID, EXTENDED_DISPLAY_ID];
  speedChosen = '3 sec';
  subscriptions: Subscription [] = [];
  hands = {0:{ enabled: true},
           1: { enabled: false},
           2: { enabled: false},
           3: { enabled: false},
           4: { enabled: false}
  };
  displayedBets= {0:'0', 1:'0',2:'0', 3:'0', 4:'0'};
  bets=[];
  handsPlayed: number [];
  buttonReady=false;
  readyCount = 0;
  switch2CntrlDm: Switch2CntrlDm;
  table = {
     chosen : 'stay on soft 17',
     rules : ['stay on soft 17', 'hit on soft 17']
  }
  ddm: Cntrl2SwitchSetupDm;
  numHandsPlayed = 0;
  handResults= [];
  usage = false;
  thumbParams : {};
  spinner= true;
  retry = false;
  hideCount=false;
  cStrength='WEAK';
  cCut='begin';
  cStrengthColor='accent';
  idle = true;
  imageDims={};
  // dropShuffle=false;
  // et scores: { [key: string]: number } = {};
  defaultFontColor='primary-font-color';
  mobile=false;
  gvars:{};
  result=0;
  stack=[];
  page:PageEnum=PageEnum.bet;
  currentSlide: number;
  cachedSlide: number;
  settingWasActive=false;
  slideConfig :any= {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "dots": false,
   "infinite": false,
   'showArrow': false,
   'swipeToSlide': false,
   "arrows": false
 };
 resultReport = {
   columns:['Round','Hand', 'Player','Dealer', 'Won','Lost','Net'],
   source:[],
   net:0,
   pageSizeOptions:[]

 };
 die=false;
 resultTable :  MatTableDataSource<ResultTableIf>;
 resultTableInit=false;
 playDisabled=false;
 buttons={
   hand: ['hit', 'stand', 'double', 'split'],
   play: ['play', 'bet', 'result', 'shuffle'],
   ins: ['yes', 'no']
 }
 disabledHand= {
   hit: true,
   stand: true,
   double: true,
   split: true
 }
 flashes = {};
 disableActions=false;
 savedImage={};
 cardsImage={};
 flashed=[];
  constructor(
    private router: MobileRouterService,
    private dealerService: DealerService,
    private cardswitchService: CardswitchMobileService,
    private store: Store<any>,
            ) {
          // temporary set it 2 1
          globalVars['hands-net']=0;
          //globalVars['#shuffle']=0;
          this.gvars = globalVars;
          this.currentSlide=0;
          this.cachedSlide=PageEnum.bet;
          for (const key in this.buttons) {
              for (const ikey of this.buttons[key]) {
                this.flashes[ikey] = new nFlash;
              }
          }
  }

  afterChange(event:any) {
		 this.currentSlide=event.currentSlide;
	 }
   checkActive(key) {
     return this.flashes[key].isActive();
   }

   flashPlay() {
     if (this.checkActive('play')) return;
     for (const key of ['play', 'bet'])
         this.flashes[key].flash();

   }
   unflashPlay() {
     // if (this.checkActive('play')) return;
     for (const key of ['play', 'bet'])
         this.flashes[key].unflash();

   }
   flashIns() {
    if (this.checkActive('yes')) return;
     for (const key of this.buttons['ins'])
         this.flashes[key].flash();
   }

   flashHand(list) {
     const common=[];
     const klist= list.length >= this.flashed.length ?  this.flashed: list;
     const olist = list.length >= this.flashed.length ? list: this.flashed;
     for (const key of klist) {
       if (olist.includes(key)) {
           common.push(key);
       }
     }

     // console.log(`flashed:${this.flashed}, list:${list}, common:${common}`);
     const delete_from_flashed = lpull(this.flashed, ...common);
     list = lpull(list, ...common);
     // console.log(`--->delete_from_flashed: ${delete_from_flashed}, list:${list}`);
     for (const key of delete_from_flashed) this.flashes[key].unflash();
     this.flashed=lpull(this.flashed, ...delete_from_flashed);
     for (const key of list) {
       this.flashes[key].flash();
       this.flashed.push(key);
     }
     // console.log(`new flashed:${this.flashed}`)
     for (const key of this.buttons['hand']) {
       this.disabledHand[key]=true;
     }
     for (const key of this.flashed) {
       this.disabledHand[key]=false;
     }
   }
  afterChangeButton(event:any) {
    // switch (event.currentSlide) {
    // }
  }

//////////////////////////////

  configControl(resetButton?: boolean):void {
    if (resetButton) {
      this.handOff();
    } else {
      this.playDisabled=false;
      this.slickModalButtons.slickGoTo(PageEnum.control);
    }
  }

  // active(which: string, button: string) {
  //   return this.buttons[which][button].enabled;
  // }

  ngOnDestroy() :void{

     this.cardswitchService.destroy();
     for (const subscription of this.subscriptions) {
       subscription.unsubscribe();
     }
     MobileSendKeyRoute(this.store,[{key:"reset"}]);
     BottomResetMenuPressed(this.store, true);
     globalVars['status']= '';
     globalVars['play'] = false;
     GenericRemoveCardCount(this.store);
     globalVars['image']=this.savedImage;
     for (const key in this.buttons) {
         for (const ikey of this.buttons[key])
           this.flashes[ikey].unflash();
     }

//print(`train-play-component::ngOnDestroy`);
  }

  shuffleAlert() :void{
    // this.dropShuffle=true;
    // setTimeout(() => {
    //   this.dropShuffle=false;
    //
    // }, 5000);
    alert("shuffling the deck");
  }


  istate() {
       const msg=[`Playing:  ${decksMapping()}`];
       if (globalVars['count'] == 'show') {
         GenericAddCardCount(this.store);
       } else {
         GenericRemoveCardCount(this.store);
       }
       GenericTopTopAction(this.store, msg);
       BottomAppleModify(this.store, { enable:['backward','lastIcon'], disable:['forward'],  lastIcon:'settings'});
       //BottomAppleModify(this.store, { enable:['backward','lastIcon'],  disable:['forward']});
	    // this.flashit(['P']);
      this.flashPlay();
  }

 _signalStrength(rcount) {
   const ndecks=globalVars['ndecks'];
   const strong_thold= ndecks *5;
   const medium_thold= strong_thold - ((ndecks-1)*2+2);
   if (rcount >= strong_thold) return GenericDataUnitEnum.strong;
   if (rcount >= medium_thold) return GenericDataUnitEnum.medium;
   // return GenericDataUnitEnum.medium;
   // return GenericDataUnitEnum.strong;
   return GenericDataUnitEnum.weak;
 }

  signalStrength() {
    const count=globalVars['cardCount'];

    switch(globalVars['method']) {
      case 'mit': {
        return this._signalStrength(Math.abs(+count));
      }
      default: {
        return this._signalStrength(this.dealerService.getReducedCount());
      }
    }

  }


  topBottomSubscribe() {
      this.subscriptions.push(
              this.store
                .select('mobileTopBottom')
                .pipe(filter(data => data.typeof=== 'KeyPressedDm'))
                .subscribe((data: KeyPressedDm) => {
                  if (data.target=='parent') {
                     this.die=true;
                     return;
                  }
                    switch(data.key) {
                      case 'home' : {
                          this.die=true;
                          setTimeout(() => {
                            this.router.navigate('mobile/home');
                          }, 200);
                        break;
                      }case 'backward' : {
                             this.die=true;
                             setTimeout(() => {
                               this.router.navigate('mobile/train');
                             }, 200);
                           break;
                         } case 'settings': {
                             this.router.navigate('mobile/setting-train');
                             globalVars['setting'] = {
                              func: GenericSlidePlayTrain,
                              route:'mobile/play'
                            };
                             }
                         }
                    })
            );
  }
  getBorder(id) {
    return id==DEALER_DISPLAY_ID?'border-on-dealer': 'border-on-player';
  }
  // process cards
  scardSubscribe() {
    this.subscriptions.push(
        this.store.select('scard')
        .pipe(filter(data => data.data_type === 'CardDisplay2SwtichDm'))
        .subscribe((data: CardDisplay2SwtichDm) => {
           })
    );

    this.subscriptions.push(this.store.select('scard')
      .pipe(filter(data => data.data_type === 'Switch2CntrlDm'))
      .subscribe((data: Switch2CntrlDm) => {
//print(['data:',data]);
        if (this.idle) { return ;} // added for the rxjs bug
        this.switch2CntrlDm = data;
         if (this.debug) {
          //console.log(`received from switch2control data.action:${JSON.stringify(data)}`);
         }
        switch(data.action) {
          case END_OF_HAND: {
            // this.store.dispatch(new InsertC2SAction(new Cntrl2switchDecisionDm(this.switch2CntrlDm.id, DISPLAY_ARBITER)));
            // console.log(`rcvd end_of_hand in play.component`)
               MobileUnflashPlayerLabel(this.store,0);
            globalVars['#shuffle']= this.dealerService.shuffleCount();
            globalVars['#round']= this.hcount;
            GenericCardStrengthAction(this.store, this.signalStrength());
            const result = this.ddm.result(globalVars);
            // console.log(`hands result: ${JSON.stringify(result)}`);
             // console.log(`last result: ${JSON.stringify(result.slice(-1))}`);
             const net = result.slice(-1)[0]['net'];
            // const net = global['current-net'];

            // console.log(`result:${JSON.stringify(result)}`)
            for (const r of result) { // walk through each hand
                  const bet = r['bet'];
                  const _result=r['result'];
                  if (_result !== 'Push') {
                    if (_result=='Loss') {
                      this.resultReport.net -= bet;
                    } else {
                      this.resultReport.net += bet;
                    }
                  }
                  let net=`${this.resultReport.net}`;
                  if ( this.resultReport.net<0) {
                    net="-$"+net.slice(1);
                  } else {
                    net="$"+net;
                  }
                  this.resultReport.source.push({Round: r['#round'],
                                                 Hand:r['#hand'],
                                                 Player:r['player-hand'],
                                                  Dealer:r['dealer-hand'],
                                                  Won:_result=='Loss'?'-': `${bet}`,
                                                  Lost:_result=='Loss'? `${bet}`:'-',
                                                  Net: net
                                                })
            }
            this.resultTable.data = this.resultReport.source;
            if (!this.resultTableInit) {
              this.resultTableInit=true;
            }
            const time2shuffle = this.time2shuffle(this.handsPlayed.length-1);
            this.disableActions=true;
            setTimeout(() => {
                 if (time2shuffle) {
                   this.dealerService.shuffle();
                   this.shuffleAlert();
                 }

                 if (net >0) {
                   this.displayedBets[3] = '-$'+`${net}`;
                 } else if (net < 0 ) {
                   this.displayedBets[3] ='$'+`${-1*net}`;
                 } else {
                   this.displayedBets[3] ='$0';
                 }



                this.configControl();
            }, time2shuffle?2200:2000);

            this.result++;
            this.flashPlay();
// reduce count
//print([`collect count list:`, this.dealerService.cards.cardCount.collectCount.list]);
            break;
          }
          case CHANGE_BORDER: {
            // here declist is the list of id in string format
            if (this.prevBorderIndex.length) {
              for (const id of this.prevBorderIndex) {
                if (id==DEALER_DISPLAY_ID) continue;
                   // this.ngClass[id].border = 'border-off';
                   MobileUnflashPlayerLabel(this.store,+id);
              }
              if (data.id==-1) {
                for (const id of data.decList) {
                  if (id != '4') {
                    MobileFlashPlayerLabel(this.store,+id);
                    this.prevBorderIndex.push(`${id}`);
                  }

                }
              }
            } else { // initial state
              // console.log(`initial-state with id: ${data.id}`)
              const id = data.id==-1 ? data.decList[0] : data.id;
              this.prevBorderIndex.push(id);
              MobileFlashPlayerLabel(this.store,+id);
            }
            break;
          }
          case GET_DECISION : {
           if (this.debug) {
              console.log(`Get decision hands are :${JSON.stringify(this.hands)}`);
            }
            this.store.dispatch(new InsertC2SAction(new Cntrl2switchDecisionDm(this.switch2CntrlDm.id, DISPLAY_ARBITER)));
            const list= data.decList;
            // console.log(`decision list: ${JSON.stringify(data.decList)}`)
            if (list.includes(INSURANCE_YES)) {
                    this.flashIns();
                    this.slickModalButtons.slickGoTo(PageEnum.insurance);
            } else {
                   this.slickModalButtons.slickGoTo(PageEnum.decision);
                   this.flashHand(list);
            }
            break;
          }
        }
      } ));
  }
  genericReducerSusbsribe() {
    this.subscriptions.push(
          this.store
            .select('genericReducer')
            .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
            .subscribe((data: GenericDataUnit) => {
              if (data.action==GenericDataUnitEnum.die) {
                this.die=true;
                return;
              }
            }
          )
     );
  }

  setStack(nbets) {
    if (nbets==1) {
      this.stack=['flat', 'flat', 'flat'];
      return;
    }
      this.stack=['stack', 'stack', 'stack'];
  }
  ngOnInit() :void{
    globalVars['vpath']="play";
    this.stack=['stack', 'stack', 'stack']

    BottomResetMenuPressed(this.store);

    this.istate();
    globalVars['status']= 'train-play';
    globalVars['play'] = true;
    this.dealerService.shuffle();
    this.dealerService.resetShuffleCount();
    this.mobile=globalVars['mobileDevice'];
    this.cardswitchService.config();
    this.handsPlayed = [];

    this.scardSubscribe();
    this.topBottomSubscribe();
    this.genericReducerSusbsribe();
    this.resultTable = new MatTableDataSource<ResultTableIf>(this.resultReport.source);
    this.savedImage=globalVars['image'];

    if (globalVars['midDevice']) {
      globalVars['image']=imagesAttr.mobile;
    }
    this.imageDims={
      width:`${globalVars['image']['card']['width']}`,
      height:`${globalVars['image']['card']['height']}`

    }
    //maybe flash play button
  }

  betExists():any {
    return Object.keys(this.bets).length;
  }
  configSet():void {
   if (this.betExists()) {
     this.store.dispatch(new InsertC2SAction(new Cntrl2switchDecisionDm(0, RESET)))
   }
  }

  handOff() :void{
    for (const key of this.handKeys) {
      if (this.hands[key].enabled) {
        this.hands[key] = {  enabled: false };
      }
    }
  }

  borderOff() :void{
  }
  checkBet() {
    return globalVars['online-bet'][0]==0?false: true;
  }
  createHands() :any{
//print('createHands');
      globalVars['play']=true;
      this.hcount++;
      this.handOff();
      const hands = {};
      this.handsPlayed = [];
//print([`bets:`, this.bets]);
      this.bets=globalVars['online-bet'];
      const bets = this.bets;
      const count = bets.length;
      this.setStack(count);
      let width='';
      let height="height-1-third";
      switch(count) {
        case 1: {width="width-100p"; break;}
        case 2: {width="width-50p"; break;}
        case 3: {width="width-30p"; break;}

      }
      let i=0;
      for (const i of [0,1,2]) {
        this.hands[i].enabled = false;

      }
      for (const bet of bets) {
        if (bet) {
          this.hands[i].enabled = true;
          this.handsPlayed.push(i);

          this.displayedBets[i] = `${bet}`;
          // for now
          hands[i] = new Hand(i, bet, PLAYER, globalVars['ndecks'], globalVars['soft17'], globalVars['splits']);
          i++;
        }

      }
      this.displayedBets[4] ='0 ';
    this.handsPlayed.push(DEALER_DISPLAY_ID);
    const extEnabled = count != 1;
    this.hands[EXTENDED_DISPLAY_ID].enabled = extEnabled;
    if (this.debug) {
      console.log(`EXTENDED_DISPLAY_ID:${EXTENDED_DISPLAY_ID} enabled: ${this.hands[EXTENDED_DISPLAY_ID].enabled}`);
    }
    // dealer hand
    hands[MAX_NUMBER_OF_HANDS] = new Hand(MAX_NUMBER_OF_HANDS, 1000000, DEALER,1,globalVars['stayOnSoft17'], globalVars['split'] );
    this.hands[MAX_NUMBER_OF_HANDS].enabled = true;
    this.ddm = new Cntrl2SwitchSetupDm({display: { dealer: DEALER_DISPLAY_ID, extended: EXTENDED_DISPLAY_ID }, hands: hands});

    return this.ddm;
    }

  time2shuffle(hands:any):any {
         return this.dealerService.enoughCards(hands)? false: true;
  }

  removeCards() {
   this.dealerService.flushCardCount();
   let timeout=0;
   // if (this.handsPlayed.includes(4)) {
    if (true) {
     MobileDropCardAction(this.store,4);
     timeout+=100;
   }
   for (const hand of this.handsPlayed) {
      if (hand==4) continue;
       setTimeout(() => {
         MobileDropCardAction(this.store, hand);
       }, timeout);
       timeout+=100;
   }
   if (isJack() ) {
     setTimeout(() => {
          this.dealerService.updateGlobalVars();
     }, Math.floor(timeout/2));
   }

   return timeout+500;
  }
  drawCard() :void {
    // ResetReduceCardAction(this.store);
    // this.configHand();
    this.idle=false;
    this.store.dispatch(new InsertC2SSetupAction(this.createHands()));
  }
  resultEvent(e):void {
    if (e=='clear') {
      // this.resetResult();
      return;
    }
    this.slickModalButtons.slickGoTo(PageEnum.control);
  }
  goto(slide) {
    this.slickModal.slickGoTo(slide);
    this.cachedSlide=slide;
  }
  clicked( button: string):void {
    switch (button) {
      case 'bet': {
        const dservice = this.dealerService;
        dservice.reduceit(); // reduce the count
        this.gvars['cardCount'] = dservice.getCount();
        this.borderOff();
        this.removeCards();
        this.goto(PageEnum.bet)
        break;
      }

      case 'result': {
        // MobilePlayerResultAction(this.store,{source:this.handResults,
        //           columns:['#shuffle','#round', '#hand', 'bet', 'dealer-hand', 'player-hand', 'result', 'net']} )
        MobilePlayerResultAction(this.store, {display: true} )  ;
        this.goto(PageEnum.result);
        break;
      }
      case 'play': {
        GenericUpdateRule(this.store, `None`);

        if (!this.checkBet()) {
            alert("Your entered bet is '0'");
            this.goto(PageEnum.bet);
            return;
        }
        this.playDisabled=true;
        this.unflashPlay();
;
        setTimeout(() => {
            this.drawCard();
            if (this.cachedSlide != PageEnum.play) {
              this.goto(PageEnum.play);
            }
        }, this.removeCards());
        break;
      }
      case 'shuffle': {
        this.dealerService.shuffle();
        break;
      } default : {
        if (['yes','no'].includes(button)) {// insurance
           button=`Insurance-${button}`;
        } // decisions
        if (button==DOUBLE) {
          const id=this.ddm.getcid();
          const bet=+this.displayedBets[id] * 2;
          this.displayedBets[id]=`${bet}`;
        }
        this.store.dispatch(new InsertC2SAction(new Cntrl2switchDecisionDm(this.switch2CntrlDm.id, button)));
        break;
      }

    } // end of switch
  }
  getOptions() {
    return globalVars['mobile']?[3,4]: [5,7,10];
  }
   ngAfterViewInit() {
     this.resultReport.pageSizeOptions=globalVars['mobile']?[3,4]: [5,10];
     this.resultTable.paginator = this.paginator;
   }

}
