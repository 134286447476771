import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
// import { DealerService } from '../../../services/deck.service';
import { Observable,Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';

import { isJack,globalVars, licenseConfig,MobileRouterService,SpeedInSec,getMethodDesc,getTheLesson } from '../../../services/global.service';

import { DealerService } from '../../../services/deck.service';

import {ActivatedRoute} from "@angular/router";

import {KeyPressedDm, BottomQuesButtonsPressed} from '../../store/bottom.model';
import {MobileSendKeyRoute, BottomQuesButtonsSend,BottomResetMenuPressed, BottomAppleModify,BottomQuesButtonsLessonSend} from '../../store/bottom.action.reducer';


import {MobileDrawCardAction, MobileDisplayCount, MobileDrawCardResetAction} from '../../store/cards.action.reducer';
import {MobileCountCardDataUnit} from '../../store/cards.model';
import {Shuttle, GroupButtons, Button, GroupButtonsEnum,GetCount } from '../../shared/classes/common';
import { GenericTopTopAction,GenericSlideCountTrain,GenericSlideLicenseTrain,GenericSlideConceptTrain, GenericTimerAction,GenericSingleCardTrain,GenericSingleSpeedTrain } from '../../store/generic.action.reducer';
import { GenericDataUnitEnum, GenericDataUnit} from '../../store/generic.model';
import {BottomNumbersComponent} from '../numbers/component';
import {ThumbsTimerComponent} from '../thumbs-timer/component';


const IDLE = 0;
enum SettingActiveEnum {
	active,
	idle
};
enum CardsStateEnum {
	drawn,
	clear
};


@Component({
  selector: 'anms-mobile-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['../../styles.scss'],
})

export class MobileTestingComponent implements OnInit, OnDestroy {
  @ViewChild("number") number: BottomNumbersComponent;
	@ViewChild("thumbs_timer") thumbsTimer: ThumbsTimerComponent;

  subscriptions=[];
  unit='';
  gvars = {};
  cardCount='0';
  buttons: Array<Button>;
  reroute=false;
  buttonsEnabled=false;
  tbuttons : GroupButtons;
  userPossibleCount : {list:any, clist: any};
  shuttle800: Shuttle;
  dropShuffle=false;
  hcolor='accent';
  firstDraw=true;
  continuous=false;
  die=false;
  cardState=CardsStateEnum.clear;
  slideConfig= {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "dots": false,
   "infinite": false,
   arrows: false,
   'swipeToSlide': false,
 };
 settingState:SettingActiveEnum=SettingActiveEnum.idle;
 settingWasActive=false;
 netcount=false;
 showNumbers=true;
 numberMargin=0
 settingFunc=null;
 numberControlSetup=true;
 label='';
 settingKey='';
 marginTop='10px';
 topLeft=0;
 topPos=0;
 restore={
	 cards:false
 }
///////
// constructor
///////
  constructor(
    private activatedRoute: ActivatedRoute,
    private dealerService: DealerService,
    private router: MobileRouterService,
    public store: Store<any>,
            ) {
          this.gvars = globalVars;
          globalVars['state']= 'train-testing';
          this.buttons=[];
          for (let i=0; i!=4; i++) {
            this.buttons.push( new Button(`${i}`));
          }
          const tbuttons =[];
          for (let i=0; i!=2; i++) {
            tbuttons.push(new Button(`0`));
            tbuttons[i].color = i?'warn':'accent';
            tbuttons[i].changed = false;
          }

          this.tbuttons = new GroupButtons(tbuttons, GroupButtonsEnum.one, ['correct', 'wrong'])
          this.tbuttons.setLength(2);
          this.shuttle800= new Shuttle(1000); //changed to 1000
          const modifyIcon = (button:any, icon:any) => {
            if (button.changed == false || button.name =='0') {
              return;
            }
            if (button.icon) {
              button.icon = false;
            } else {
              button.icon = true;
              button.iconValue=icon;
            }
          }
          this.shuttle800.add(()=> {
            modifyIcon(this.tbuttons.get('correct'), 'thumb_up');
          });
          this.shuttle800.add(()=> {
            modifyIcon(this.tbuttons.get('wrong'), 'thumb_down');
          });
   }
////////

  ngOnDestroy() :void{
   for (const subscription of this.subscriptions) {
       subscription.unsubscribe();
     }
     MobileSendKeyRoute(this.store,[{key:"reset"}]);
     this.shuttle800.destroy();
     globalVars['mode']='';
     globalVars['testing'] = false;
     globalVars['state'] = 'top';
     BottomResetMenuPressed(this.store, true);
     this.clearScreen();
     // console.log(`testing ngOnDestroy called`)
		 this.dealerService.shuffle();
		 if (this.restore.cards)
		     globalVars['cards-out']=3;
  }
/////////

/////////
// ngOninit task
/////////


setTrainingMode() {
	return globalVars['mode'].includes('practice')? GenericSlideLicenseTrain: GenericSlideCountTrain;
}

eventHandler(e:any):void {
  switch(e) {
    case 'removed': {
			if (this.settingWasActive) {
				globalVars['setting'] = {
				 func: GenericSlideCountTrain,
				 route:`mobile/tests/${this.unit}`,
				 speed:800,
				 mode:globalVars['mode']
			 };
				this.router.navigate('mobile/setting-train');
				return;
			}
			if (this.reroute) {
				globalVars['setting'] = {
					func: this.settingKey=='speed'?  GenericSingleSpeedTrain: GenericSingleCardTrain,
					route:`mobile/tests/${this.unit}`,
					speed:800,
					mode:globalVars['mode']
				}
				this.router.navigate('mobile/setting-train');
				return;
			}


			if (this.netcount) {
				this.dealerService.reduceit();
			}
			if(this.dealerService.isCountTooHigh()) {
				setTimeout(() => {
					alert("Shuffling the decks, reset the count.");
					this.dealerService.shuffle();
					this.draw();
				}, 1000);
			}  else this.draw();
			// BottomAppleModify(this.store, {disable:['forward'],flash:{forward: false}});
      this.showNumbers=true;
      break;
    } case 'drawn': {
      // console.log(`drawn is activated:count:${this.getCount()}`)
			this.showNumbers=true;
      this.fillupCountButs(this.getCount());
      this.cardState=CardsStateEnum.drawn ;
			BottomAppleModify(this.store, { enable:['lastIcon']});
      break;
    } default: { // shuffle event
      // console.log(`received shuffle`)
      alert("Shuffling the decks, reset the count.");
      this.draw();
    }
  }
}

clearScreen(delay=0) :void {
	setTimeout(() => {
		setTimeout(()=>this.buttonsEnabled=false, 700);
		MobileDrawCardResetAction(this.store);
	}, delay);

}

fillupCountButs(expected:any):void {
   this.userPossibleCount = GetCount(expected, this.netcount);
	 if (globalVars['learning']) {
		 BottomQuesButtonsLessonSend(this.store, this.userPossibleCount.list, this.continuous, this.userPossibleCount.clist[0]);
		 return;
	 }
	 // this continuous for this call is a don't care parameter
	 // alert(`correct value:${ this.userPossibleCount.clist[0]}`)
		BottomQuesButtonsSend(this.store, this.userPossibleCount.list, this.continuous, this.userPossibleCount.clist[0]);
}

track(compared) :void{
    this.tbuttons.track(compared);
}

tevent(event) {
	  this.thumbsTimer.thumbsevent(event);
}

istate() {
    const msg=[getMethodDesc('testing'), getTheLesson()];
		msg.push(this.label);
    GenericTopTopAction(this.store, msg);
    BottomAppleModify(this.store, {enable:['backward', 'lastIcon'], disable:['forward'],  lastIcon:'settings'});
  }


timeout():any {
  const cout = globalVars['cards-out'];
  const timePerRow=1300; // 1second
  const npanels = globalVars['npanels']
  return npanels*timePerRow;
}
 reset_count() {
	 const reset=globalVars['method']=='ten'? 20*globalVars['ndecks']: 0;
	alert(`Resetting the count back to '${reset}'`);
 }
 reset() {
	 this.reset_count();
	 this.clearScreen();
   this.tbuttons.reset();
  	this.dealerService.shuffle();


}

go2continueous() {
	this.reset();
	globalVars['cach'] = {
		unit: this.unit,
		vpath: globalVars['vpath'],
		label: this.label,
		netcount: this.netcount,
		continuous: false,
		mode: globalVars['mode']
	};
	this.continuous=true;
	globalVars['vpath']='count/tests/Continuous';
	this.unit="Continuous";
	this.label="Continuous test, no shuffling";
	this.istate();

	this.netcount=true;
	globalVars['mode']='Continuous';
}

cachRestore() {
	this.reset();
	const cach=globalVars['cach'];
	this.unit=cach['unit'];
	globalVars['vpath']=cach['vpath'];
	this.label=cach['label'];
	this.netcount=cach['netcount'];
	this.continuous=false;
	this.istate();
	globalVars['mode']=cach['mode'];

	globalVars['cach']=null;

}

// 'Net count with cards': 'mobile/Test/net-count-1',
// 'Net count no cards': 'mobile/Test/net-count-2',
// 'Adding and net count': 'mobile/Test/all',
// 'Certification': 'mobile/Certificate'

//
// program flow::
//  draw -> fillupcount -> control(pressed-count)->[clear-buttons|mobilesendfalsh downkey]
// clear-button->unflash, reset buttons, buttons enabled false, draw

  ngOnInit() :void{
    BottomResetMenuPressed(this.store);
		setTimeout(() => {
			GenericTimerAction(this.store, {min:0, tick:GenericDataUnitEnum.uptimer});
		}, 200);
     globalVars['testing']=true;
    const config=licenseConfig();
    this.hcolor = globalVars['method'] == 'mit' ? 'warn': 'accent';
    this.marginTop=globalVars['mobileDevice']?'20px': '10px';
    this.subscriptions.push(
         this.activatedRoute.params.subscribe( ( params:any) => {
          this.unit = params['id'];
          globalVars['mode']=params['id'];
					// alert(`params[id]=${params['id']}`)
					const practice = params['id'].includes('practice');
					const continuous=params['id'].includes('Continuous');
					globalVars['vpath']=`count/tests/${this.unit}`;
					// const prefix=practice?'': params['id'].includes('Pre')? '': 'Testing ';

          // this.label=prefix+params['id'];
					this.label=practice? 'Certification practice': params['id']+ ' test';
					if (this.label.includes('net')) {
						this.label=this.label.replace('net', 'running').replace('-1', '');
					} else if (continuous) {
						this.label="Continuous test, no shuffling";
					}
				  this.netcount=params['id'].includes("net") || continuous;
          this.continuous =  continuous|| practice;
					// globalVars['learning']=this.continuous?false:globalVars['learning'];

					// if (this.continuous) {
					// 		globalVars['ndecks']=6;
					// }
					if (practice) {
						globalVars['adjustSpeed']=true;
						const image = globalVars['image'];
						this.topPos=config['top']+100;
						this.topLeft=config['left'];
						globalVars['cards-out'] = config['cards'];
						globalVars['mode']='practice';
						this.restore.cards=true;
					} else {
						const cout=globalVars['cards-out'];
						const extra=100;
						const top=config['top'];
						let _rows=3;
						if (globalVars['desktop']) _rows=4;
						if (globalVars['mobileDevice']) _rows=2;
						const pixelPerRow=Math.floor(top/_rows);

						const distr=globalVars['mobileDevice']? 5:8;
						const rows = Math.floor(cout/distr)+ (cout%distr?1:0);
						this.topPos=pixelPerRow*rows;
						this.topLeft=config['left']+50;
					}
					globalVars['dropCards']=continuous? false: true;
          if (practice)  {
						if (globalVars['speed'] > 800) {
							globalVars['speed'] = 800;
						}
            globalVars['mode'] = 'license';
						this.netcount=true;
          }
					this.istate();
       }));

     this.subscriptions.push(
             this.store
               .select('mobileTopBottom')
               .pipe(filter(event=> event.typeof=== 'BottomQuesButtonsPressed'))
               .subscribe((data: BottomQuesButtonsPressed) => {
                 const clist = this.userPossibleCount.clist;
                 switch(data.button) {
									 // alert(`data.button:${data.button}`)
									 case 'lesson': {
										 globalVars['testing']=false;
										 let net=false;
										 if (globalVars['cach']) {
											 net=globalVars['cach']['netcount'];
											 globalVars['cach']=null;
										 }
										 const route=net || this.unit.includes('net') ? 'mobile/lessons/net-count': `mobile/lessons/adding-count`
										 this.router.navigate(route);
										 return;
									 } case 'continuous-test': {
									   	this.go2continueous();
										  return;
										}case 'reset': {
											this.reset();
											return;
									 } case 'test' : {
										 this.cachRestore();
										 return;
									 }
                   case 'speed':
									 case 'cards':{
										 // this.reroute=true;
						         // this.settingKey=data.button;
										  setTimeout(() => {

												 globalVars['setting'] = {
								 					func: data.button=='speed'?  GenericSingleSpeedTrain: GenericSingleCardTrain,
								 					route:`mobile/tests/${this.unit}`,
								 					speed:800,
								 					mode:globalVars['mode']
								 				}
								 				this.router.navigate('mobile/setting-train');
												 	 }, 200);

										 return;
									 }

									  default: {
											if (clist[0] ==data.button) { // if pressed correct number
		                    this.clearScreen();
		                    this.track(true);
		 								 } else {
		 	             	   MobileDisplayCount(this.store); //
		                    this.track(false);
		 									 // BottomAppleModify(this.store, {enable:['forward'],flash:{forward: true}});
		                    this.showNumbers=false; // this causes the -> button to be active on the page
		                  }
										}
								 }

               })
             );
       this.subscriptions.push(
           this.store
             .select('mobileTopBottom')
             .pipe(filter(data => data.typeof=== 'KeyPressedDm'))
             .subscribe((data: KeyPressedDm) => {
							 if (data.target=='parent') {
								  this.die=true;
									return;
							 }
							switch(data.key) {
								case 'home' : {
										this.die=true;
										setTimeout(() => {
											this.router.navigate('mobile/home');
										}, 200);
									break;
								}
				           case 'forward': {
									       if(this.settingState==SettingActiveEnum.active) { return; }
	                       this.clearScreen();
	                       MobileDrawCardResetAction(this.store);
									       break;
									   } case 'backward' : {
									       if(this.settingState==SettingActiveEnum.active) { return; }
	                       this.die=true;
												 this.router.navigate('mobile/train');
												 globalVars['learning']=false;
									       break;
			     } case 'settings': {
							this.settingWasActive=true;
							 this.clearScreen();
			     }
					}
							             })
         );

        this.subscriptions.push(
              this.store
                .select('genericReducer')
                .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
                .subscribe((data: GenericDataUnit) => {
	               	if (data.action==GenericDataUnitEnum.die) {
	                    this.die=true;
	                    return;
	                  }
										if (data.action==GenericDataUnitEnum.timerReset) {
											    this.tbuttons.reset();
		                    return;
		                  }
                  if (data.action==GenericDataUnitEnum.settingDone) {
                    this.istate();
                    // this.slickModal.slickPrev();
                    this.settingState=SettingActiveEnum.idle;
										 this.settingWasActive=false;
									    if (this.cardState==CardsStateEnum.drawn) {
									       // BottomAppleModify(this.store, {enable:['forward']});
									    }

                  }

                }
              )
         );

     this.draw();
  }



  getCount() :any {
		return this.netcount?  this.dealerService.getReducedCount(): this.dealerService.getCount();
  }


  draw() :void{

    // MobileDrawCardAction(this.store, !this.unit.includes('All'));
    MobileDrawCardAction(this.store, this.firstDraw);
    this.firstDraw=this.continuous?false:true;
		// BottomAppleModify(this.store, { disable:['lastIcon']});

  }
	clicked() {
			this.clearScreen();
	}
}
