<ng-container *ngIf="mobile; else desktop">
  <div class="top">

      <div *ngIf="basic">
        <p class="primary-font-color">Let us know what is on your mind</p>
          <div class="flex-column ">
            <mat-form-field>
              <input matInput  class="primary-font-color" style="font-size:1.2rem" required type="email" placeholder="Email Address" [(ngModel)]="email">
            </mat-form-field>
             <mat-form-field>
              <input matInput class="primary-font-color" style="font-size:1.2rem" required  placeholder="Subject" [(ngModel)]="subject">
            </mat-form-field>

            <mat-form-field class="text-area primary-font-color  min-width">
               <mat-label>Content:</mat-label>
                <mat-icon matSuffix>mode_edit</mat-icon>
                <textarea matInput required [(ngModel)]="content"
                  style="font-size:1.2rem"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="2"
                  cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>
            <div class="flex-row-reversed">
               <button mat-raised-button color="accent" (click)="contact()">Send</button>
             </div>
          </div>
      </div>

      <div *ngIf="response">
          <p> {{message}} </p>
      </div>

      <div  style="display: flex; flex-direction: column;" *ngIf="obj">
        <div *ngFor="let key of keys">
                <p> {{key}} </p>
        </div>
      </div>
  </div>
</ng-container>
<ng-template #desktop>
  <div class="top">
      <mat-card class="margin0" *ngIf="basic">
        <mat-card-title class="primary-font-color">Let us know what is on your mind</mat-card-title>
        <mat-card-content>
          <div class="flex-column ">
            <mat-form-field>
              <input matInput  class="primary-font-color" style="font-size:1.2rem" required type="email" placeholder="Email Address" [(ngModel)]="email">
            </mat-form-field>
             <mat-form-field>
              <input matInput class="primary-font-color" style="font-size:1.2rem" required  placeholder="Subject" [(ngModel)]="subject">
            </mat-form-field>

            <mat-form-field class="text-area primary-font-color  min-width">
               <mat-label>Content:</mat-label>
                <mat-icon matSuffix>mode_edit</mat-icon>
                <textarea matInput required [(ngModel)]="content"
                  style="font-size:1.2rem"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="2"
                  cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>
            <div class="flex-row-reversed">
               <button mat-raised-button color="accent" (click)="contact()">Send</button>
             </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card *ngIf="response">
        <mat-card-content>
          <p> {{message}} </p>
        </mat-card-content>
      </mat-card>

      <div  style="display: flex; flex-direction: column;" *ngIf="obj">
        <mat-card   *ngFor="let key of keys">
            <mat-card-content>
                <p> {{key}} </p>
            </mat-card-content>
        </mat-card>
      </div>
  </div>
</ng-template>
