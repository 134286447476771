export enum  GenSettingEnum {
  deck_changed,
  count_changed,
  d1card,
  changed_ack,
  start_timer,
  stop_timer,
  reset_timer,
  on_expiration_reset,
  on_expiration_stop,
  lesson_test_changed,
  hide_content,
  reset_config,
  reset_apps,
  sidenav_left_close,
  sidenav_right_close,
  sidenav_left_open,
  sidenav_right_open,
  goto_train,
  reset_mobile,
  play_config_change,
  mobile_train,
  mobile_counting_overview,
  mobile_counting_selection,
  mobile_learing_selection,
  mobile_decks_config,
  mobile_testing_selection,
  mobile_netcount,
  mobile_countup,
  mobile_test_addtion,
  mobile_test_netcount,
  mobile_test_math,
  mobile_test_certificate,
  mobile_test_continueous,
  mobile_root,
  loginSuccessful,
  userHaveCredit,
  logout,
  enableSubscription,
  disableSubscription,
  None
}

export interface GenSettingModel {
    typeof: 'GenSettingModel';
    action: GenSettingEnum;
}
