import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { globalVars ,RouterService, isPageRoute,getVideoUrl } from '../../services/global.service';
import { Store } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';
import { MainBottomDm, BottomQuesButtons,BottomMenuReset,BottomAppleButtons} from '../store/bottom.model';
import {MobileSendKeyPressed, BottomQuesButtonsPressedSend, MobileSendSubNavigationInfo} from '../store/bottom.action.reducer';
import { FlashButtons, FilterType, START_FLASH,  FADEOUT, FADEIN,Flash} from '../../services/common.service';
import {trigger,state,style,transition,animate,keyframes,query,stagger} from '@angular/animations';

import {KeyPressedDm} from '../store/bottom.model';

const IDLE = 0;
const DEFAULT_COLOR = 'primary';
const HEIGHT='50';
const XHEIGHT='150';

@Component({
  selector: 'anms-top-bottom',
  templateUrl: './component.html',
  styleUrls: ['../styles.scss'],
  animations: [
    trigger('flash', [
      transition(`* => ${FADEIN}`, [
        style({ opacity: 0 }),
        animate(1200, style({ transform: 'translateX(0) scale(1)', opacity: 1 }))
      ]),
      transition(`* => ${FADEOUT}`, [
        animate(1900, style({ transform: 'translateX(0) scale(0.5)', opacity: 0 }))
      ])
    ])
  ]
})


export class TopBottomComponent implements OnInit, OnDestroy{
  @Input('vertical') vertical:boolean=false;
  keys = ['backward', 'forward', 'lastIcon'];
  home='home';
  subscriptions = [];
  model='others';
  flashed='';
  buttons :{};
  location=window.location;
  maps: any = {
    list: [],
    icons:[],
    disabled:true
  };

  qheight=HEIGHT;
  lastIcon="add_circle";
  settingEnabled=false;
  // flash = { name : 'DRAW', color: DEFAULT_COLOR, status: IDLE, disabled: false };
  constructor(
   private routerService: RouterService,
   private store: Store<any>) {
     // this.disableAll();

   }
   videoUrl() {
     return getVideoUrl();
   }
   reset():void {
     this.buttons =  {
       up: { name : 'UP', color: DEFAULT_COLOR, status: IDLE, disabled: true,  flashed: false, flash: new Flash() },
       down: { name : 'DOWN', color: DEFAULT_COLOR, status: IDLE, disabled: true, flashed: false, flash: new Flash() },
       forward: { name : 'FORWARD', color: DEFAULT_COLOR, status: IDLE, disabled: true, flashed: false, flash: new Flash()},
       backward: { name : 'BACKWARD', color: DEFAULT_COLOR, status: IDLE, disabled: true,  flashed: false,flash: new Flash()},
       lastIcon: { name : 'lastIcon', color: DEFAULT_COLOR, status: IDLE, disabled: true,  flashed: false,flash: new Flash()},
       menu: { name : 'MENU', color: DEFAULT_COLOR, status: IDLE, disabled: false,flashed: false, flash: new Flash() }
     };
     // this.unflash('all');
     this.home='home';
   }
   // flash up, down, forward, backward
   flashit(name: string, color='warn'):void {
         const button = this.buttons[name];
         if (button.flashed) {
           return;
         }
         button.color=color;
         button.disabled = false;
         button.flash.flash(button);
         button.flashed = true;
   }
   _unflash(dict:any):void {
     dict.flash.unflash();

     // if (dict.name != 'home') {
     //   dict.disabled= true;
     // }
     dict.flashed=false;
     dict.color= DEFAULT_COLOR;
   }
   unflash(key:any):void  {
     if (key=='all') {
       for (let [key, dict] of Object.entries(this.buttons)) {
         if (dict['flashed']) {
           this._unflash(dict);
         }
       }
       return;
     }
     this._unflash(this.buttons[key])
  }
 ///////////////////////////
 switch():void {
   this.home = this.home == 'done'? 'home': 'done';
 }

///////////////////////
  ngOnInit():void {
    // this.unflash('all');
    this.reset();
                this.subscriptions.push(
                  this.store
                    .select('mobileTopBottom')
                    .pipe(filter(data => data && data.typeof=== 'BottomAppleButtons'))
                    .subscribe((data: BottomAppleButtons) => {
                        const action: any=data.action;
                        if ('model' in action) {
                          if (action['model']=='others') {
                            this.model='others';
                            return;
                          }
                        }
                        this.model='apple';
                        // console.log(`bottom rcvd:${JSON.stringify(action)}`)
                  			if ('reset' in action) {
                  			    this.reset();
                  			    return ;
                  			}
                        //console.log(`this action:${JSON.stringify(action, null, 2)}`);
                         if ('list' in action) {
                           this.maps.list=action['list'];
                         }
                         if ('icons' in action) {
                           this.maps.icons=action['icons'];
                         }

                         if ('enable' in action) {
                           const enabled: any=action['enable'];
                           if (enabled.length) {
                             enabled.map(key=>this.buttons[key].disabled=false);
                             this.settingEnabled = enabled.includes('setting');
                           }

                           // console.log(`enabled:${JSON.stringify(enabled)}, settingEnabled:${this.settingEnabled}`);

                         }
                         if ('disable' in action) {
                           const disabled:any =action['disable'];
                           if (disabled.length) {
                             disabled.map(key=>this.buttons[key].disabled=true);
                           }
                         }
                    		if ('lastIcon' in action) {
                             const icon:any =this.lastIcon;
                    				 this.lastIcon=action['lastIcon'];
                             // alert(`changing icon from :${icon} to ${this.lastIcon}`)
                    		}
                         if ('flash' in action) {
                           const flash = action ['flash'];
                           for (const key in flash){
                             if (flash[key]) {
                                 this.flashit(key);
                             } else {
                               this.unflash(key);
                             }
                           }
                         }
                         this.maps.disabled = this.maps.list.length?false: true;
                      }
                    ));

  }
//////////////////////
ngOnDestroy():void {
    for (const subscriber of this.subscriptions) {
      subscriber.unsubscribe();
    }
    this.reset();
}

disableAll() :void{
  for (const key of this.keys) {
    this.buttons[key].disabled=true;
  }
}

////////////////
  clicked(key:any):void {
    // console.log(`bottom comp. received key: ${key}`);
    if (isPageRoute(key)) {
        MobileSendKeyPressed(this.store,key);
     	return;
    }
    MobileSendSubNavigationInfo(this.store, key=='lastIcon'? this.lastIcon: key);

  }
}
