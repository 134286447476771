import { Component, OnInit, OnDestroy,Input } from '@angular/core';
import { MobileRouterService } from '../../services/global.service';
import { Store } from '@ngrx/store';
import { CommonListAnimations} from '../shared/animations/mobile-animations';
import { globalVars} from '../../services/global.service';
import { filter, tap } from 'rxjs/operators';
import { GenericDataUnitEnum, GenericDataUnit} from '../store/generic.model';
import { GenericTopTopAction} from '../store/generic.action.reducer';

const m2c = (card) => `/assets/cards/${card}${globalVars['image'].dims}.png`;
@Component({
  selector: 'anms-landing-sample-cc',
  templateUrl: './lpagegame.component.html',
  styleUrls: ['../styles.scss'],
  animations: [
    CommonListAnimations.anim,
    CommonListAnimations.manim,
    CommonListAnimations.flip,
    CommonListAnimations.cards
  ]
})
export class LandingPageComponent implements OnDestroy, OnInit  {
//  @ViewChild('countStepper') private countStepper: MatStepper;
  // subscription = {};
  //columns=['Count', 'High count', 'Low count', 'Net count', 'Spread', 'comments'];
  //
  // supported modes are: split, nsplit, double, ndouble
  //
  @Input() mode:string='split';
  @Input() id:string='0';
  @Input() delay=800;
  imageHeight=[];
  imageH=0;
  pageEnabled=false;
  label={
    top:'',
    dealer:['',''],
    player:['', ''],
    cls:'landing-warn-bg'
  };
  margin={top:20};
  cards = {
    dealer:[],
    player:[[], []],
    shand:false,
    split: {
      dealer: ['6C', 'Gray_back', '3D', '8C'],
      player: [['AH', '4C'], ['AD', '3C']]
    },
    nsplit: {
      dealer: ['6C', 'Gray_back', '3D', '8C'],
      player: ['AH', 'AC','4D', '3C']
    },
    double: {
      dealer: ['6C', 'Gray_back', '4D', '8C'],
      player: ['9H', '2C', 'Gray_back','3H']
    },
    ndouble: {
      dealer: ['6C', 'Gray_back', '4D', '8C'],
      player: ['9H', '2C','3H']
    },
    quiz6high: {
      dealer: ['6C', 'Gray_back'],
      player: ['4H', '2C']
    }
    // ndouble: {
    //   dealer: ['6C', 'Gray_back', '4D', '8C'],
    //   player: [['9H', '2D', '3H']]
    // },
  };

  cardQueue=[];
  images= [];
  subscriptions=[];
  interval:any;
  height:number;
  end=false;
  gvars=globalVars;
  iid=0;
  cardsClass="cards-makeup";

  thumbs={color:'warn', direction:'down'};


  setThumb(t="down") {
    if (t=='down') {
       this.thumbs={color:'warn', direction:'thumb_down'};
       return;
    }
    this.thumbs={color:'accent', direction:'thumb_up'};
  }
  constructor(private store:Store<any> ) {}
  split() {
     this.label.top='Loosing twice as much';
     this.label.cls='landing-warn-bg';
     this.label.dealer[0]="Dealer's hand";
     this.label.player[0]="Player splitting aces";
     GenericTopTopAction(this.store, [ "Non trained player","Ignores the cards",'Splits aces with a big loss'], 'monkey');

     this.setThumb();
     this.cardQueue = [
       ()=>this.cards.player[0].push(m2c(this.cards.split.player[0][0])),
       ()=>this.cards.dealer.push(m2c(this.cards.split.dealer[0])),
       ()=>this.cards.player[0].push(m2c(this.cards.split.player[1][0])),
       ()=>this.cards.dealer.push(m2c(this.cards.split.dealer[1])),
       /* the first 2 cards are dealt */
       ()=>{
              this.label.player[0]="Player's hand split-1";
              this.label.player[1]="Player's hand split-2";
              this.cards.player[0].shift();
              this.cards.shand=true;
             },
       ()=>this.cards.player[1].push(m2c(this.cards.split.player[1][0])),
       ()=>this.cards.player[0].push(m2c(this.cards.split.player[0][1])),
       ()=>this.cards.player[1].push(m2c(this.cards.split.player[1][1])),
       ()=>this.cards.dealer.splice(1,1,m2c(this.cards.split.dealer[2])),
       ()=>this.cards.dealer.push(m2c(this.cards.split.dealer[3])),
       ()=> {this.label.dealer[1]=", winning $1000"; this.end=true;}
     ];
     this.margin.top=20;
   }

   csplit() {
     this.label.top= 'Wins a hand';
     this.label.cls='landing-accent-bg';

      this.label.dealer[0]="Dealer's hand";
     this.label.player[0]="Player hitting 2 aces";
     GenericTopTopAction(this.store, ['Trained player',"Follows the cards",'Hits aces maximizes winning'], 'tigger');

     this.setThumb('up');


     this.cardQueue = [
       ()=>this.cards.player[0].push(m2c(this.cards.nsplit.player[0])),
       ()=>this.cards.dealer.push(m2c(this.cards.nsplit.dealer[0])),
       ()=>this.cards.player[0].push(m2c(this.cards.nsplit.player[1])),
       ()=>this.cards.dealer.push(m2c(this.cards.nsplit.dealer[1])),
       ()=>this.cards.player[0].push(m2c(this.cards.nsplit.player[2])),
       ()=>this.cards.player[0].push(m2c(this.cards.nsplit.player[3])),
       ()=>this.cards.dealer.splice(1,1,m2c(this.cards.split.dealer[2])),
       ()=>this.cards.dealer.push(m2c(this.cards.nsplit.dealer[3])),
        ()=> {this.label.dealer[1]=", loosing $500"; this.end=true;}
     ];
     this.margin.top=30;
   }

   double() {
     // this.label.top=;
     this.label.top='Loses twice as much';
     this.label.cls='landing-warn-bg';

     this.label.dealer[0]="Dealer's hand";
     this.label.player[0]="Player doubling down on 11";
     let dindex=0;
     let pindex=[0,0];
     this.margin.top=20;
     this.setThumb();

     GenericTopTopAction(this.store, ["Non trained player","Ignores the cards",'Doubles down with a big loss'], 'monkey');

     this.cardQueue = [
       ()=>this.cards.player[0].push(m2c(this.cards.double.player[0])),
       ()=>this.cards.dealer.push(m2c(this.cards.double.dealer[0])),
       ()=>this.cards.player[0].push(m2c(this.cards.double.player[1])),
       ()=>this.cards.dealer.push(m2c(this.cards.double.dealer[1])),
       ()=>this.cards.player[0].push(m2c(this.cards.double.player[2])),
       ()=>this.cards.dealer.splice(1,1,m2c(this.cards.double.dealer[2])),
       ()=>this.cards.dealer.push(m2c(this.cards.double.dealer[3])),
       ()=>this.cards.player[0].splice(2,1,m2c(this.cards.double.player[3])),
       ()=> {this.label.dealer[1]=", winning $1000"; this.end=true;}
     ];
   }

   cdouble() {
     // this.label.top=;
    this.label.top="Loses half as much";
     this.label.dealer[0]="Dealer's hand";
     this.label.player[0]="Player's hand, hitting on 11";
     this.label.cls='landing-accent-bg';

     let dindex=0;
     let pindex=[0,0];
     this.setThumb('up');


     GenericTopTopAction(this.store, ['Trained player',"Follows the cards","Hits 11, minimizes losses"], 'tigger');
     this.cardQueue = [
       ()=>this.cards.player[0].push(m2c(this.cards.ndouble.player[0])),
       ()=>this.cards.dealer.push(m2c(this.cards.ndouble.dealer[0])),
       ()=>this.cards.player[0].push(m2c(this.cards.ndouble.player[1])),
       ()=>this.cards.dealer.push(m2c(this.cards.ndouble.dealer[1])),
       ()=>this.cards.player[0].push(m2c(this.cards.ndouble.player[2])),
       ()=>this.cards.dealer.splice(1,1,m2c(this.cards.ndouble.dealer[2])),
       ()=>this.cards.dealer.push(m2c(this.cards.ndouble.dealer[3])),
       ()=> {this.label.dealer[1]=", winning $500"; this.end=true;}
     ];
     this.margin.top=30;
   }

   quiz6high() {
     // this.label.top=;
     this.label.top="High cards very likely";
     this.label.dealer[0]="Dealer's hand";
     this.label.player[0]="Player's hand";
     this.label.cls='landing-accent-bg';

     let dindex=0;
     let pindex=[0,0];
     this.setThumb('up');
     GenericTopTopAction(this.store, ['Trained player',"Quiz, player's 6 vs dealer's 6","Hit or Stand"], 'tigger');
     this.cardQueue = [
       ()=>this.cards.player[0].push(m2c(this.cards.quiz6high.player[0])),
       ()=>this.cards.dealer.push(m2c(this.cards.quiz6high.dealer[0])),
       ()=>this.cards.player[0].push(m2c(this.cards.quiz6high.player[1])),
       ()=>this.cards.dealer.push(m2c(this.cards.quiz6high.dealer[1])),
     ];
     this.margin.top=30;
   }

   reset() {
     this.resetCards();
     this.end=false;
     clearInterval(this.interval);
     this.cardQueue=[];

   }

   fillqueue() {
     this.interval=setInterval(()=>{
        if (!this.pageEnabled) {
          clearInterval(this.interval);
          return;
        }
         if (this.cardQueue.length) {
            this.cardQueue.shift()();
            return;
         }
         clearInterval(this.interval);
       },
           this.delay);
     switch(this.mode) {
       case 'split' : {this.split(); break;}
       case 'csplit' : {this.csplit(); break;}
       case 'double' : {this.double(); break;}
       case 'cdouble' : {this.cdouble(); break;}
       case 'quiz-6-high' : {this.quiz6high(); break;}
     }
   }

   ngOnDestroy() :void {
     clearInterval(this.interval);
      for (const elm of this.subscriptions)
           elm.unsubscribe();
       this.pageEnabled=false;
    }

    resetCards() {
      this.cards.dealer=[];
      this.cards.player=[[],[]];
    }

   ngOnInit() :void{
     this.height = globalVars['height']-100;
     const imageHeight=globalVars['image']['height'];
     this.imageH=imageHeight;
     this.imageHeight=[imageHeight+400, imageHeight*2+400];
     if (globalVars['mobileDevice']) this.cardsClass='';
     this.subscriptions.push(
           this.store
             .select('genericReducer')
             .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
             .subscribe((data: GenericDataUnit) => {
               switch(data.action) {
                 case GenericDataUnitEnum.reset : {
                   this.reset();
                   break;
                 }
                 case GenericDataUnitEnum.config_enable:{
                   if (data.id==this.id) {
                     setTimeout(()=>this.fillqueue(), 500);
                     this.pageEnabled=true;
                   }
                   break;
                 }
                 case GenericDataUnitEnum.config_disable:{
                   if (data.id==this.id) {
                     this.pageEnabled=false;
                   }
                   break;
                 }

                 case GenericDataUnitEnum.dieSlave:{
                      if (data.id==this.id) {
                        // alert(`anms-landing-page dieSlave id:${this.id} rcvd:${JSON.stringify(data)}`)
                          this.reset();

                       }
                    break;
                  }

               }
             }
           )
      );

  }
}
