

      <!-- <div  style="margin-top:20px;width:100%; height:100%;margin-left:{{gvars['center-width']}}px"> -->
      <div  style="margin-top:{{margin.top}}px; height:100%;" *ngIf="pageEnabled">
        <!-- <div  style="margin-top:{{margin.top}}px; height:100%;"> -->

              <div class="flex">
                   <p > {{label.top}}</p>
                   <mat-icon style="margin-left:10px" [color]="thumbs.color">  {{thumbs.direction}} </mat-icon>
              </div>

              <!-- <div  style="min-height:25%"> -->
                    <span *ngIf="cards.dealer.length"> {{label.dealer[0]}} <span *ngIf="end"> {{label.dealer[1]}} </span></span>


                    <!-- <div  style="min-height:{{imageH+40}}px; max-width:400px; margin-top:10px" > -->
                    <div class="flex">
                          <div  style="min-height:{{imageH+40}}px; margin-top:10px;" >

                                <div class="flex " [@cards]="cards.dealer.length" >

                                     <img  src="{{cimage}}" *ngFor="let cimage of cards.dealer" class="cards-spacing" style="margin-top:10px">
                              </div>
                        </div>

                    </div>

              <!-- </div> -->
                <p style="margin-top:10px; margin-bottom:10px"> player betting $500 </p>
                <div class='flex' style="margin-top:10px">
                        <div style="min-height:{{imageH+70}}px">
                                  <p style="color:white" *ngIf="cards.player[0].length"> {{label.player[0]}} </p>
                                  <div class="flex " [@cards]="cards.player[0].length" >
                                         <img  src="{{cimage}}" *ngFor="let cimage of cards.player[0]" class="cards-spacing" style="margin-top:10px">
                                  </div>
                        </div>
                        <div   *ngIf="cards.shand" style="min-height:{{imageH+70}}px;margin-left:40px">
                                  <p style="color:white" *ngIf="cards.player[1].length"> {{label.player[1]}} </p>
                                  <div class="flex " [@cards]="cards.player[1].length" style="margin-top:10px">
                                         <img  src="{{cimage}}" *ngFor="let cimage of cards.player[1]" class="cards-spacing"  style="margin-top:10px">
                                  </div>
                        </div>

                </div>
  </div>
  <!-- <div class='flex' style="min-height:25%"> -->
  <!-- <div  style="position: absolute; top:{{imageHeight[1]}}px;left:{{gvars['center-width']}}px;" > -->
  <!-- <div class="flex " [@cards]="cards.dealer.length" style="min-width:200px"> -->
