<div  style="height:100%; margin-top:10px; margin-left:{{mleft}}px">
              <!-- <div class="flex {{die}}" style="width:240px;"> -->
          <div class="flex">
                <ng-container *ngFor="let button of qbuttons.buttons;let i=index">
                        <button mat-fab   (click)="clicked(button)" style="margin-left:5px" class="toolbar-scale numbers-nav-primary"> {{button}}</button>
                </ng-container>
         </div>
        <div class="flex"  style=" margin-top:20px;margin-left:{{numberMargin}}px" *ngIf="lesson" >

                <button mat-flat-button  (click)="clicked('cards')" style="margin-left:3px"
                   [disabled]="control.cards"  [color]="'warn'" class=" scale-p9">  cards  </button>

                <button mat-flat-button  (click)="clicked('speed')" style="margin-left:3px"
                         [disabled]="control.speed" [color]="'warn'"  class=" scale-p9">  speed  </button>


                   <button mat-raised-button style='margin-left:5px' [matMenuTriggerFor]="menu"
                           class="toolbar-scale" [color]="'primary'" > more
                   </button>
                    <mat-menu #menu="matMenu"  class="flex-column">
                      <button mat-menu-item  (click)="clicked(testkey)" class="warn-bg" style="margin-top:3px">  {{testkey}}</button>
                      <button mat-menu-item  (click)="clicked('reset')" class="warn-bg" style="margin-top:3px">  reset</button>
                      <button mat-menu-item  (click)="clicked('lesson')" class="warn-bg" style="margin-top:3px">  lesson</button>



                        <button mat-menu-item  *ngFor="let button of menulist" (click)="clicked(button)" class="warn-bg" style="margin-top:3px">  {{button}} </button>
                    </mat-menu>



      </div>


</div>
<!-- class="toolbar-scale flex-grow-1 numbers-nav"> {{button}}</button> -->
<!-- <button mat-button style='margin-left:5px' [matMenuTriggerFor]="menu"
        class="toolbar-scale" [color]="'accent'"> <mat-icon>  more_vert </mat-icon>
        <div class="flex flex-center" style="margin-left:{{numberMargin}}px">

</button> -->
<!-- <div class="flex parent-center" > -->
<!--  -->
<!-- <button mat-fab  *ngIf="qbuttons.enabled[button]" (click)="clicked(button)"  [@flash]="qbuttons.flashinfo[i].status.status" -->
<!-- <div *ngIf="!die" style="height:100%; margin-top:10px; margin-left:{{mleft}}px"> -->
<!-- [@flash]="qbuttons.flashinfo[i].status.status" -->
