import { Component, ViewChild, EventEmitter, Output} from '@angular/core';
import {ThumbsComponent} from '../thumbs/component'

@Component({
  selector: 'anms-thumbs-timer',
  templateUrl: './component.html',
  styleUrls: [],
})

export class ThumbsTimerComponent {
  @ViewChild("thumbs") thumbs: ThumbsComponent;
  @Output() timerEvent = new EventEmitter<any>()
  constructor() {
   }

  thumbsevent(event) {
    this.thumbs.thumbsevent(event)
  }
  tevent() {
    this.timerEvent.emit();
  }
}
