<div class="row-center">
   <div class="column-between">
       <mat-tab-group (selectedTabChange)="tabChanged($event)">
          <mat-tab  *ngFor="let tab of menu.tabs" label="{{tab}}"> </mat-tab>
       </mat-tab-group>
       <p style="font: bold 1rem sans-serif; margin-top:20px"> Select video from the following menus:</p>
       <div class="top">
            <mat-form-field style="font-size:18px; margin-top:20px; margin-right:20px" >
                 <mat-select [(value)]="menu.ngmodel.parent" placeholder="Categories" (selectionChange)="selectionChanged('category')" >
                 <mat-option value="{{option}}" *ngFor="let option of menu.pkeys"><span class="primary-font-color"> {{option}}</span></mat-option>
            </mat-select>
            </mat-form-field>
            <mat-form-field style="font-size:18px; margin-top:20px; margin-right:20px" >
                 <mat-select [(value)]="menu.ngmodel.child" placeholder="Items" (selectionChange)="selectionChanged(menu.ngmodel.parent)" >
                     <mat-option value="{{option}}" *ngFor="let option of menu.ckeys"><span class="primary-font-color"> {{option}}</span></mat-option>
                 </mat-select>
            </mat-form-field>
       </div>

       <!-- <video #video  width="{{width}}"  controls style="margin-top:20px; "> <source    type="video/mp4"> </video> -->
       <vg-player>
            <video #media [vgMedia]="media" id="singleVideo" preload="auto" controls>
              <source  type="video/mp4">

            </video>
      </vg-player>
       <div class="row-start">
          <button mat-button [color]="'primary'" [matMenuTriggerFor]="lmenu"> language <mat-icon>arrow_drop_down</mat-icon> </button>
          <mat-menu #lmenu="matMenu"> <button mat-menu-item *ngFor="let lang of menu.languages" (click)="languageClick(lang)"> <span style="font-size:1.5em"> {{lang}} </span> </button> </mat-menu>
          <div class="row-reverse">
               <mat-card class="margin"> {{ thumbs.down}} </mat-card>
               <button mat-button [color]="'warn'" (click)="thums(false)"> <mat-icon class="scale1a3" [color]="'warn'"> thumb_down</mat-icon> </button>
              <mat-card class="margin"> {{ thumbs.up}} </mat-card>
             <button mat-button [color]="'primary'" (click)="thums(true)"> <mat-icon class="scale1a3" [color]="'primary'"> thumb_up</mat-icon> </button>
         </div>
         <div *ngIf="false">
            <mat-form-field class="comment" *ngIf="true">
              <mat-label>Your comment:</mat-label>
                 <textarea matInput placeholder="Ex. this is example..."></textarea>
              </mat-form-field>
         </div>
      </div>
</div>
</div>
