import { Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Store } from '@ngrx/store';
import { MobileRouterService, globalVars, verificationCode } from '../services/global.service';
import {KeyPressedDm} from '../mobile/store/bottom.model';
import {MobileSendKeyRoute,  BottomResetMenuPressed, mainBottomSwitchActionNone} from '../mobile/store/bottom.action.reducer';
import { map, filter } from 'rxjs/operators';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { UserService } from '../services/user.service';
import { HttpClient } from '@angular/common/http';
import {SendLoginSuccess} from '../store/gen-setting.action.reducer';
import {GenericStopAction,GenericTopTopAction} from '../mobile/store/generic.action.reducer';

@Component({
  selector: 'anms-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['../mobile/styles.scss']

})


export class SignupComponent  implements OnDestroy, OnInit  {

@ViewChild('slickModal') slickModal: SlickCarouselComponent;
   subscriptions= [];
   slideConfig= {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": false,
    'swipeToSlide': true,
     arrows: false,
  };
  vars = globalVars;
  // enabled=false;
  colors={login:'primary', signup:'accent'};
  loginData={
    email:'',
    password:'',
    repeatPassword:'',
    verification:'',
    pin:''
  };
  state='';
  errorMessage='';
  error = {
    email:false,
    password:false,
    verification:false,
    pin:false,
    login:false
  };
  loginEnabled=true;
  vcode:string;
  constructor(public store: Store<any>,
              private routeService:MobileRouterService,
              public router: MobileRouterService,
               public userService: UserService,
              private http: HttpClient
             ) {}
  afterChange(event:any):void {}

  resetError() {
    this.error = {
    email:false,
    password:false,
    verification:false,
    login:false,
    pin:false
    };
  }
  subscription() {
    globalVars['timerOn']=false;
    this.router.navigate('/subscriptions');
  }
  loginCredential() {
    const params = {
                email: this.loginData.email,
                password: this.loginData.password,
                pin: this.loginData.pin,
                type:'login'
              } ;
    this.userService.credential(params)
               .subscribe( (resp:any) => {
                 // console.log(`login credential response:${JSON.stringify(resp)}`);
                 const code = resp['code'];
                  switch(code) {
                    case 707: {
                      this.error.email=true;
                      // this.errorMessage='email mismatched, re-enter the email';
                      alert("not a valid email, re-enter the email or signup.");
                      break;
                    }case 709: {
                      // this.error.password=true;
                      this.errorMessage='password mismatched, re-enter the email';
                      alert("not a valid password, re-enter the password.");

                      break;
                    }case 711: {
                      alert('Please check your email for your password');
                      this.router.navigate('about');
                      break;
                    }
                    case 705: {
                       alert ('Email provided is not correct, please login with correct email');
                         this.router.navigate('about');
                         break;
                    }
                   case 800:
                   case 700: {
                           const credit = resp['credit'];
                           globalVars['now']=resp['started']
                           globalVars['credit']=credit;

                           globalVars['free'] = resp['free'];
                           globalVars['timerOn']=false;
                           if (code==800) {
                              alert("Check your email for your password");
                              this.userService.sendPlainEmail(this.loginData.email,
                                  'jackcount password recovery',
                                   `Your password :${resp['password']}`) ;
                           }
                           globalVars['email']=this.loginData.email;
                           if (credit>0) {
                             // check Access
                             const access=resp['access'];
                             const token=resp['token'];
                             if (access!='both') {
                               if (globalVars['mobileDevice'] && access!='mobile') {
                                 alert("Your subscription is for desktop device, need to upgrade for mobile access.");
                                 this.router.navigate('/subscriptions');
                               } else if (!globalVars['mobileDevice'] && access=='mobile') {
                                 alert("Your subscription is for mobile device, need to upgrade for desktop access.");
                                 this.subscription();
                               }
                             } // access oky

                             localStorage.setItem('token', token);
                             globalVars['token'] = token;
                             globalVars['isAuthenticated'] = true;
                             globalVars['updateCredit'] = true;
                             SendLoginSuccess(this.store);
                           } else {
                             globalVars['credit']=0;
                             globalVars['isAuthenticated']=false;
                             alert('You are out of credit, sending you to subscription page');
                             this.subscription();
                           }
                           return;
                         }
                  }
                });
  }


  signupCredential() {
    GenericTopTopAction(this.store, ['One day free pass when you sign-up']);
    this.userService.credential(
      {
        email: this.loginData.email,
        password: this.loginData.password,
        pin: this.loginData.pin,
        type:'signup'
      }

    ).subscribe( (resp:any) => {
                // console.log(`signup credential response:${JSON.stringify(resp)}`);

                if (resp['code']==700) {
                  const credit = resp['credit'];
                  globalVars['credit']=credit;
                  globalVars['now']=resp['started'];
                  var not_allowed = false;
                  if (credit>0) {
                    // check Access
                    const access=resp['access'];
                    globalVars['timerOn']=false;
                    if (access!='both') {
                      if (globalVars['mobileDevice'] && access!='mobile') {
                        alert("Your subscription is for desktop device, need to upgrade for mobile access.");
                        not_allowed = true;
                      } else if (!globalVars['mobileDevice'] && access=='mobile') {
                        alert("Your subscription is for mobile device, need to upgrade for desktop access.");
                        not_allowed = true;
                      }
                      if (not_allowed) {
                        this.subscription();
                        return;
                      }
                    } // access oky

                    localStorage.setItem('token', resp['token']);
                    globalVars['token'] = resp['token'];
                    globalVars['isAuthenticated'] = true;
                    globalVars['updateCredit'] = true;
                    SendLoginSuccess(this.store);
                  } else {
                    globalVars['credit']=0;
                    globalVars['isAuthenticated']=false;
                    alert('You are already signed up but out of credit, sending you to subscription page');
                    globalVars['email']=this.loginData.email;
                    this.subscription();
                  }
                  return;
                } // end of 700
              });
  }
  clicked(key:any):void {
     switch(key) {
       case 'note': {
           // this.router.navigate('about');
           this.state='login';
           break;
       }
       case 'login': {
          this.resetError();
         if (this.state=='') {
           this.slickModal.slickPrev();
           break;
         }
         this.loginCredential();
         break;
       }

       case 'signup': {
         if (this.loginEnabled) {
           this.loginEnabled=false;
           return;
         }
         this.resetError();
         if (this.state=='') {
           this.slickModal.slickPrev();
           break;
         }
         if ( this.loginData.password != this.loginData.repeatPassword) {
           this.error.password=true;
           this.errorMessage='passwords are not matching.';
           break;
         }
         if ( this.loginData.pin =='' || this.loginData.pin.length<5) {
           this.error.pin=true;
           this.errorMessage='You need to enter 5 digit pin';
           break;
         }
         this.vcode = verificationCode();
         this.userService.sendPlainEmail(this.loginData.email, 'jackcount verification code', `Your verification code is ${this.vcode}`) ;
         this.slickModal.slickNext();
         break;
       }
       case 'verification': {
         if (this.vcode != this.loginData.verification) {
           alert("The verification code entered is not valid, check your email again.")
           break;
         }
         this.signupCredential();
       }

     } // end of switch

  } // end of clicked

  ngOnInit() :void {
    GenericTopTopAction(this.store, ['Login if you already have an account']);
    mainBottomSwitchActionNone(this.store);

    // setTimeout(() => {
    //   this.enabled=true;
    // }, 200);
    GenericStopAction(this.store);
    this.state='top';
    // this.routeService.navigate('mobile/train');
     this.subscriptions.push(
     this.store
       .select('mobileTopBottom')
       .pipe(filter(event => event.typeof=== 'KeyPressedDm'))
       .subscribe((data: KeyPressedDm) => {
         // need to see approriate data with keys pressed
         const key = data.key;
         if (['done', 'home'].includes(key)) {
            setTimeout(() => {
              this.router.navigate('about');
            }, 200);
         }
       }));

      }

   ngOnDestroy() :void {
     for (const subs of this.subscriptions) {
       subs.unsubscribe();
     }
     MobileSendKeyRoute(this.store,[{key:"reset"}]);

   }

}
