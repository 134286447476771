
<div  style="margin-left:{{gvars['center-width']}}px; height:100%;" *ngIf="!die">
         <ngx-slick-carousel style="height:80%" class="carousel" #slickModal="slick-carousel" [config]="slideConfig"   (afterChange)="afterChange($event)" *ngIf="!die" >
             <div ngxSlickItem class="slide" >
               <div style="margin-top:45px !important;">
                    <anms-online-betting > </anms-online-betting>
               </div>
              </div>

             <div ngxSlickItem style="height:100%" class="slide" style="margin-top:20px">
                 <div class="flex-column">
                          <mobile-dealer-cards-display [id]="3" label="Dealer({{displayedBets[3]}})"> </mobile-dealer-cards-display>
                         <div class="flex-row" style="margin-top:15px">
                               <ng-container *ngFor="let id of [2,1,0]">
                                   <div *ngIf="hands[id].enabled" style="min-width:{{imageDims['width']*2+20}}px; min-height:{{imageDims['height']}}px;margin-right:15px">
                                       <mobile-player-cards-display [id]="id" label="Hand({{id+1}})${{displayedBets[id]}}" [mode]='stack[id]' ></mobile-player-cards-display>
                                 </div>
                               </ng-container>
                         </div>
                </div>
             </div>
           <div ngxSlickItem class="slide" >
               <div *ngIf="resultTableInit" class="cards-width">
                        <div class="mat-elevation-z8 margin-top-20px table-font" >

                            <mat-table  [dataSource]="resultTable">
                                  <ng-container  [matColumnDef]="col" *ngFor="let col of resultReport.columns">
                                        <mat-header-cell *matHeaderCellDef > {{ col }} </mat-header-cell>
                                       <mat-cell *matCellDef="let element"> {{element[col]}} </mat-cell>
                                  </ng-container>
                                  <mat-header-row *matHeaderRowDef="resultReport.columns"></mat-header-row>
                                  <mat-row *matRowDef="let row; columns: resultReport.columns;"></mat-row>

                            </mat-table>
                        </div>
                </div>
                <mat-paginator #paginator  [pageSizeOptions]="getOptions()" showFirstLastButtons  class="cards-width"></mat-paginator>

           </div>
         </ngx-slick-carousel>
         <div  style="height:20%; !important">
             <ngx-slick-carousel style="margin-top:30px;" class="carousel" #slickModalButtons="slick-carousel" [config]="slideConfig" (afterChange)="afterChangeButton($event)">
                <div ngxSlickItem class="slide">
                  <div class="ctrl-bottom-margin" >
                      <ng-container *ngFor="let elm of buttons['play']" >
                        <button  mat-fab
                                   [@flash]="flashes[elm].status"
                                    class="bgold imargins"
                                    (click)="clicked(elm)"> {{elm}}</button>
                        </ng-container>
                   </div>
                </div>
                <div ngxSlickItem class="slide">
                  <div class="ctrl-bottom-margin" >
                            <ng-container *ngFor="let elm of buttons['hand']" >
                              <button  mat-fab
                                         [disabled]="disabledHand[elm]"
                                         [@flash]="flashes[elm].status"
                                          class="bgold imargins"
                                          (click)="clicked(elm)"> {{elm}}</button>
                              </ng-container>
                  </div>
                </div>
                <div ngxSlickItem class="slide">
                  <div class="ctrl-bottom-margin">
                      <p> Insurance: </p>
                      <ng-container *ngFor="let elm of buttons['ins']" >
                        <button  mat-fab
                                   [@flash]="flashes[elm].status"
                                    class="bgold imargins"
                                    (click)="clicked(elm)"> {{elm}}</button>
                        </ng-container>
                  </div>
                </div>
             </ngx-slick-carousel>
          </div>
   </div>
 <!-- <div class="flex-row" style="min-width:{{imageDims['width']}}; min-height:{{imageDims['height']}};margin-top:15px">
   </div> -->
<!--
   <div class="flex-column">
           <div  class="flex" style="min-width:{{imageDims['width']}}px; min-height:{{imageDims['height']}}px; margin-top:15px">
                    <mobile-dealer-cards-display [id]="3" label="Dealer({{displayedBets[3]}})" > </mobile-dealer-cards-display>
           </div>
           <div class="flex-row" style="margin-top:15px">
                 <ng-container *ngFor="let id of [2,1,0]">
                     <div *ngIf="hands[id].enabled" style="display:flex;min-width:{{imageDims['width']*2+20}}px; min-height:{{imageDims['height']}}px;margin-right:15px">
                         <mobile-player-cards-display [id]="id" label="Hand({{id+1}})${{displayedBets[id]}}" [mode]='stack[id]' ></mobile-player-cards-display>
                   </div>
                 </ng-container>
           </div>
  </div> -->
