
        <div style="margin-left:{{gvars['center-width']}}px; width:80%"  *ngIf="!die">
           <div [ngSwitch]="state" >
               <div  *ngSwitchCase="'weight'">
                 <span   style="position: absolute; top:200px; left:{{gvars['center-width']+scale.left}}px;"> balanced state</span>
                 <span   style="position: absolute; top:300px; left:{{gvars['center-width']+scale.left}}px;"> 5 high cards out, low cards very likely</span>
                 <span   style="position: absolute; top:400px; left:{{gvars['center-width']+scale.left}}px;"> 5 low cards out, high cards very likely </span>
               </div>
               <div  *ngSwitchCase="'cards0'">
                 <span   class="card-count-dressing"
                         style="position: absolute; top:{{top}}px; left:{{gvars['center-width']+75}}px; height:25px; z-index:4">25%
                  </span>
                  <span   class="card-count-dressing"
                          style="position: absolute; top:{{top}}px; left:{{gvars['center-width']+300}}px; height:25px;z-index:4">25%
                   </span>
                   <span   class="card-count-dressing"
                           style="position: absolute; top:{{top+150}}px; left:{{gvars['center-width']+75}}px; height:25px;z-index:4">25%
                    </span>
                    <span   class="card-count-dressing"
                            style="position: absolute; top:{{top+150}}px; left:{{gvars['center-width']+300}}px; height:25px;z-index:4">25%
                     </span>
               </div>
               <div  *ngSwitchCase="'cards1'">
                 <span   class="card-count-dressing"
                         style="position: absolute; top:{{top}}px; left:{{gvars['center-width']+75}}px; height:25px; z-index:4">25%
                  </span>
                  <span   class="card-count-dressing"
                          style="position: absolute; top:{{top}}px; left:{{gvars['center-width']+300}}px; height:25px;z-index:4">25%
                   </span>
                   <span   class="card-count-dressing"
                           style="position: absolute; top:{{top+150}}px; left:{{gvars['center-width']+75}}px; height:25px;z-index:4">50%
                    </span>

               </div>
           </div>
          <div class="flex-column" >
                    <ng-container [ngSwitch]="mode">
                       <ng-container *ngSwitchCase="'auto'">
                                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (afterChange)="afterChange($event)">

                                     <div ngxSlickItem class="slide">
                                       <anms-mobile-fair> </anms-mobile-fair>
                                     </div>
                                     <div ngxSlickItem class="slide">
                                       <anms-landing-sample-cc  delay="1200" mode="split" id='1'> </anms-landing-sample-cc>
                                     </div>
                                     <div ngxSlickItem class="slide">
                                       <anms-landing-sample-cc  delay="1200" mode="csplit" id='2'> </anms-landing-sample-cc>
                                     </div>
                                     <div ngxSlickItem class="slide">
                                       <anms-landing-sample-cc  delay="1200" mode="double" id='3'> </anms-landing-sample-cc>
                                     </div>
                                     <div ngxSlickItem class="slide">
                                       <anms-landing-sample-cc  delay="1200" mode="cdouble" id='4'> </anms-landing-sample-cc>
                                     </div>
                                     <div ngxSlickItem class="slide">
                                       <div style="margin-top:75px">
                                           <mat-container *ngFor="let header of list; let i=index">
                                                 <mat-card  [ngClass]="i==3? 'last-panel': 'rest-panel'">
                                                       <mat-card-content>
                                                          <span style="margin-left:10px">{{i+1}}.<span style="margin-left:2px">{{header}}</span></span>
                                                       </mat-card-content>
                                                 </mat-card>
                                            </mat-container>
                                      </div>
                                           <div  class="flex" style="margin-top:50px; margin-left:200px">
                                              <button mat-raised-button  [color]="'warn'" (click)="bclicked('demo')">Back</button>
                                              <button mat-raised-button  [color]="'accent'" class="" style="margin-left:20px;" (click)="bclicked('signup')">Signup</button>
                                          </div>
                                    </div>

                                </ngx-slick-carousel>
                       </ng-container>
                       <ng-container *ngSwitchCase="'video-first'">
                         <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" >
                               <div ngxSlickItem class="slide">
                                 <anms-mobile-fair> </anms-mobile-fair>
                               </div>
                              <div ngxSlickItem class="slide">
                                <anms-landing-sample-cc  delay="{{delay}}" mode="split" id='5'> </anms-landing-sample-cc>
                              </div>
                              <div ngxSlickItem class="slide">
                                <anms-landing-sample-cc  delay="{{delay}}" mode="csplit" id='6'> </anms-landing-sample-cc>
                              </div>
                              <div ngxSlickItem class="slide">
                                <anms-landing-sample-cc  delay="{{delay}}" mode="double" id='7'> </anms-landing-sample-cc>
                              </div>
                              <div ngxSlickItem class="slide">
                                <anms-landing-sample-cc  delay="{{delay}}" mode="cdouble" id='8'> </anms-landing-sample-cc>
                              </div>
                              <div ngxSlickItem class="slide">
                                <anms-landing-sample-cc  delay="{{delay}}" mode="quiz-6-high" id='9'> </anms-landing-sample-cc>
                              </div>
                         </ngx-slick-carousel>
                       </ng-container>

                       <ng-container *ngSwitchCase="'video-second'">
                           <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" >
                                 <div ngxSlickItem class="slide" style="margin-top:40px">
                                        <anms-mobile-cards-struct> </anms-mobile-cards-struct>
                                 </div>
                                 <div ngxSlickItem class="slide">
                                            <div class="flex-column"  style="margin-top:30px">
                                                <h3> One low and one high card </h3>
                                                <div class="flex-row " >
                                                         <img  src="{{hidden_card}}" *ngFor="let image of [1,2]" class="cards-spacing">
                                                </div>

                                            </div>
                                 </div>
                                 <div ngxSlickItem class="slide" >
                                   <div class="flex-column" style="margin-top:40px">
                                     <div class="flex">
                                          <div class="flex-column"  style="margin-left:50px">
                                              <h3 style="color:white"> 2 high cards </h3>
                                              <div class="flex-row " style="justify-content: center;align-items: center;z-index:1">
                                                       <img  src="{{image}}" *ngFor="let image of vimages[1]" class="cards-spacing">
                                              </div>

                                          </div>
                                             <div class="flex-column"  style="margin-left:50px">
                                                 <h3 style="color:white"> 2 low cards </h3>
                                                 <div class="flex-row " style="justify-content: center;align-items: center;z-index:1">
                                                          <img  src="{{image}}" *ngFor="let image of vimages[2]" class="cards-spacing">
                                                 </div>

                                             </div>

                                     </div>
                                     <div class="flex">
                                       <div class="flex-column"  style="margin-left:50px">
                                           <h3 style="color:white"> Mix cards </h3>
                                           <div class="flex-row " style="justify-content: center;align-items: center;z-index:1">
                                                    <img  src="{{image}}" *ngFor="let image of vimages[3]" class="cards-spacing">
                                           </div>

                                       </div>
                                       <div class="flex-column"  style="margin-left:50px">
                                           <h3 style="color:white"> Mix cards </h3>
                                           <div class="flex-row " style="justify-content: center;align-items: center;z-index:1">
                                                    <img  src="{{image}}" *ngFor="let image of vimages[4]" class="cards-spacing">
                                           </div>

                                       </div>

                                     </div>
                                   </div>
                                 </div>
                                 <div ngxSlickItem class="slide">
                                   <div class="flex-column" style="margin-top:40px">
                                     <div class="flex">
                                          <div class="flex-column"  style="margin-left:50px">
                                              <h3 style="color:white"> 2 high cards </h3>
                                              <div class="flex " style="justify-content: center;align-items: center;">
                                                       <img  src="{{image}}" *ngFor="let image of vimages[1]" class="cards-spacing">
                                              </div>

                                          </div>
                                             <div class="flex-column"  style="margin-left:50px">
                                                 <h3 style="color:white"> 2 low cards </h3>
                                                 <div class="flex " style="justify-content: center;align-items: center;">
                                                          <img  src="{{image}}" *ngFor="let image of vimages[2]" class="cards-spacing">
                                                 </div>

                                             </div>

                                     </div>
                                     <div class="flex">
                                       <div class="flex-column"  style="margin-left:50px">
                                           <h3 style="color:white"> Mix cards </h3>
                                           <div class="flex" style="justify-content: center;align-items: center;">
                                                    <img  src="{{image}}" *ngFor="let image of vimages[3]" class="cards-spacing">
                                           </div>

                                       </div>
                                     </div>
                                   </div>
                                 </div>
                                 <div ngxSlickItem class="slide" style="margin-top:40px">
                                    <anms-mobile-prediction-overview> </anms-mobile-prediction-overview>
                                 </div>



                            </ngx-slick-carousel>
                       </ng-container>

                       <ng-container *ngSwitchCase="'video-scale'">
                           <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" >
                         <div ngxSlickItem class="slide">
                           <mat-card class="example-card" *ngFor="let card of ['low', 'high']" >
                                      <mat-card-title>Five {{card}} cards</mat-card-title>
                                      <mat-card-content>
                                            <div class="flex-row " >
                                                   <img  src="{{image}}" *ngFor="let image of images[card]" class="cards-spacing">
                                            </div>
                                        </mat-card-content>
                              </mat-card>
                         </div>
                         <div ngxSlickItem class="slide">
                            <img  src="/assets/misc/balance-weight-{{scale.img}}.jpg">
                         </div>
                       </ngx-slick-carousel>
                       </ng-container>
                       <ng-container *ngSwitchCase="'video-cardcount'">
                           <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" >
                         <div ngxSlickItem class="slide">
                           <mat-card class="example-card" *ngFor="let card of ['low', 'high']" >
                                      <mat-card-title>Five {{card}} cards</mat-card-title>
                                      <mat-card-content>
                                            <div class="flex-row " >
                                                   <img  src="{{image}}" *ngFor="let image of images[card]" class="cards-spacing">
                                            </div>
                                        </mat-card-content>
                              </mat-card>
                         </div>
                         <div ngxSlickItem class="slide">
                           <anms-nl-cells > </anms-nl-cells>
                         </div>
                       </ngx-slick-carousel>
                       </ng-container>
                     </ng-container>

            </div>

          </div>
