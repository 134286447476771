import { Component, OnInit, OnDestroy,ViewChild } from '@angular/core';
import { MobileRouterService, globalVars,getMethodDesc } from '../../services/global.service';
import { Store } from '@ngrx/store';
import {BaseListComponent} from '../shared/list/component';
import {MobileSendKeyRoute,BottomAppleModify} from '../store/bottom.action.reducer';
import { CommonListAnimations} from '../shared/animations/mobile-animations';
import {GenericTopTopAction} from '../store/generic.action.reducer';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

import { map, filter } from 'rxjs/operators';
import {KeyPressedDm} from '../store/bottom.model';


@Component({
  selector: 'anms-mobile-netcount-strategy',
  templateUrl: './netcount.strategy.component.html',
  styleUrls: ['../styles.scss']
})


export class MobileNetcountStrategyComponent  implements OnDestroy, OnInit  {
//  @ViewChild('countStepper') private countStepper: MatStepper;
  // subscription = {};
  //columns=['Count', 'High count', 'Low count', 'Net count', 'Spread', 'comments'];
    @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  subscriptions=[];
  gvars=globalVars;
  disabled={forward:false, backward:true};
  slidesCount=0;
  statements= [
       // [
       //   "Running count used for:",
       //   "Betting",
       //   "Hand's decisions like:",
       //   "Hit, split, double down and stand"
       // ],
       [
         "Running count should be calculated at the end of the hand",
         "The goal is to reduce the count",
         "to either a low or high card count",
         "Press next->"
       ],
       [
         "If the count is labeled 'L' or 'H'",
         "No subtraction needed",
         "If the count contains mixture of high and low cards",
         "subtract out the smaller count",
         "If smaller count was a low cards count",
         "then, the count reduces to only high cards",
         "Count: '3, 2' then, the count is (3-2) is 1H",
         "Press next->"
       ],
       [
         "If smaller count was a high card count",
         "then, the count reduces to only low cards",
         "Count: '2, 3' then, the count is (3-2) is 1L",
         "Press next->"
       ],
        [
           "More Examples:",
           "count '5, 7', (7-5) is 2L",
           "count '7, 5', (7-5) is 2H",
           "count '5, 9', (9-5) is 4L",
           "count '9, 5', (9-5) is 4H",
           "count '12, 18', (18-12) is 6L",
           "count '18, 12', (18-12) is 6H",
           "count '8, 8', (8-8) is 0"
         ]
       ];

  slideConfig= {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": false,
     arrows:false,
     'swipeToSlide': true,
  };
  extraMsg='';
  constructor(
    public store: Store<any>,
     private router: MobileRouterService
            ) {
	      // globalVars['state'] = 'train-concept';
        // super(store);
      }

  ngOnDestroy():void {
     for (const elm of this.subscriptions)
          elm.unsubscribe();
      MobileSendKeyRoute(this.store,[{key:"reset"}]);
   }

   clicked(key) {
     if (key=='forward') {
        this.slickModal.slickNext();
        return;
     }
     this.slickModal.slickPrev();
   }
   afterChange(event:any):void {
       if (!event.currentSlide) {
         this.disabled.forward=false;
         this.disabled.backward=true;
         return;
       }
       if ((this.slidesCount-1)==event.currentSlide) {
         this.disabled.forward=true;
         this.disabled.backward=false;
         return;
       }
       this.disabled.forward=false;
       this.disabled.backward=false;
   }

   ngOnInit():void {
     const list=[getMethodDesc('Running count/Count down concept'),'Pick the smaller count',"Subtract out the smaller count"];
     if (globalVars['method']=='single') list.push("or when either high or low count is '9'");
    this.slidesCount=this.statements.length;
      GenericTopTopAction(this.store, list, 'tigger');
      BottomAppleModify(this.store, { disable:['forward'], enable:['backward']});
      globalVars['vpath']="concept/running-count";

      this.subscriptions.push(
          this.store
            .select('mobileTopBottom')
            .pipe(filter(data => data.typeof=== 'KeyPressedDm' && data.target=='child'))
            .subscribe((data: KeyPressedDm) => {
               this.router.navigate('mobile/adding-strategy');
            })
        );
      }
  }
