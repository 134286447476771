
 <div style="margin-left:{{vars['center-width']}}px;height:80%;" class="white-border">
          <div  style="margin-top:{{marginTop}}px" *ngIf="!die" >
                     <mobile-cards-display (event)="eventHandler($event)" ></mobile-cards-display>
              <!-- <div  style="position: absolute; top:{{totalHeight}}px;left:{{vars['center-width']+200}}px;" *ngIf="controlEnabled"> -->
                          <div class="flex-row"  style="margin-left:10px; margin-top:20px" *ngIf="controlEnabled" >
                                    <button mat-flat-button  (click)="clicked('cards')"
                                      [disabled]="disabled.test" [color]="'warn'" class="scale-p9">  cards  </button>

                                    <button mat-flat-button  (click)="clicked('speed')" style="margin-left:3px"
                                      [disabled]="disabled.test" [color]="'warn'" class="scale-p9">  speed  </button>

                                      <button mat-flat-button  (click)="clicked('test')" style="margin-left:3px"
                                        [disabled]="disabled.test" [color]="'warn'" class="scale-p9">  test  </button>

                                 <button mat-flat-button  style='margin-left:5px' (click)="clicked('forward')"
                                      [disabled]="disabled.forward" [color]="'primary'"  class="scale-p9"> <mat-icon > skip_next </mat-icon></button>
                          </div>
            <!--  </div> -->
          </div>
  </div>
  <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"   (afterChange)="afterChange($event)" >
    <div ngxSlickItem class="slide">
         <mobile-cards-display (event)="eventHandler($event)" ></mobile-cards-display>
    </div>
   </ngx-slick-carousel> -->
