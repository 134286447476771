<div style="width:100%;margin-left:{{vars['center-width']}}px; height:100%" *ngIf="!die" >
      <div class="flex-column" style="height:80%; width:100%">
              <div class="scene" style="margin-top:20px">
              		     <div class="flip" [@cardFlip]="state">
                                 <div class="face back">
                                   <ng-container *ngIf="state!='default'">
                      		             <ng-container *ngFor="let row of matrix.row">
                                            <div class="flex-row" >
                                              <ng-container *ngFor="let col of matrix.row">
                                                   <anms-mobile-flip-card    style="margin-right:4px;cursor:pointer" [id]="row*matrix.size+col" >  </anms-mobile-flip-card>
                                               </ng-container>
                                            </div>
                                         </ng-container>
                                 </ng-container>

                                 </div>
                                <div class="face front">
                                   <ng-container *ngIf="state=='default'">
                                         <ng-container *ngFor="let i of matrix.row">
                                              <div class="flex-row">
                                                <ng-container *ngFor="let col of matrix.row">
                                                   <anms-mobile-flip-card style="margin-right:4px;cursor:pointer"  [id]="i*matrix.size+col" >  </anms-mobile-flip-card>
                                                 </ng-container>
                                              </div>
                                           </ng-container>
                                   </ng-container>

                              </div>
                    </div>
	           </div>
                   <!-- <anms-thumbs-timer-number style="margin-top:{{numMargin}} height:20% !important"> </anms-thumbs-timer-number> -->
                   <anms-thumbs-timer-number style="margin-top:20px height:20% !important"> </anms-thumbs-timer-number>

      </div>
</div>
