export interface CardsBase {
   card:any;
   badge:boolean;
   value?: number;
};

export interface InsertCardsDataUnit {
   typeof:string;
   //cards: Array<CardsBase>;
   cards: Array<any>;
   id: number;
   msg?:string;
};
export interface PlayCardsDataUnit {
   typeof:string;
   cards: Array<any>;
   id: number;
   label:string;
   sum?:number;
};

export enum MobileDrawCardEnum {
   cards,
   badge,
   erase,
   sbadge,
   reset
};
export interface MobileDrawCardDataUnit {
  shuffle: boolean;
  type:MobileDrawCardEnum;
  typeof: string;
  id:any;
}
export interface MobileCountCardDataUnit {
  count: string;
  typeof: string;
}
export interface MobileCardsDrawnDataUnit {
  typeof: string;
}
