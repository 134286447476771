import { Component, Input} from '@angular/core';

@Component({
  selector: 'Lp',
  template: `<p style="max-width:600px" (mouseenter)="mouse('penter')" (mouseleave)="mouse('pleave')" class="{{mouseClass}} word"> {{text}} </p>`,
  styles: [
		`
		   .penter {
	        background-color: #556b2f;
	        color:white
	     }
			 .word {
				 overflow-wrap: break-word !important;
			 }
	     .pleave {
	    }`
		],
})

export class ParagraphComponent  {
  @Input() text: string;
  mouseClass='pleave';
  constructor() { }
  mouse(event) {
    this.mouseClass=event;
  }

}
