import {lrange} from '../../services/global.service';
import { CardDm } from '../../store/switchcards.model';
import {Injectable} from '@angular/core';
@Injectable({providedIn: 'root'})
export class CardOverWrite {

  list : Array<Array<number>> = [];

  push(lst:Array<number>) :void {
    this.list.push(lst)
  }

  getCards(): any {
    return this.list.shift();
  }

  fetch( card: CardDm) : any {
      if (this.list.length === 0) {
       // console.log(`fetch is reaching 0 length`);
        return card;
      }
      const list = this.list[0];
      const _card = list.shift();
      card.num = _card;
      card.rank = _card;
      // console.log(`list-length:${this.list.length}`);
      if (list.length===0 && this.list.length ) {
        console.log(`cardoverwrite shifting`)
        this.list.shift();
      }
  }

  getList(size) : any {
    return lrange(size*2);
  }

  hand(list,index, values, stride=4) : void {
    while (values.length) {
       list[index]= values.shift();
       index += stride;
    }
  }

 getcards() {
   return this.list[0];
 }
  cardsOneHand(player2cards, dealer2cards, rest) {
    return [player2cards[0], dealer2cards[0], player2cards[1], dealer2cards[1], ...rest];
  }

  exposeSingleHand() {

    this.push([
               ...this.cardsOneHand([2,9],[7,10],[4]),
               ...this.cardsOneHand([8,8],[10,10],[10,10]),
               ...this.cardsOneHand([1,7],[9,9],[8,8]),
               ...this.cardsOneHand([1,7],[8,3],[8,8]),
               ...this.cardsOneHand([5,5],[5,6],[5,8]),
               ...this.cardsOneHand([1,7],[8,3],[9,8])
             ]);

  }
  player1handMisc() {
    this.push([1,7,7,4,8,7]);
    // this.push([8,1,7,10]);

  }
  player3handsMisc() {
    // this.push([10,9,1,2,10,5,4,2]);
    // this.push([10,1,10,10,3,6,10,10,1,5]);
    this.push([8,8,8,10,10,10,10,10]);

  }
  player2handsMisc() {
    this.push([1,7,6,7,2,7,3,2,9]);
  }
  player3handsMisc1() {
    this.push([10,4,5,2,10,4,4,5,2,3,4,5]);
  }
  playerBjSingleHand() : void {
    //player black jack; this hand should finish early
    // there are 4 hands played here 4 different scienereo
    this.push([10,10,1,4]);
    // both player and dealer bjack
    this.push([10, 10, 1, 1]);
    // insurance and player blackjack
    this.push([10, 6, 1, 1]);
    // both player and dealer bjack
    this.push([10, 1, 1, 10]);
  }
  playerBjDealerFaceupSingleHand() : void {
    //player black jack; this hand should finish early
    // there are 4 hands played here 4 different scienereo
    this.push([10,10,1,1]);

  }
  playerBjTwoHands(): void {
    // first hand bj, second hand and dealer normal hand
    // there are 6 hands played here for 2 hands
    this.push([10, 6,4,1,5,6,7,8]);
    // first hand bj, second norml , dealer bjack with ace showing
    this.push([10, 6,10,1,5,1]);
    // first hand bj, second norml , dealer bjack with 10 showing
    this.push([10, 6, 1, 1, 5, 10]);
    // first hand normal, second blackjak , dealer normal
    this.push([6,10,4,5,1,6,7,8]);
    // first hand normal, second blackjak , dealer bjack with ace showing
    this.push([6, 10, 10, 5, 1, 1]);
    // first hand normal, second blackjak , dealer bjack no ace showing
    this.push([6, 10, 1, 5, 1, 10]);

  }
  playSplitSingle():void {
    //this.push([5,6,5,6]);
    this.push([5,6,5,6,5,5,5,6,7,4,7,6,8,4,6,8]);
    //this.push([5,6,5,6,5,6,7,1,5]);

  }
  playSplitSingleDealer2Aces() :void{
    //this.push([5,6,5,6]);
    this.push([5,6,5,6,5,5,5,6,7,4,7,6,8,4,6,8,1,9,1]);
    //this.push([5,6,5,6,5,6,7,1,5]);

  }
  playSplitDouble() : void {
    this.push([5,3,6,5,7,7,5]);

  }
  playSplitAces() :void{
    this.push([1,6,1,6]);
    //this.push([5,6,5,6,5,5,5,6,7,4,7,6,8,4,6,8]);
    //this.push([5,6,5,6,5,6,7,1,5]);

  }
  playSplit5():void {
    this.push([5,6,5,6]);
    //this.push([5,6,5,6,5,5,5,6,7,4,7,6,8,4,6,8]);
    //this.push([5,6,5,6,5,6,7,1,5]);

  }
  playSplit9():void {
    // 3 9's to troubleshoot splitting
    this.push([9,6,9,6,9]);
  }
  playSplit8():void {
    // 3 9's to troubleshoot splitting
    this.push([8,2,8,9,6,10,10]);
  }
  // all three cards are less than 17
  play3dealerAceUp_1() :any {
    let list = this.getList(3);
    this.hand(list,0, [2,5]);
    this.hand(list,1, [9,5]);
    this.hand(list,2, [2,6]);
    this.hand(list,3, [1,7]);
    return list;
  }

  play3misc1() :any {
    let list = this.getList(3);
    this.hand(list,0, [10,10]);
    this.hand(list,1, [9,5]);
    this.hand(list,2, [1,4]);
    this.hand(list,3, [2,2]); // dealer hand
    return list;
  }

  play3dealerBlackJack() :any {
    let list = this.getList(3);
    this.hand(list,0, [2,5]);
    this.hand(list,1, [9,5]);
    this.hand(list,2, [2,6]);
    this.hand(list,3, [1,10]);
    return list;
  }
  play3dealerAceUp_1_17() :any {
    let list = this.getList(3);
    this.hand(list,0, [10,7]);
    this.hand(list,1, [9,5]);
    this.hand(list,2, [2,6]);
    this.hand(list,3, [1,6]);
    return list;
  }
  play3dealerAceUp_12_17() :any {
    let list = this.getList(3);
    this.hand(list,0, [10,7]);
    this.hand(list,1, [9,9]);
    this.hand(list,2, [2,6]);
    this.hand(list,3, [1,6]);
    return list;
  }
  play3dealerAceUp_123_17() : any {
    let list = this.getList(3);
    this.hand(list,0, [10,7]);
    this.hand(list,1, [9,9]);
    this.hand(list,2, [1,8]);
    this.hand(list,3, [1,6]);
    return list;
  }
  play3dealerTest() :void {
    this.push(this.play3dealerAceUp_1());
    this.push(this.play3dealerBlackJack());
    this.push(this.play3dealerAceUp_1_17());
    this.push(this.play3dealerAceUp_12_17());
    this.push(this.play3dealerAceUp_123_17());
  }
  //Error: src/app/services/cardoverwrite.service.ts:158:16 - error TS2345: Argument of type 'void' is not assignable to parameter of type 'number[]'.


  play1dealerBlackJack() :void {
    let list = this.getList(1);
    this.hand(list,0, [2,5],2);
    this.hand(list,1, [1,10],2);
    this.push( list);
  }
  playOneSimpleSplit() :void {
    let list = this.getList(1);
    this.hand(list,0, [4,4],2);
    this.hand(list,1, [3,5],2);
    list.push(7,5,10);
    this.push( list);
  }
  play1dealerAceup()  :void{
    let list = this.getList(1);
    this.hand(list,0, [2,5],2);
    this.hand(list,1, [1,6],2);
    this.push( list);
  }
  play1dealerAceup2() :void{
    let list = this.getList(1);
    this.hand(list,0, [4,7],2);
    this.hand(list,1, [6,1],2);
    this.push( list);
  }
  play3dealerAceUp() :any {
    let list = this.getList(3);
    this.hand(list,0, [4,7]);
    this.hand(list,1, [3,9]);
    this.hand(list,2, [2,8]);
    this.hand(list,3, [6,1]);
    return list;
  }
  play1split2() :void{
    let list = this.getList(1);
    this.hand(list,0, [2,2],2);
    this.hand(list,1, [3,5],2);
    list.push(2,10,6);
    this.push( list);
  }
  play3split1() :void{
    let list = this.getList(3);
    this.hand(list,0, [2,2]);
    this.hand(list,1, [8,9]);
    this.hand(list,2, [5,5]);
    this.hand(list,3, [6,7]);
    //list.push(2,10,6);
    this.push( list);
  }
  play3split3() :void{
    let list = this.getList(3);
    this.hand(list,0, [2,2]);
    this.hand(list,1, [3,3]);
    this.hand(list,2, [5,5]);
    this.hand(list,3, [6,7]);
    list.push(2,2,10,6);
    this.push( list);
  }
  play3split31() :void{
    let list = this.getList(3);
    this.hand(list,0, [2,2]);
    this.hand(list,1, [3,3]);
    this.hand(list,2, [5,5]);
    this.hand(list,3, [6,7]);
    list.push(10,10,3,6);
    this.push( list);
  }
  play3random() :void{
    let list = this.getList(3);
    this.hand(list,0, [2,9]);
    this.hand(list,1, [8,9]);
    this.hand(list,2, [5,7]);
    this.hand(list,3, [6,7]);
    //list.push(2,10,6);
    this.push( list);
  }
  play1splitAce():void {
    let list = this.getList(1);
    this.hand(list,0, [1,1],2);
    this.hand(list,1, [3,5],2);
    //list.push(2,10,6);
    this.push( list);
  }
/*
 * 4 aces
 */
  play1splitAce4() :void {
    let list = this.getList(1);
    this.hand(list,0, [1,1],2);
    this.hand(list,1, [3,5],2);
    list.push(1,1);
    this.push( list);
  }
  play3splitAceMiddle() :void {
    let list = this.getList(3);
    this.hand(list,0, [2,2]);
    this.hand(list,1, [1,1]);
    this.hand(list,2, [5,5]);
    this.hand(list,3, [6,7]);
    list.push(10,10,3,6);
    this.push( list);
  }
  play1soft17() :void{
    let list = this.getList(1);
    this.hand(list,0, [3,4],2);
    this.hand(list,1, [1,6],2);
    list.push(1,1);
    this.push( list);
  }
  play3splitAceMiddle4():void {
    let list = this.getList(3);
    this.hand(list,0, [2,2]);
    this.hand(list,1, [1,1]);
    this.hand(list,2, [5,5]);
    this.hand(list,3, [6,7]);
    list.push(2,2,2,2);
    this.push( list);
  }
  play3bjack():void {
    let list = this.getList(3);
    this.hand(list,0, [1,10]);
    this.hand(list,1, [10,1]);
    this.hand(list,2, [1,10]);
    list.push(2,2,2,2);
    this.push( list);
  }
  play3bjack1():void {
    let list = this.getList(3);
    this.hand(list,0, [1,10]);
    this.hand(list,1, [10,3]);
    this.hand(list,2, [1,10]);
    this.push( list);
  }
  play3bjack1v2() :void{
    let list = this.getList(3);
    this.hand(list,0, [1,10]);
    this.hand(list,1, [10,1]);
    this.hand(list,2, [1,4]);
    this.push( list);
  }
  dealer21 () :void {
    let list = this.getList(1);
    this.hand(list,0, [10,3],2);
    this.hand(list,1, [10,1],2);
    list.push(1,1);
    this.push( list);
  }
  dealer21f3hands () :void {
    let list = this.getList(1);
    this.hand(list,0, [1,3]);
    this.hand(list,1, [4,5]);
    this.hand(list,2, [6,6]);
    this.hand(list,3, [10,1]);
    this.push( list);
  }
  dealerAceUp3hands () :void {
    let list = this.getList(1);
    this.hand(list,0, [1,3]);
    this.hand(list,1, [4,5]);
    this.hand(list,2, [6,6]);
    this.hand(list,3, [8,1]);
    this.push( list);
  }
  // dealer showing Ace for checking insurance
  dealer1f3hands () :void {
    let list = this.getList(1);
    this.hand(list,0, [2,3,1]);
    this.hand(list,1, [4,5]);
    this.hand(list,2, [6,6]);
    this.hand(list,3, [8,1]);
    this.push( list);
  }
  player21 () :void {
    let list = this.getList(1);
    this.hand(list,0, [10,1],2);
    this.hand(list,1, [10,3],2);
    list.push(1,1);
    this.push( list);
  }
  dealerShowingAce() :void {
    let list = this.getList(1);
    this.hand(list,0, [2,2],2);
    this.hand(list,1, [3,1],2);
    list.push(4);
    this.push( list);
  }
  dealer4cards() :void {
    let list = this.getList(1);
    this.hand(list,0, [3,10],2);
    this.hand(list,1, [2,3],2);
    list.push(2,10);
    this.push( list);
  }
  ruleErr_tmp () :void{
    let list = this.getList(1);
    this.hand(list,0, [2,2],2);
    this.hand(list,1, [10,3],2);
    list.push(6); // pushing value of 6 as fifth card
    this.push( list);
  }
  nines() :void { // 2 9's for split
    let list = this.getList(1);
    this.hand(list,0, [9,9],2);
    this.hand(list,1, [10,3],2);
    list.push(6); // pushing value of 6 as fifth card
    this.push( list);
  }
  misc () :void {
    let list = this.getList(6);
    this.hand(list,0, [6,7],4);
    this.hand(list,1, [1,2],4);
    this.hand(list,2, [10,5],4);
    this.hand(list,3, [4,6],4);
    this.hand(list,8, [2,7,10,2],1);  // fetch( card: CardDm) : any {
  //     if (this.list.length === 0) {
  //      // console.log(`fetch is reaching 0 length`);
  //       return card;
  //     }
  //     const list = this.list[0];
  //     const _card = list.shift();
  //     card.num = _card;
  //     card.rank = _card;
  //     console.log(`list-length:${this.list.length}`);
  //     if (list.length===0 && this.list.length ) {
  //       console.log(`cardoverwrite shifting`)
  //       this.list.shift();
  //     }
  // }
    this.push(list);
  }
  misc1 () :void {
    let list = this.getList(6);
    this.hand(list,0, [4,4],4);
    this.hand(list,1, [10,5],4);
    this.hand(list,2, [3,3],4);
    this.hand(list,3, [8,7],4);
    this.hand(list,8, [7,9],1);
    this.push(list);
  }

  play3borderStopOn2ndhand() :void {
    let list = this.getList(4);
    this.hand(list,0, [10,7]);
    this.hand(list,1, [10,8]);
    this.hand(list,2, [2,3]);
    this.hand(list,3, [8,7]);
    this.push(list);
  }
  play3borderStopOn3ndhand() :void {
    let list = this.getList(4);
    this.hand(list,0, [10,7]);
    this.hand(list,1, [10,8]);
    this.hand(list,2, [10,9]);
    this.hand(list,3, [8,7]);
    this.push(list);
  }
  split4strongHigh() :void {
    let list = this.getList(4);
    this.hand(list,0, [4,4],2);
    this.hand(list,1, [4,8],2);
    this.push(list);
  }

  misc2 ()  :void {
    let list = this.getList(1);
    this.hand(list,0, [10,10],2);
    this.hand(list,1, [4,10],2);
    list.push(4);
    this.push( list);
  }
  cardcounting_demo () :void {
      const aces=[1,6,1,4,2,4,8];
      const double = [6,6,5,4,4,7];
      const list=[]
      for (const l of [aces, aces, double, double]) {
        list.push(...l);
        // console.log(`list:${JSON.stringify(list)}`);
      }
      this.push(list);

  }
}
