
      <!-- <div style="margin-left:{{gvars['center-width']}}px; width:80%"> -->
          <div class="flex" style='margin-top: {{margin_top}}px'>
              <div class="flex-column" style="width:250px; height:500px;margin-top:10px; border:8px dashed grey;border-radius: 20px 20px !important ">
                  <div class="flex-column"  class="unfair" style="margin-top:20px;margin-left:10px;margin-right:10px" >
                    <p> {{fairness['first'][0]}} </p>
                    <h6 > {{fairness['first'][1]}} </h6>
                  </div>

                  <div class="flex-column" style="margin-left:10px;margin-top:20px">
                      <p style="margin-left:70px"> Dealer's hand </p>
                      <div class="flex" style="margin-left:20px">
                           <img  src="{{image}}" *ngFor="let image of oddsImages['first']['dealer']" class="cards-spacing">
                      </div>
                  </div>
                  <div class="flex-column" style="margin-left:10px;margin-top:20px;">
                        <p style="margin-left:70px"> Player's hand </p>
                      <div class="flex"  style="margin-left:20px">
                          <img  src="{{image}}" *ngFor="let image of oddsImages['first']['player']" class="cards-spacing">
                      </div>
                  </div>
              </div>

                <div  style="position: absolute; top:250px; left:269px;">
                  <img  src="{{bridgeImage}}" >
              </div>
              <!-- <div  style="position: absolute; top:295px; left:311px;">
                 <h5 style='color:white' class="rotate45"> www.jackcount.com </h5>
               </div> -->
               <div  style="position: absolute; top:340px; left:285px;">
                  <h4 style='color:white' > jackcount.com </h4>
                </div>
                <div class="flex-column" style="width:250px; height:500px;margin-top:10px; margin-left:230px; border:8px dashed grey;border-radius: 20px 20px !important ">

                <div class="flex-column"  class="fair" style="margin-top:20px;margin-left:10px;margin-right:10px" >
                  <p> {{fairness['second'][0]}} </p>
                  <h6 > {{fairness['second'][1]}} </h6>

                </div>
                  <div class="flex-column" style="margin-left:10px;margin-top:20px">
                      <p style="margin-left:70px"> Dealer's hand </p>
                      <div class="flex" style="margin-left:20px">
                           <img  src="{{image}}" *ngFor="let image of oddsImages['second']['dealer']" class="cards-spacing">
                      </div>
                  </div>
                  <div class="flex-column" style="margin-left:10px;margin-top:20px;">
                        <p style="margin-left:70px"> Player's hand </p>
                      <div class="flex"  style="margin-left:20px">
                          <img  src="{{image}}" *ngFor="let image of oddsImages['second']['player']" class="cards-spacing">
                      </div>
                  </div>
              </div>

           </div>
        <!-- </div> -->
