/*
 * note everthing related to Switch needs to be removed.
 */
import { MainBottomDm, KeyPressedDm, BottomQuesButtons,
  BottomQuesButtonsPressed, BottomMenuReset,  BottomAppleButtons
  
} from './bottom.model';
import { Action } from '@ngrx/store';


const insert=['InsertTopBottomAction','InsertKeyPressedAction','BottomQuesButtonsAction','bottomAppleInsert',
            'BottomQuesButtonsPressedAction','bottomMenuResetInsert',
             'MainBottomSwitchAction' // to be removed
];


export class InsertTopBottomAction implements Action {
    readonly type = 'InsertTopBottomAction' ;
    constructor(public payload: MainBottomDm) { }
};

export class InsertKeyPressedAction implements Action {
    readonly type = 'InsertKeyPressedAction';
    constructor(public payload: KeyPressedDm) { }
};

export class BottomQuesButtonsAction implements Action {
  readonly type = 'BottomQuesButtonsAction';

    constructor(public payload: BottomQuesButtons) { }
};


export class BottomQuesButtonsPressedAction implements Action {
  readonly type = 'BottomQuesButtonsPressedAction';

    constructor(public payload: BottomQuesButtonsPressed) { }
};
export class BottomMenuResetAction implements Action {
  readonly type = 'bottomMenuResetInsert';

    constructor(public payload: BottomMenuReset) { }
};
export class BottomAppleAction implements Action {
  readonly type = 'bottomAppleInsert';

    constructor(public payload: BottomAppleButtons) { }
};

type Actions = InsertTopBottomAction | BottomQuesButtonsAction|BottomMenuResetAction|InsertKeyPressedAction|
              BottomQuesButtonsPressedAction| BottomAppleAction
              ;

export function topBottomReducer (State = {}, action: Actions) {
    // return insert.includes(action.type)? action.payload: {};
    if (insert.includes(action.type)) {
      // console.log(`topBottomReducer received: ${JSON.stringify(action.payload)}`);
      return action.payload;
    }

    return {}
};

export function mainBottomSwitchActionNone (store: any) {
  store.dispatch( new BottomAppleAction ({action:{model: 'others'}, typeof:'BottomAppleButtons'}));
};

export function BottomResetMenuPressed (store: any, blank=false) {
  store.dispatch( new BottomAppleAction ({action:{reset: true}, typeof:'BottomAppleButtons'}));
};
export function NumberResetPressed (store: any, blank=false) {
  store.dispatch( new BottomMenuResetAction ({blank: blank, typeof:'BottomMenuReset'}));
};

export function MobileSendKeyPressed (store: any, data: string) {
  store.dispatch( new InsertKeyPressedAction ({key: data, target:'parent', typeof:'KeyPressedDm'}));
};
export function MobileSendSubNavigationInfo (store: any, data: string) {
  store.dispatch( new InsertKeyPressedAction ({key: data, target:'child', typeof:'KeyPressedDm'}));
};
export function BottomAppleModify (store:any, action: any) {
  store.dispatch( new BottomAppleAction ({action: action, typeof:'BottomAppleButtons'}));
};
export function MobileSendKeyRoute (store:any, data: any) {
  store.dispatch( new InsertTopBottomAction ({data: data, typeof:'MainBottomDm'}));
};
export function MobileSendFlash (store:any, key: any) {
  store.dispatch( new InsertTopBottomAction ({data: [{flash:true, key:key}], typeof:'MainBottomDm'}));
};
export function MobileSendUnFlash (store:any, key: string) {
  store.dispatch( new InsertTopBottomAction ({data: [{flash:false, key:key}], typeof:'MainBottomDm'}));
};

export function BottomQuesButtonsSend (store:any, buttons: Array<string>, timeout, cvalue='') {
  store.dispatch( new BottomQuesButtonsAction ({buttons: buttons,timeout:timeout,  cvalue:cvalue, typeof:'BottomQuesButtons'}));
};

export function BottomQuesButtonsLessonSend (store:any, buttons: Array<string>, timeout, cvalue='') {
  store.dispatch( new BottomQuesButtonsAction ({buttons: buttons,timeout:timeout, lesson:true,  cvalue:cvalue, typeof:'BottomQuesButtons'}));
};


export function BottomQuesButtonsPressedSend (store:any, button: string) {
  store.dispatch( new BottomQuesButtonsPressedAction ({button: button, typeof:'BottomQuesButtonsPressed'}));
};
