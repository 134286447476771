
import { Component, OnInit, OnDestroy} from '@angular/core';
import {globalVars, MobileRouterService} from '../../services/global.service';
import {MobileSendKeyRoute} from '../store/bottom.action.reducer';
import { map, filter } from 'rxjs/operators';
import {KeyPressedDm} from '../store/bottom.model';
import { Store } from '@ngrx/store';

/*
 note the timer is maintained in globalvars to be global, the following template
 displays the count
 */

@Component({
  selector: 'anms-mobile-time-left',
  templateUrl: './component.html',
  styleUrls: ['../styles.scss'],
})

export class MobileTimeLeftComponent implements OnInit, OnDestroy {

  days: string;
  hours: string;
  minutes: string;
  seconds: string;
  vars:{};
  subscriptions=[];
  //buttons[button].status
  constructor(private store:Store<any>, private routeService:MobileRouterService ) {  }
  ngOnInit():void {
     this.vars=globalVars;
     MobileSendKeyRoute(this.store,[
       {key:"up", disabled: true},
       {key:"down", disabled: true},
       {key:"backward", disabled: false},
       {key:"forward", disabled: false},
       {key:"home", disabled: false, route:'mobile/home'},


     ]);
     this.subscriptions.push(
     this.store
       .select('mobileTopBottom')
       .pipe(filter(event=> event.typeof=== 'KeyPressedDm'))
       .subscribe((data: KeyPressedDm) => {
         // need to see approriate data with keys pressed
         switch(data.key) {
           case 'backward': {this.routeService.navigate_back(); break;}
           case 'forward': {this.routeService.navigate('mobile/train'); break;}
           case 'home': {
               MobileSendKeyRoute(this.store,[
                 {key:"backward", disabled: true},
                 {key:"forward", disabled: true},
                 {key:"up", disabled: true},
                 {key:"down", disabled: true}
               ]);
               this.routeService.navigate('mobile/home');
                break;
              }
         }

       }));
   }

   ngOnDestroy():void {
     for (const subs of this.subscriptions) {
       subs.unsubscribe();
     }
    MobileSendKeyRoute(this.store,[{key:"reset"}]);
   }
}
