


<div class="flex"  style="margin-top:50px;width:600px;height:50px;">
     <div style="margin-left:100px;font-size:20px;"> PLUS-ZONE </div>
     <div style="margin-left:150px;font-size:20px;"> MINUS-ZONE </div>
  </div>




  <table class="top">
    <colgroup>
        <col span="1" class="dims ">
        <col span="2" class="dims green">
        <col span="2" class="dims red">
        <col span="1" class="dims yellow">
        <col span="2" class="dims red">
        <col span="2" class="dims green">


       </colgroup>
    <tr>
      <th class="border">cards</th>
      <th class="border">L</th>
      <th class="border">L</th>
      <th class="border">H</th>
      <th class="border">H</th>
      <th class="border"> </th>
      <th class="border">H</th>
      <th class="border">H</th>
      <th class="border">L</th>
      <th class="border">L</th>
    <tr>
      <th class="border">count</th>
      <td class="border"> 1 </td>
      <td class="border"> 2 </td>
      <td class="border"> 1 </td>
      <td class="border"> 0 </td>
      <td class="border"><--></td>
      <td class="border"> -1 </td>
      <td class="border"> -2 </td>
      <td class="border"> -1 </td>
      <td class="border"> 0 </td>

    </tr>

  </table>
    <div style="margin-top:50px;margin-left:250px;font-size:20px;"> Recipe-101 </div>
  <table class="top1">
    <colgroup>
        <col span="1" class="dims ">
        <col span="2" class="dims green">
        <col span="2" class="dims red">
        <col span="1" class="dims yellow">
        <col span="2" class="dims red">
        <col span="2" class="dims green">


       </colgroup>
    <tr>
      <th class="border">cards</th>
      <th class="border">L</th>
      <th class="border">L</th>
      <th class="border">H</th>
      <th class="border">H</th>
      <th class="border"> </th>
      <th class="border">H</th>
      <th class="border">H</th>
      <th class="border">L</th>
      <th class="border">L</th>
    <tr>
      <th class="border">count</th>
      <td class="border"> 1 </td>
      <td class="border"> 2 </td>
      <td class="border"> 1o2 </td>
      <td class="border"> 2o2 </td>
      <td class="border"><--></td>
      <td class="border"> 1 </td>
      <td class="border"> 2 </td>
      <td class="border"> 1o2 </td>
      <td class="border"> 2o2 </td>

    </tr>

  </table>
