<div *ngIf="model=='apple'"  >


      <!-- <mat-toolbar [color]="'accent'"  style="width:100%" *ngIf="!vertical else mobile" > -->
        <mat-toolbar   style="width:100%" *ngIf="!vertical else mobile" class="mobile-side-bg">

            <div class="flex-row-center">
                     <button mat-button  (click)="clicked('backward')" [@flash]="buttons.backward.status"
                       [disabled]="buttons.backward.disabled"  class="toolbar-scale"> <mat-icon> skip_previous</mat-icon></button>

                    <button  mat-button style='margin-left:5px' (click)="clicked('forward')"  [@flash]="buttons.forward.status"
                         [disabled]="buttons.forward.disabled"   class="toolbar-scale"> <mat-icon > skip_next </mat-icon></button>
    	            	<button  mat-button  style='margin-left:5px'  (click)="clicked('lastIcon')"  [@flash]="buttons.lastIcon.status"
    	            		    [disabled]="buttons.lastIcon.disabled"     class="toolbar-scale"> <mat-icon >{{lastIcon}}</mat-icon></button>
                    <button mat-button style='margin-left:5px' [matMenuTriggerFor]="menu"
                          [disabled]="!maps.list.length"  class="toolbar-scale"> <mat-icon>  more_vert </mat-icon>
                    </button>
                     <mat-menu #menu="matMenu">
                           <ng-container *ngIf="maps.icons.length; else noicon">
                                 <div *ngFor="let button of maps.list; let i=index" >
                                       <button mat-button *ngIf="button =='Video' else nonvideo" (click)="location.href=videoUrl()">
                                             <mat-icon> {{maps.icons[i]}} </mat-icon> {{button}} </button>
                                       <ng-template #nonvideo>
                                          <button mat-button  (click)="clicked(button)" > <mat-icon> {{maps.icons[i]}} </mat-icon> {{button}} </button>
                                       </ng-template>

                                 </div>
                           </ng-container>

                           <ng-template #noicon>
                             <div *ngFor="let button of maps.list">
                                   <button mat-menu-item  (click)="clicked(button)" >  {{button}} </button>
                             </div>
                           </ng-template>

                     </mat-menu>
           </div>
     </mat-toolbar>
     <ng-template #mobile>
               <div class="top-bottom-flex-column mobile-side-bg" style="height:350px">
                     <button mat-icon-button   [matMenuTriggerFor]="menu"
                           [disabled]="!maps.list.length" > <mat-icon> more_vert </mat-icon>

                     </button>
                      <mat-menu #menu="matMenu">
                            <ng-container *ngIf="maps.icons.length; else noicon">
                              <div *ngFor="let button of maps.list; let i=index" >
                                    <button mat-menu-item  (click)="clicked(button)" > <mat-icon> {{maps.icons[i]}} </mat-icon> {{button}} </button>
                              </div>
                            </ng-container>

                            <ng-template #noicon>
                              <div *ngFor="let button of maps.list">
                                    <button mat-menu-item  (click)="clicked(button)" >  {{button}} </button>
                              </div>
                            </ng-template>

                      </mat-menu>
                      <button mat-icon-button    (click)="clicked('lastIcon')"  [@flash]="buttons.lastIcon.status"
       	            		    [disabled]="buttons.lastIcon.disabled"> <mat-icon >{{lastIcon}}</mat-icon></button>
                      <button mat-icon-button   (click)="clicked('forward')"  [@flash]="buttons.forward.status"
                           [disabled]="buttons.forward.disabled"> <mat-icon > skip_next </mat-icon></button>
                      <button mat-icon-button  (click)="clicked('backward')" [@flash]="buttons.backward.status"
                        [disabled]="buttons.backward.disabled"> <mat-icon> skip_previous</mat-icon></button>
           </div>
     </ng-template>
</div>
<!-- [disabled]="!maps.list.length" [color]='buttons.menu.color'  class="toolbar-scale"> <mat-icon> <span class="gold-font"> more_vert </span></mat-icon> -->
<!-- [disabled]="!maps.list.length"   class="toolbar-scale"> <mat-icon> <span class="gold-font"> more_vert </span></mat-icon> -->
