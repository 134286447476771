import { Injectable, Inject } from '@angular/core';
const APP_PREFIX = 'ANMS-';

@Injectable({providedIn: 'root'})
export class LocalStorageService {
  keys = new Set();
  constructor() {}
  setItem(key: string, value: any) {
//    console.log(`storage setItem is called with key: ${key} value: ${JSON.stringify(value)}`);
    this.keys.add(key);
/*
console.log('local-storage.service.ts setting item');
console.log(`key:${key}, value:${ JSON.stringify(value)}`);
*/

    localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));

  }


  getItem(key: string) {
      if (this.exists(key) === false) {
              //console.log(`getItem: <${key}>, doesn't exist`);
              return null;
      }
      return JSON.parse(localStorage.getItem(`${APP_PREFIX}${key}`));
  }

  exists(key:any):any { return this.keys.has(key);}

  clear():void {
     localStorage.clear();
  }
  get(key:any):any {
       const auth =  this.getItem('AUTH'); 
       if (auth) {
        return auth[key];
       }
       return false;
       
  }
  getToken() :any{
       //return this.getItem('AUTH').token;
       return this.get('token');
  }
  getAuth():any {
       return this.get('isAuthenticated');
  }
  static loadInitialState():void {
    return Object.keys(localStorage).reduce((state: any, storageKey) => {
      if (storageKey.includes(APP_PREFIX)) {
        state = state || {};
        const stateKey = storageKey
          .replace(APP_PREFIX, '')
          .toLowerCase()
          .split('.');
        let currentStateRef = state;
        stateKey.forEach((key, index) => {
          if (index === stateKey.length - 1) {
            const value = localStorage.getItem(storageKey);
            if (value != 'undefined') {
               currentStateRef[key] = JSON.parse(value);
            }
            return;
          }
          currentStateRef[key] = currentStateRef[key] || {};
          currentStateRef = currentStateRef[key];
        });
      }
      return state;
    }, undefined);
  }
}
