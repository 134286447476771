<!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" style='width:80%' > -->

 <div  style="margin-top:10px;margin-left:{{vars['center-width']}}px;" >
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"  >
      		 <div ngxSlickItem class='slide' *ngFor="let duration of productKeys;">
                 <mat-card class="aws-mat-card">
                         <mat-card-title > {{duration}} subscription</mat-card-title>
                         <mat-card-subtitle > Device:Desktop, Mobile, Tablet, Laptop </mat-card-subtitle>
                         <mat-card-subtitle > Apple Note: Minimum Iphone 8 plus, later model</mat-card-subtitle>
                         <mat-card-subtitle > Samsung Note: Minimum S3, later model</mat-card-subtitle>
                         
                       <mat-card-content>
                         <div class="flex-column">
                                 <p *ngFor="let key of subscriptsKeys">   {{key}}:{{products[duration][key]}} </p>
                         </div>
                       </mat-card-content>
                 </mat-card>
          </div>
    </ngx-slick-carousel>
    <div class="flex-row"  style="margin-left:10%">
              <button mat-flat-button  (click)="clicked('backward')"
                [disabled]="disabled.backward" [color]="'warn'" class="scale-p9"> <mat-icon> skip_previous</mat-icon></button>

             <button mat-flat-button  style='margin-left:5px' (click)="clicked('forward')"
                  [disabled]="disabled.forward" [color]="'warn'"  class="scale-p9"> <mat-icon > skip_next </mat-icon></button>
            <button mat-flat-button  style='margin-left:5px' (click)="clicked('done')"
                       [disabled]="disabled.done" [color]="'warn'"  class="scale-p9"> <mat-icon > done </mat-icon></button>
    </div>
   </div>
