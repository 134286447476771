
<div  style="margin-top:10px;margin-left:{{vars['center-width']}}px;" >
        <div class="flex-column-space-between wrapper margin-side-20">
          <span class="mat-cell"> You have: </span>
          <mat-card  class=" mat-cell">
                <mat-icon> calendar_view_day</mat-icon>  {{vars['days']}} days
          </mat-card>
          <mat-card class=" mat-cell">
                <mat-icon> hourglass_full</mat-icon>  {{vars['hours']}} hours
              </mat-card>
          <mat-card class=" mat-cell">
                <mat-icon> 3d_rotation </mat-icon>  {{vars['minutes']}} minutes
            </mat-card>
          <mat-card class=" mat-cell">
                <mat-icon> watch</mat-icon>  {{vars['seconds']}} seconds

          </mat-card>
        </div>
</div>
