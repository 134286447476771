<!-- class="very_light_blue" -->
<!-- <button mat-button [routerLink]="[item.link]" routerLinkActive="active" [matTooltip]="item.tip" *ngIf="item.auth?gvars['isAuthenticated']:true"> -->
<!-- url="https://rodrigo.kamada.com.br/"
description="Angular Share Buttons"
twitterAccount="rodrigokamada" -->
  <ng-container [ngSwitch]="device">
    <mat-sidenav-container *ngSwitchCase="'desktop'"  style="width:{{gvars['width']}}px; height:{{gvars['height']}}px">
          <mat-sidenav #sidenavL  style="width:{{dimension.sidebar.width}}px; height:100%" mode="side" opened  [fixedInViewport]="false">
                  <ng-container *ngFor="let casino of left_casinos;let i=index">
                             <anms-vertical-img [id]="i" (mouseenter)="vimageMouse(i, 'enter')" (mouseleave)="vimageMouse(i,'leave')"> </anms-vertical-img>
                  </ng-container>
           </mat-sidenav>
          <mat-sidenav #sidenavR  style="width:{{dimension.sidebar.width}}px; height:100%" mode="side" opened position="end"  [fixedInViewport]="false" >
            <ng-container *ngFor="let casino of right_casinos;let i=index">
                       <anms-vertical-img [id]="i+left_casinos.length" (mouseenter)="vimageMouse(i+left_casinos.length, 'enter')" (mouseleave)="vimageMouse(i+left_casinos.length,'leave')" > </anms-vertical-img>
            </ng-container>
          </mat-sidenav>
	  <mat-sidenav-content class="flex-column-space-between" style="width:{{theWidth}}px; height:100%" >
                           <mat-toolbar  class="flex-row-reverse font mobile-side-bg" style="width:100%;height:{{dimension.app.bar.height}}%" >
                                  <ng-container  *ngIf="gvars['isAuthenticated']; else signin">
                                    <button mat-icon-button [matMenuTriggerFor]="toolbarUserMenu"> <mat-icon>person</mat-icon> </button>
                                    <mat-menu #toolbarUserMenu="matMenu">
                                                  <button mat-menu-item (click)="onLogoutClick()"> <mat-icon>power_settings_new</mat-icon> <span>Logout</span> </button>
                                    </mat-menu>
                                    <div *ngIf="gvars['credit']==0">
                                      <button  mat-button  (click)="settingClicked('Subscriptions')"
                                      routerLinkActive="active" [matTooltip]="item.tip" class="margin-top-right-5px">Subscriptions</button>
                                    </div>
                                    <ng-container *ngIf="gvars['credit']!=0">
                                              <button *ngFor="let item of navMenu" mat-button  (click)="settingClicked(item.label)"
                                              routerLinkActive="active" [matTooltip]="item.tip" class="margin-top-right-5px">{{item.label}}</button>

                                              <button mat-icon-button [matMenuTriggerFor]="socialMediaMenu" style="margin-right:15px"> <mat-icon>share</mat-icon></button>
                                              <mat-menu #socialMediaMenu="matMenu">
                                                    <share-buttons theme="modern-dark"
                                                               [include]="[ 'facebook', 'telegram',  'twitter', 'whatsapp']"
                                                               [showIcon]="true"
                                                               [showText]="true"

                                                               class="pt-5">
                                                   </share-buttons>
                                             </mat-menu>
                                      </ng-container>
                                  </ng-container>
                                   <ng-template #signin>
                                        <button mat-button (click)="settingClicked('signin')"> Sign in </button>
                                   </ng-template>
                                   <button mat-button  (click)="location.href=videoUrl()">
                                         <mat-icon> video_library </mat-icon> video </button>
                          </mat-toolbar>
                          <!-- <anms-mobile-toptop  style="height:{{dimension.app.toptop.height}}%"> </anms-mobile-toptop> -->
                          <anms-mobile-toptop > </anms-mobile-toptop>
                          <div  style="height:{{dimension.app.route.height}}%; width:{{dimension.app.route.width-20}}%;">
                                        <router-outlet #o="outlet"></router-outlet>
                          </div>
	                        <anms-top-bottom  style="height:{{dimension.app.bottom.height}}%; width:100%"> </anms-top-bottom>

        </mat-sidenav-content>
    </mat-sidenav-container>


    <mat-sidenav-container *ngSwitchDefault  style="width:{{gvars['width']}}px; height:{{gvars['height']}}px" >
      <mat-sidenav  style="width:40px; height:100%;" mode="side" opened position="end"  [fixedInViewport]="false" >
           <div class="flex-column-reverse" style="height:100%">
             <anms-top-bottom  [vertical]="true" style="height:60%;margin-top:10%"> </anms-top-bottom>
             <!-- <div class="flex-column-space-between mobile-side-bg" style="height:35%; margin-bottom:5%"> -->
               <div class="flex-column  mobile-side-bg" style="height:30%; margin-bottom:5%">

                    <ng-container  *ngIf="gvars['isAuthenticated']; else signin">
                      <button mat-icon-button [matMenuTriggerFor]="toolbarUserMenu"> <mat-icon>person</mat-icon> </button>
                      <mat-menu #toolbarUserMenu="matMenu">
                                    <button mat-menu-item (click)="onLogoutClick()"> <mat-icon>power_settings_new</mat-icon> <span>Logout</span> </button>
                      </mat-menu>
                      <div *ngIf="!noCredit" style="margin-top:30px">
                                <button mat-icon-button *ngIf="gvars['online-mobile']" [matTooltip]="online-assistance" (click)="settingClicked('Online-assist')"> <mat-icon> assistant </mat-icon> </button>
                                <button mat-icon-button [matMenuTriggerFor]="socialMediaMenu" style="margin-right:15px"> <mat-icon>share</mat-icon></button>
                                <mat-menu #socialMediaMenu="matMenu">
                                      <share-buttons theme="modern-dark"
                                                 [include]="[ 'facebook', 'telegram',  'twitter', 'whatsapp']"
                                                 [showIcon]="true"
                                                 [showText]="true"
                                                 class="pt-5">
                                     </share-buttons>
                               </mat-menu>
                        </div>
                    </ng-container>
                     <ng-template #signin>
                          <button mat-icon-button [matTooltip]="sign-up" (click)="settingClicked('signin')"> <mat-icon> accessibility </mat-icon> </button>
                     </ng-template>
                     <button style="margin-top:20px; margin-right:6px" mat-button  (click)="location.href=videoUrl()">
                           <mat-icon> video_library </mat-icon> video </button>
               </div>
            </div>
      </mat-sidenav>
      <mat-sidenav-content class="flex-column" style="width:{{gvars['width']-50}}px; height:100%">
                <anms-mobile-toptop  *ngIf="toptopEnabled" style="height:{{dimension.app.toptop.height}}%"> </anms-mobile-toptop>
                <div style="height:{{dimension.app.route.height}}%;">
                    <router-outlet #o="outlet"></router-outlet>
                </div>
      </mat-sidenav-content>

  </mat-sidenav-container>
</ng-container>
