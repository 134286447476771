<div style="margin-left:{{gvars['center-width']}}px; height:100%"  *ngIf="!die" >
                  <anms-thumbs-timer  #thumbs_timer style="margin-left:100px">   </anms-thumbs-timer>
                  <mobile-cards-display style="margin-top:{{marginTop}}" (event)="eventHandler($event)" ></mobile-cards-display>
                  <ng-container *ngIf="showNumbers else showArrow">
                     <!-- <anms-thumbs-timer-number> </anms-thumbs-timer-number> -->
                     <anms-bottom-numbers (thumbsEvent)="tevent($event)"> </anms-bottom-numbers>
                  </ng-container>
                  <ng-template #showArrow>
                            <div class="flex-row"  style="margin-left:10%; margin-top:20px">
                                   <button mat-flat-button  style='margin-left:5px' (click)="clicked()"
                                        [disabled]="false" [color]="'primary'"  class="scale-p9"> <mat-icon > skip_next </mat-icon></button>
                            </div>
                 </ng-template>
</div>
<!-- <button mat-button (click)="incr()" > clickme </button> -->
<!-- <div style="margin-left:{{gvars['center-width']}}px; height:100%"  *ngIf="!die" >

      <ngx-slick-carousel class="carousel"  #slickModal="slick-carousel" [config]="slideConfig" >

        <div ngxSlickItem class="slide"  >
             <div class="flex-column" style="margin-top:{{marginTop}}">

                    <mobile-cards-display (event)="eventHandler($event)" ></mobile-cards-display>
             </div>


               <anms-bottom-numbers (timerEvent)="tevent()" *ngIf="showNumbers else showArrow" > </anms-bottom-numbers>


               <ng-template #showArrow>
                       <div class="flex-row"  style="margin-left:10%; margin-top:20px">
                              <button mat-flat-button  style='margin-left:5px' (click)="clicked()"
                                   [disabled]="false" [color]="'warn'"  class="scale-p9"> <mat-icon > skip_next </mat-icon></button>
                       </div>
                </ng-template>
      </div>

 </ngx-slick-carousel>

</div> -->
