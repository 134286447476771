import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {UserService  } from '../services/user.service';
import { print } from '../services/common.service';
import {ValidateEmail, globalVars} from '../services/global.service';
import { Observable } from "rxjs";
import { catchError, filter } from "rxjs/operators";
import { Store } from '@ngrx/store';
import { GenSettingModel, GenSettingEnum } from '../store/gen-setting.model';

interface ReturnDataIf {
       type: string;
       status: string;
       message?: string;
       json?: Array<any>;
  }



@Component({
  selector: 'app-contact',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})

export class ContactustComponent implements OnInit, OnDestroy  {

  email: string='';
  subject: string='';
  content: string='';

  response = false;
  message: any;
  basic = true;
  obj = false;
  keys =[];
  mobile=false;

  reset() :void{
   this.basic = true;
   this.email='';
   this.subject='';
   this.content='';
  }

  constructor(
   private store: Store<any>,
   private http: HttpClient,
   private userService:UserService
  ) { }

  ngOnInit() :void {
	  /*
    this.store
      .select('genSetting')
      .pipe(filter(data => data.typeof=== 'GenSettingModel' ))
      .subscribe((data: GenSettingModel) => {
         if (GenSettingEnum.hide_content) {
            this.basic= false;
         }
      });
     */
      this.mobile=globalVars['mobileDevice'];
   }

  ngOnDestroy() :void{ }

   handleError ( err:any):any {
    print( `handle error called with :${err}` );
    return Observable.throw ( err );
  }
/*
  emailCheck() {
     return /@/.exec(this.email) && /\./.exec(this.email) ;
  }
*/

  subjectCheck ():any { return this.subject.length ? true : false; }
  contentCheck ():any { return this.content.length ? true : false; }

  contact(): void{

     if (ValidateEmail(this.email)) {
       if ( this.subjectCheck()) {
          if ( this.contentCheck()) {
              this.userService.sendPlainEmail(this.email, this.subject, this.content) ;
              this.reset();
           } else {
                 alert('Email content missing');
           }
       } else {
          alert('Email subject missing');
       }
     } else {
       alert('Enter correct email address');
     }
  }

}
