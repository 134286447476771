export class Insurance {
  table = []; 
  insDictList = [];
  bjack = [];
  constructor() {}
  config( table:any []) {
       this.table = table.slice();
  }
  taken(id: number, bjack: boolean) {
    const index = this.table.indexOf(id);
    this.insDictList.push({pos: index, id: id});
    if (bjack) {
     this.bjack.push(index);
    }
  }
  merge(table) {
    for (const elm of this.insDictList) {
        const pos = elm['pos'];
        const id = elm['id'];
        table.splice(pos,0,id);
    }
    for (const id of this.bjack) {
      const pos = this.table.indexOf(id);
      table.splice(pos,1); 
    }
    this.insDictList = [];
    this.bjack = [];
    return table;
  }
};

