import { Action } from '@ngrx/store';
export interface ServerErrorData {
  error:any;
  type: string;
}
 const INSERT = 'server-insert';


export class ServerErrorAction implements Action {
    readonly type = INSERT;
    constructor(public payload: ServerErrorData) { }
}

export function ServerErrorReducer(State = {}, action: ServerErrorAction) {
    
      return action.type==INSERT? action.payload: {};
};

export function sendServerErrorAction (store: any, error: any) {
  store.dispatch( new ServerErrorAction ({error:error,   type:'ServerErrorData'}));
}
