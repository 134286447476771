import { Actions, ActionTypes } from '../core.interfaces';
import { User } from '../../models/user';

export interface State {

  isAuthenticated: boolean;
  error?: string;
  loaded: boolean;
  loading: boolean;
  user?: User;
  ignore: boolean;

}

export const initialState: State = {

  isAuthenticated: false,
  loaded: false,
  loading: false,
  ignore: false
};

export const AUTH_KEY = 'AUTH';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_MISC = 'AUTH_MISC';

export const login = (data:any) => {
  const rval = { 
   type: AUTH_LOGIN,
   payload: { email: data['email'], 
              password: data['password'], 
              token: data['token'],
              activity: data['activity']
             } 
            };
            return rval;

   };
export const logout = () => ({ type: AUTH_LOGOUT });

export const selectorAuth = (state:any) => state.auth;

export function authReducer(state = initialState, action: Actions) : State {
  switch (action.type) {
    case AUTH_LOGIN:
      return Object.assign({}, state, {
        isAuthenticated: true,
        ignore: false
      });

    case AUTH_LOGOUT:
      return Object.assign({}, state, {
        isAuthenticated: false,
        ignore: false
      });

    case ActionTypes.AUTHENTICATE:
      return Object.assign( {}, state, {
        loading: true,
        ignore: false
      });

    case ActionTypes.AUTHENTICATED_ERROR:
      return Object.assign( {}, state, {
        isAuthenticated: false,
        error: action.payload.error.message,
        loaded: true,
        ignore: false
      });

    case ActionTypes.AUTHENTICATED_SUCCESS:
      return Object.assign( {}, state, {
        isAuthenticated: true,
        user: action.payload.user,
        loaded: true,
        ignore: false
      });

    default:
      //return state;
      if (/update-reducer/.exec(action.type)) {
               return Object.assign( {}, state, { ignore: true });
      } else {
        return state;
      }
  }
}
