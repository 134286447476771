import {CardDm} from '../store/switchcards.model';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import { GenSettingAction } from '../store/gen-setting.action.reducer';
import { Observable } from "rxjs";
import { catchError} from "rxjs/operators";

import { HttpClient } from '@angular/common/http';
import { print, timeSplit } from './common.service';
import {Timer1sec } from './timer.observable.service';
import { HttpHeaders } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';
import {GenericGresetAction, GenericDieAction} from '../mobile/store/generic.action.reducer';





export const THUMB_ANIMATION_TIME=3000;
const SECOND=1000;
export const MINUTE=60*SECOND;
const HOUR=60*MINUTE;
//export const globalVars = {};
export enum DeviceEnum  {
   verysmall=0,
   small=1,
   medium=2,
   large=3
};

@Injectable({providedIn: 'root'})
export class JcountSeo {
  constructor(private readonly meta: Meta, private readonly title: Title) {}
  defaultSetting() {
    this.title.setTitle("blackjack training camp");
    this.meta.addTags([
      {name:'description', content:'easy card counting'},
      {name:'robots', content:'index, follow'}
    ]);
  }
  updateTag(obj) {
    this.meta.updateTag(obj);
  }
  addTags(list) {
    this.meta.addTags(list);
  }
};

export function ValidateEmail(mail)
{
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return true;
  }
  alert(mail.length? `Email entered(${mail}) not valid email address!`: 'You need to fillin the email field');
  return false;
}




 const defaultVars:any = {
    state: 'top',
    reset:true,
     baseCount:'',
     testing: false,
     learning: false,

     'hands-net':0,
     'method':'advance', // dynamic vs Mit method
     // decks: 'single deck',
     certificate: { enabled: false, speed: 800, cards: {1: 8, 2: 12, 6:16}},
     d1card_hidden: true,
     join:' ',
     updateCredit:false,
     soft17: 'stay',
     'center-width':0,
     count:'show',
     voice:false,
     prevRoute:'',
     monitors: 'betting and decisions',
     methodLabel:{jack:'J', mit:'C', ten:'T', advance:'A'},
     timerOn:false,
     jackCount:(low,high)=>`${high},${low}`,
     risk:'moderate',
     'online-bet':[],
     image:{width:0, height:0, cols:0, rows:0,dims:''},
     adjustSpeed:false,
     cach:null,
     max: {
       mobile: {1:14, 2:21, 6:28},
       others: {
              'low and high cards the same, difficulty(1)' : {1:32, 2:32, 6:32},
              'high cards more frequent, difficulty(2)'    : {1:8, 2:24, 6:32},
              'low cards more frequent, difficulty(2)'    : {1:8, 2:24, 6:32},
              'only low and high cards, difficulty(3)'    : {1:8, 2:24, 6:32},
      },
     },
     decksMapping: {
       1: 'single deck',
       2: 'double decks',
       6: 'six decks'
     },
     credit: 0,
     vpath:'',
     cardCount:'',
     play: false,
     d1card:false,
     strength:'',
     free:true,
     setting:{},
     token:'',
     connect:false,
     isAuthenticated:false,
     cut:'',
     reason:'',
     pause: 5,
     minimumBet: 25,
     continuous: false,
     splits: 2,
     reduceCountEnable: false,
     strategy:'advance',
     // mobileDevice: screen.availWidth<=600,
     // midDevice: screen.availWidth>600 && screen.availWidth <=768,
     // width:screen.availWidth,
     // // height: window.screen.height-50,
     // height: screen.availHeight,
     ndecks:2,nplayers:1,speed:1200,
             mode:'training',what:'count',type:'count-up','cards-out':3, 'auto-shuffle': false};

export const isJack=()=> ['jack', 'advance'].includes(globalVars['method']);
export const globalVars:any = {
  ...defaultVars
};
// export const setUptoMax = ()=>{
//   const ndecks = `${globalVars['ndecks']}`;
//   const max =   globalVars['max'][ndecks] ;
//   const cardsOut = globalVars['cards-out'];
//   globalVars['cards-out'] = cardsOut > max ? max : cardsOut;
// };

export const ResetGlobalvars = ()=> {
  const credit=globalVars['credit'];
  const  cards=globalVars['cards-out'];
  const speed=globalVars['speed'];
  const method= globalVars['method'];
  const ndecks=globalVars['ndecks'];
   //globalVars = {...defaultVars};
   for (const key in defaultVars) {
     globalVars[key]= defaultVars[key];
   }
   globalVars['credit']=credit;
   globalVars['cards-out']=cards;
   globalVars['speed']=speed;
   globalVars['method']=method;
   globalVars['ndecks']=ndecks;


};
/////////////////
// it fills up buttons with bounch of counts
///////////////
const Random=(min, max) => {
     return   Math.floor(Math.random() * (max-min+1)) + min;
  }

export const verificationCode = () => {
   let i=0;
   let rvalue='';
   while(i++<5) {
     const rand =  ((i==0) || (i==2))? String.fromCharCode(Random(65,90)): Random(0,9);
     rvalue=`${rvalue}${rand}`;
   }
   return rvalue;
}

const  GetRandomFromList= (list)=> {
     let index = Random(0,list.length-1);
     const rval = list[index];
     list.splice(index,1);
     return rval;
  }

const GetModifyList = (value: number) => {
     if (value === 9 && globalVars['ndecks'] === 2) {
       return [-1,-2];
     }
     if (value === 0 ) {
       return [1,2];
     }
     return [-1,1];
  }

// export interface PanelEvent { action: string, values: Array<string>};
// export interface PlayerEvent { count: string, cut: string,strength: string} ;

 // image display
 export const getImage=(card:CardDm) => {
   const image=globalVars['image'];
   return '/assets/cards/'+card2image(card)+image.dims+'.png';

   // return downSizedImage(cimage);
 }
 const card2image=(card: {})=> {
    const map = {clubs: 'C', diamonds: 'D', hearts: 'H', spades: 'S'};
    if (card['num'] === 1) { return `A${map[card['suit']]}`;}
    else {
       return `${card['rank']}${map[card['suit']]}`;
    }
   }

// export const downSizedImage = (image) => {
//    const ex="[/a-zA-Z0-9_]*";
//    const rx=new RegExp(ex);
//    var myArray=rx.exec(image);
//    const rvalue = myArray[0]+`_${globalVars['image'].dims}`+'.png';
//
//    return rvalue;
// };
export const imagesAttr = {
  mobile: {
    width :  66,
    height : 100,
    cols:4,
    rows:2,
    dims:'_80x100',
    card: {
      width:66,
      height:100
    }
  },
  midDevice: {
    width :  79,// image 100x120
    height :  120,
    cols:6,
    rows:3,
    // dims:'_80x100',
    dims:'_100x120',
    card: {
      width:79,
      height:120
    }
  },
  desktop: {
    width :  79,// image 100x120
    height :  120,
    cols:8,
    rows:3,
    dims:'_100x120',
    card: {
      width:79,
      height:120
    }
  }

};
export const imageDims=(width, height) => {
  const image = globalVars['image'];
  if ( globalVars['desktop']) {
     globalVars['image']=imagesAttr.desktop;
  } else if (  globalVars['midDevice']) {
      globalVars['image']=imagesAttr.midDevice;

  } else {
    globalVars['image']=imagesAttr.mobile;
  }

};
export const downSizeImage =()=> {
  const image = globalVars['image'];
  const ipad=['MacIntel','iPad' ].includes(navigator.platform);
  const height= window.innerHeight-(ipad?200:0);
  image.width =  52;
  image.height = 80;
  // image.cols=Math.floor(window.innerWidth/image.width)-1;
  // image.rows=Math.floor(height/image.height)-1;
  if (globalVars['mobileDevice']) {
    image.cols++;
  }
  image.dims='_60x80';
};

export const upSizeImage =()=> {
  const ipad=['MacIntel','iPad' ].includes(navigator.platform);
  const height= window.innerHeight-(ipad?200:0);
  const image = globalVars['image'];
  image.width =  66;
  image.height = 100;
  // image.cols=Math.floor(window.innerWidth/image.width)-1;
  // image.rows=Math.floor(height/image.height)-1;
  if (globalVars['mobileDevice']) {
    image.cols--;
  }
  image.dims='_80x100';
};

export const bottom_menue_item = { };

@Injectable({providedIn: 'root'})
export class RouterService {
  nextRoutes=[];
  prevRoute=''
  currentRoute='';
  constructor (private router: Router) { }
  navigate(route, args=null) {
    if (route=='mobile/home') {
      globalVars['isAuthenticated'] = false;
    }
    if (args) {
      this.router.navigate([route, args]);
    } else {
      this.router.navigate([route]);

    }
    this.prevRoute=this.currentRoute==''? '/': this.currentRoute;
    this.currentRoute=route;

  }
  navigate_back() {
    //console.log(`navigate-back:${this.prevRoute}`)
    this.navigate(this.prevRoute);
  }
  nextRoute(route) {
    this.nextRoutes=[];
    this.nextRoutes.push(...route);
  }
  preRoute(route) { this.prevRoute=route; }
  getPrevRoute() { return this.prevRoute;}
  next() {
   if ( this.nextRoutes.length) {
     let route = this.nextRoutes.shift();
     this.navigate(route);
   }
  }
  pre() { this.navigate(this.prevRoute); }
  events() { return this.router.events;}
  isNextRoot() { return this.nextRoutes[0]=='/';}
  backempty() { return this.prevRoute =='';}
  nextempty() { return this.nextRoutes.length ==0;}
};




@Injectable({providedIn: 'root'})
export class MobileRouterService {
  currentRoute='';
  routes=[];
  constructor (private router: Router) {
    this.routes.push(['mobile/home', null]);
   }
  topush(route) {
    const top = this.routes.slice(-1)[0];
    return top[0] != route;
  }
  refreshed() {
    // console.log(`MobileRoute refreshed:: length:${this.routes.length}`);
    // this.print();
    if (this.routes.length ==1) {
      const route = this.routes[0];
      return route[0].includes('home');
    }
    return false;
  }
  includes(route) {
    return this.currentRoute.includes(route);
  }
  checkRoute(routes) {
    for (const route of routes) {
      if (this.includes(route)) {
         return true;
      }
    }
    return false;
  }
  navigate(route, args=null) {
    if (route=='mobile/home') {
      globalVars['isAuthenticated'] = false;
    }
    if (args) {
      this.router.navigate([route, args]);
    } else {
      this.router.navigate([route]);
    }
    this.routes.push([route,args]);
    this.currentRoute=route;
  }
 print() {
   console.log(JSON.stringify(this.routes));

 }
  navigate_back() {
    // console.log(`navigate_back`);
    // this.print();
    let route='';
    while(true) {
      route=this.routes.pop();
      if (route[0] != this.currentRoute) break;
    }
    this.navigate(route[0], route[1]);
  }
  prevRouteIncludes(key) {
    const len = this.routes.length-2;
    if (len>0) {
      const route = this.routes[len][0];
      // console.log(`prevRouteIncludes:: route:${route}`);
      return route.includes(key);
    }
    return false;
  }
  events() { return this.router.events;}

};

export const maxCardsPerCol = () => {
  const swidth = window.innerWidth;
  // if (swidth < 375) return 6;
  // if (swidth < 450) return 6;
  if (swidth < 450) return 4;

  return 8;
}
export const MaxRowCards = () => {
  const sheight = window.innerHeight;
  if (sheight< 600) return 2;
  if (sheight< 800) return 3;
  return 4;
}
const renewal=(router)=> {
  globalVars['credit']=0;
  globalVars['isAuthenticated']=false;
  alert('you are running out of credit');
  // clearInterval(interval);
  stopCountdownTimer();
  // interValCount=0;
  router.navigate('/subscriptions');
   // router.navigate('about');
}

let interValCount=0;
let interval;
/*
 msg code:
   700: 'Credit'
   701: 'Account not activated'
   702: 'Check your email to activate your account'
   703: 'Access table denied'
   704: 'login passed'
   705: 'Unable to send email'
   707: 'email mismatch'
   709: 'password mismatch'
   710: 'unable to process payment'
   */
// const sconnect = (router, http) => {
//   console.log(`token:${globalVars['token']}`)
//   // const subscribe = http.post('/api/check', {param: { token: globalVars['token']}}).pipe(catchError((err)=>Observable.throw ( err )))
//   const subscribe = http.post('/api/check', { token: globalVars['token']},{headers: new HttpHeaders().set('x-access-token',  globalVars['token']) })
//   .pipe(catchError((err)=>Observable.throw ( err )))
//   .subscribe((resp) => {
//      const status = resp['status'];
//      const code = resp['code'];
//      if (code==700) {
//        const credit = resp['credit'];
//        if (credit>0) {
//          globalVars['credit']= credit;
//         return;
//        }
//        renewal(router);
//      }
//      globalVars['isAuthenticated']=false;
//      alert("Server is down; please try later.");
//      return;
//    });
// }
// export const MaxCardsOut = maxCardsPerCol() * MaxRowCards();
export const MaxCardsOut = ()=> {
  // switch(globalVars['ndecks']) {
  //   case 1: return 12;
  //   case 2: return 16;
  //   case 6: return globalVars['mobileDevice']?21:32;
  //   default: return 0;
  // }
  return 24;
}


export const xmls = (router, http) => {
  // if (interValCount++) return;
  // setTimeout(() => {
  //   sconnect(router, http);
  // }, 300);
  // interval=setInterval(()=>{
  //    sconnect(router, http)
  // }, 20*MINUTE );
  // sconnect(router, http);
};
 let credit=0;

 const secondElapsed=()=> {
   const now=Date.now();
   const elapsed = Math.floor((now-globalVars['now'])/1000);
   globalVars['now']=now;
   return elapsed;
 }

 const timeTickerfunc =()=> {
  globalVars['credit'] -= secondElapsed();
  if (globalVars['credit']>0) {
    const splittime       = timeSplit(globalVars['credit']);
    globalVars['days']    = splittime.days;
    globalVars['hours']   = splittime.hours;
    globalVars['minutes'] = splittime.minutes;
    globalVars['seconds'] = splittime.seconds;
    return false;
  }
  return true;
}
let stopTimer=false;

export const stopCountdownTimer=()=> {
  stopTimer=true;

}

export const  startCountdownTimer=(router)=> {
  let repeat=true;
  const interval=setInterval(()=>{
     if (stopTimer) clearInterval(interval);
       if (repeat) {
         if (timeTickerfunc()) { // time expired
              renewal(router);
              repeat=false; // stop until credit is greater than '0' and stop alerting
          }
        } else if (globalVars['credit']>0) {
             repeat=true;
        }
  }, 1000);
}


export const isPageRoute = (key) => {
  return ['Train', 'Time-left','Certificate', 'Counting','Playing', 'Video'].includes(key);
}
const timeout=()=> {
  // switch (globalVars['page']) {
  //   case 'TrainTopComponent' : return 100;
  //   case 'AboutComponent' : return 2000;
  //
  // }
  return 200;
}

// export const SpeedInSec = () => {
//   return (globalVars['speed']/1000).toFixed(1);
// }
/*
globalVars['vpath']="count/tests/math";
globalVars['vpath']="count/tests/adding-count";
globalVars['vpath']="count/tests/net-count-1";
globalVars['vpath']="count/tests/all";
globalVars['vpath']="count/tests/License-practice";
globalVars['vpath']="count/lessons/adding-count";
globalVars['vpath']="count/lessons/net-count";
  globalVars['vpath']="concept/running-count";
  globalVars['vpath']="concept/adding-count";
  globalVars['vpath']="threshold";
  globalVars['vpath']="rules";
  globalVars['vpath']="certification";
  globalVars['vpath']="play";




*/
const lessonsGoto=(paths, method) => {
  if (paths[0]=='adding-count')
      switch(method) {
        case 'single': return '';
        case 'jack': return '';
        case 'tens': return '';
        case 'mit': return '';
        default: return '';
      }
  //return "/paths/2/lesson/net-count";
  switch(method) {
    case 'single': return '';
    case 'jack': return '';
    case 'tens': return '';
    case 'mit': return '';
    default: return '';
  }
};

const testsMathGoto=(method) => {
  switch(method) {
    case 'single': return '';
    case 'jack': return '';
    case 'tens': return '';
    case 'mit': return '';
    default: return '';
  }
};
const testsAddGoto=(method) => {
  switch(method) {
    case 'single': return '';
    case 'jack': return '';
    case 'tens': return '';
    case 'mit': return '';
    default: return '';
  }
};
const testsNetGoto=(method) => {
  switch(method) {
    case 'single': return '';
    case 'jack': return '';
    default: return '';
  }
};
const testsAllGoto=(method) => {
  switch(method) {
    case 'single': return '';
    case 'jack': return '';
    case 'tens': return '';
    case 'mit': return '';
    default: return '';
  }
};
const testsLicenseGoto=(method) => {
  switch(method) {
    case 'single': return '';
    case 'jack': return '';
    case 'tens': return '';
    case 'mit': return '';
    default: return '';
  }
};

const testsGoto=(path, method) => {
  switch(path) { // need to consider the method:[jack, single, mit, tens]
    case 'math': return testsMathGoto(method);
    case 'adding-count': return testsAddGoto(method);
    case 'net-count-1': return testsNetGoto(method);
    case 'all': return testsAllGoto(method);
    case 'License-practice':return testsLicenseGoto(method);
    default: return '';
  }
};
const conceptAddGoto= (method) => {
  switch(method) {
    case 'single': return '';
    case 'jack': return '';
    case 'tens': return '';
    case 'mit': return '';
    default: return '';
  }
};

const conceptNetGoto= (method) => {
  switch(method) {
    case 'single': return '';
    case 'jack': return '';
    case 'tens': return '';
    case 'mit': return '';
    default: return '';
  }
};

const goto=(video) => {
  const method = globalVars['method'];
  const paths=video.split('/');
  switch(paths[0]) {
    case 'count':
      return paths[1]=='lessons'? lessonsGoto(paths[2], method)
                                :  testsGoto(paths[2], method);
    case 'threshold': return '';
    case 'rules': return '';
    case 'certification': return '';
    case 'play':return '';
    case 'concept':
        return paths[1] =='adding-count'? conceptAddGoto(method): conceptNetGoto(method);

        // this should be the path to indroductry
    case 'top': return ''

    default: return '';
  }
};

export const getVideoUrl=()=> {
  let url=goto(globalVars['vpath']);
  if (url.length){
    return 'https://'+url;
  }
  return 'https://www.youtube.com/watch?v=THYgRo4w840';
};
export const pageRoute = (store, route, key) => {
  let page='';
  switch(key) {
    case ('Time-left') : {
      page='mobile/time-left';
      break;
    }case ('Train'): {
        page='mobile/train';
        break;
    }case ('Certificate'): {
        page='mobile/License';
        break;
    } case ('Counting'): {
        page='mobile/setting-train';
        break;
    } case ('Online-assist'): {
      page="online";
      break;
    } case ('Subscriptions'): {
         page="subscriptions";
         break;
    }
  }
  GenericGresetAction(store);
  GenericDieAction(store);

  setTimeout(() => {
    route.navigate(page);
  }, timeout());
};

export const SpeedInSec = () => {
  return (globalVars['speed']/1000).toFixed(1);
}

export const getMethodDesc=(append='')=> {
  switch(globalVars['method']) {
    case 'advance':
    case 'jack': return `Revised high-low count ${append}`;
    case 'single': return `Revised high-low single deck ${append}`;
    case 'mit': return `Classic high-low count  ${append}`;
    default: return `Count-tens ${append}`;
  }
};
export const decksMapping=()=> {
   const map = {
       1: 'single deck',
       2: 'double decks',
       6: 'six decks'
     };
  return map[globalVars['ndecks']]
}
export const getTheLesson=(lesson=true)=> {
	const _lesson=lesson? 'Level:': '';
  return `${_lesson}${decksMapping()}, ${globalVars['cards-out']} cards @${SpeedInSec()} sec`;
}

export const lpull=(...args)=> { // vardic
	const lst=[...args[0]];
	const rm=(val) => {
		let index=0;
		const found=[];
		for (const e of lst) {
        if (e==val) {
			     found.push(index);
		     } index++;
		}
		index=0;
		for (const e of found) {
			lst.splice(e-index,1);
			index++;
		}
	}
	for(let a=1; a!=args.length; a++) {
		rm(args[a]);
	}
	return lst;
};

export const lrange=(from, to=-1)=> {
   const lst=[];
   if (to==-1) {
	   to=from;
	   from=0;
   }
   for(let i=from; i!=to;i++) {
	   lst.push(i);
   }
	return lst;

};
export const lsum=(val)=> {
	let sum=0;
	for (const e of val) {
		sum+=e;
	}
	return sum;
};

export class ManageButtonColors {
   colors:any;
   constructor( colors:any) {
     this.colors=colors;
   }
   _mkeys(key, value) {
     const colors=this.colors[key];
     const chosen=colors['chosen'];
     colors[chosen]='leave';
     colors[value]='enter';
     colors['chosen']=value;
   }
   swap(value, key=null) {
    if (key) {
      this._mkeys(key,value);
      return;
    }
    const chosen=this.colors['chosen'];
    this.colors[chosen]='leave';
    this.colors[value]='enter';
    this.colors['chosen']=value;
   };
};
export const maxCardsOut=()=> {
  let rows=3;
  let cols=8;
  if (globalVars['desktop']) {
    rows=4;
    cols=8;
  } else if (globalVars['mobileDevice']) {
    rows=2;
    cols=5;
  }
  return  rows*cols;
};
export const licenseConfig=() => {
    let top=400;
    let left=500;
    if (globalVars['desktop']) {
      top=600;
    } else if (globalVars['mobileDevice']) {
      left=250;
      top=250;
    }
    return {top: top, left: left, cards:maxCardsOut() };
}
