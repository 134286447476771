import { CertificateDm} from './certificate.model';
import { Action } from '@ngrx/store';

const INSERT = 'certificate-insert';


export class InsertCertificateAction implements Action {
    readonly type = INSERT;
    constructor(public payload: CertificateDm) { }
}

export function certificateReducer(State = {}, action: InsertCertificateAction) {
    return action.type==INSERT? action.payload: {};
};
