
<div style="margin-left:{{vars['center-width']}}px" *ngIf="!die">
      <div style="margin-top:20px">
              <mat-tab-group dynamicHeight (selectedTabChange)="tabClicked($event)" style="margin-top:25px">
                  <mat-tab *ngFor="let count of ['+10','+12', '+14']" [label]="count" >
                    <div  class="mat-elevation-z8 font" >
                            <mat-table #table [dataSource]="tables[count]" >
                                  <ng-container  *ngFor="let col of columns" [matColumnDef]="col">
                                    <mat-header-cell *matHeaderCellDef > <span class="rule-cell"> {{col}} </span>  </mat-header-cell>
                                    <!-- <mat-cell *matCellDef="let element" class="{{gcls(col)}}"><span  class="rule-cell"> {{element[col]}} </span> </mat-cell> -->
                                    <mat-cell *matCellDef="let element" > <span class="rule-cell">{{element[col]}} </span> </mat-cell>
                                  </ng-container>
                                  <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                                  <mat-row *matRowDef="let row; columns: columns;"></mat-row>
                            </mat-table>
                    </div>
                  </mat-tab>
              </mat-tab-group>

 </div>
</div>
