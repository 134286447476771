import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SettingsComponent } from './settings';
import {AboutComponent} from './mobile/static/about.component';
// import {TrainPlayComponent} from '@app/train/train-play/play.component';
//import {CountTutorialComponent} from '@app/train/top.component';
// import {TrainContainerComponent} from '@app/train/top/top.component';
import { SubscriptionsComponent } from '@app/subscription/subscriptions/subscriptions.component';
import { CheckoutComponent } from '@app/subscription/checkout/checkout.component';

import { AuthGuard} from './services/auth-guard-service';
import { ContactustComponent} from './contact-us/contactus.component';
// import { SocialmediaComponent} from './social-media/social.component';

import {MobileOnlineBJcomponent} from './mobile/online/online.component';

import {videoComponent} from './video-component/video.component';
import { SignupComponent  } from './signup/signup.component';
import {ProxyComponent} from './mobile/proxy/proxy.component';
const routes: Routes = [
  {
    path:'mobile',
    loadChildren: ()=> import ('./mobile/mobile.module').then(mod=>mod.MobileModule)
  },
   // {
   //   path: '',
   //   redirectTo: 'AboutComponent',
   //   // pathMatch: 'full'
   // },
  {
    path: 'video',
    component: videoComponent,
   canActivate: [AuthGuard],
    data: {
      title: 'video'
    }
  },
  // {
  //   path: 'signin',
  //   component: ProxyComponent,
  //   data:{
  //      page:'signin-org'
  //   }
  // },
  {
    path: 'signin',
    component: SignupComponent,
    data:{ }
  },
  {
    path: 'online',
    component: MobileOnlineBJcomponent,

    data:{ }
  },

  {
    path: 'subscriptions',
    component: SubscriptionsComponent,
    data: {
      title: 'Subscriptions'
    }
  },
  {
    path: 'checkout',
    component: CheckoutComponent,
    data: {
      title: 'Checkout'
    }
  },

  {
    path: 'contact',
    component: ContactustComponent,
//    canActivate: [AuthGuard],
    data: {
      title: 'Contact-us'
    }
  },



  {
    path: 'about',
    component:AboutComponent
  },
  {
    path: '',
    component:AboutComponent
  }

];

@NgModule({
  // useHash supports github.io demo page, remove in your app
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
