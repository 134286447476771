
<!-- <div class="scene flex-row-center-fake max-height" style="margin-top:20px" *ngIf="!die"> -->

<!-- <div style="margin-left:{{vars['center-width']}}px" *ngIf="!die"> -->
  <div  style="width:100%; height:100%"*ngIf="!die">
    <div  style="margin-top:20px">
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"   (afterChange)="afterChange($event)" style="height:80%">
              <div  ngxSlickItem class="slide" style="margin-left:{{vars['center-width']}}px">
                   <p> Play:<span style="margin-left:10px">{{suggested.play}} </span> </p>
                   <p> Bet:<span style="margin-left:10px">{{suggested.bet}}</span> </p>
                   <div style="margin-top:20px !important">
                        <anms-online-betting > </anms-online-betting>
                   </div>
              </div>
              <div  ngxSlickItem class="slide" style="width:100%; ">

                <div class="flex-row" style="width:100%;margin-left:{{vars['online-width']-100}}px">
                  <div style="width:40%">
                        <div class="flex-column" style="margin-left:5px;">
                                <div> Select the incoming cards </div>
                                <div class="flex-row margin-top-10px" >
                                          <div class="flex-column" *ngFor="let key of ['one', 'two','three', 'four', 'five']" style="margin-left:10px">
                                                        <ng-container *ngFor="let card of cardsImage[key]">
                                                             <img  class="image-pointer" style="margin-right:3px" src="{{card.img}}" (click)="clicked('card', card['count'])">
                                                        </ng-container>
                                           </div>
                                  </div>
                        </div>
                    </div>
                    <div class="scene" style="width:60%">
                       <div class="flip"  [@cardFlip]="state">
                            <div class="face back" style="cursor:pointer">
                              <a (click)="flipit()" >
                                <div class="flex-column" >
                                				      <div class="mat-elevation-z8 margin-top-20px table-font">
                                                  <mat-table  [dataSource]="resultTable" >
                                                        <ng-container  [matColumnDef]="col" *ngFor="let col of resultReport.columns">
                                                              <mat-header-cell *matHeaderCellDef > {{ col }} </mat-header-cell>
                                                             <mat-cell *matCellDef="let element"> {{element[col]}} </mat-cell>
                                                        </ng-container>
                                                        <mat-header-row *matHeaderRowDef="resultReport.columns"></mat-header-row>
                                                        <mat-row *matRowDef="let row; columns: resultReport.columns;"></mat-row>
                                                  </mat-table>
                                            </div>
                                      <mat-paginator #paginator  [pageSizeOptions]="getOptions()" showFirstLastButtons ></mat-paginator>
                                 </div>
                              </a>
                            </div>
                            <div class="face front" >
                              <a (click)="flipit()">
                                <div class="mat-elevation-z8 margin-top-20px table-font">
                                        <mat-table #table [dataSource]="dataSource">
                                              <ng-container  matColumnDef="hand" >
                                                <mat-header-cell *matHeaderCellDef > hand </mat-header-cell>
                                                <mat-cell *matCellDef="let element"> {{element['hand']}} </mat-cell>
                                              </ng-container>
                                              <ng-container  matColumnDef="splits" >
                                                <mat-header-cell *matHeaderCellDef > splits  </mat-header-cell>
                                                <mat-cell *matCellDef="let element"> {{element['splits']}} </mat-cell>
                                              </ng-container>
                                              <ng-container  matColumnDef="cards">
                                                <mat-header-cell *matHeaderCellDef > cards </mat-header-cell>
                                                <mat-cell *matCellDef="let element"> {{element['cards']}} </mat-cell>
                                              </ng-container>
                                              <ng-container  matColumnDef="@">
                                                <mat-header-cell *matHeaderCellDef > @ </mat-header-cell>
                                                <mat-cell *matCellDef="let element"> {{element['@']}} </mat-cell>
                                              </ng-container>
                                              <ng-container  matColumnDef="count" >
                                                <mat-header-cell *matHeaderCellDef > count </mat-header-cell>
                                                <mat-cell *matCellDef="let element"> {{element['count']}} </mat-cell>
                                              </ng-container>
                                              <ng-container  matColumnDef="INS" >
                                                <mat-header-cell *matHeaderCellDef > INS </mat-header-cell>
                                                <mat-cell *matCellDef="let element">{{element['INS']}}</mat-cell>
                                              </ng-container>
                                              <ng-container matColumnDef="action">
                                                <mat-header-cell *matHeaderCellDef > action </mat-header-cell>
                                                  <mat-cell   *matCellDef="let element">
                                                      <button mat-raised-button   [disabled]="buttons.BLJ.disabled"
                                                       *ngIf="element['hand']=='dealer' else player"
                                                       [@flash]="buttonStatus(dealerAction)" (click)="bclicked(dealerAction)"
                                                        [color]="'warn'"> <mat-icon *ngIf="icon.length"> {{icon}} </mat-icon> {{dealerAction}}</button>
                                                      <ng-template #player>
                                                        <button mat-raised-button  [color]="'primary'">  {{element['action']}} </button>
                                                      </ng-template>
                                                    </mat-cell>
                                              </ng-container>
                                              <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                                              <mat-row *matRowDef="let row; columns: columns;"></mat-row>

                                        </mat-table>
                                </div>
                              </a>
                            </div>
                       </div>
                    </div>


                  </div>
                </div>
                </ngx-slick-carousel>
                <div class="flex-row" style="margin-left:{{vars['center-width']}}px; margin-top:20px">
                    <button  mat-fab  [disabled]="buttons[elm].disabled"
                                      [matTooltip]="tooltip(elm)"
                                      *ngFor="let elm of ['P','R', 'B', 'S']"
                                      [@flash]="buttons[elm].status"
                                      class="bgold imargins"
                                      (click)="bclicked(elm)"> {{elm}}</button>
                </div>
  </div>

</div>
