const packageJson = require('../../package.json');

export const environment = {
  appName: 'Beat Blackjack',
  envName: 'PROD',
  production: true,
  //stripeKey: 'pk_test_aVu1ldaDMq1isx4Y0wVxp2DF00hOxCDIgY',
  stripeKey: 'pk_live_5zxqYzPVq727xePbbXEaKw9Z00G0xb5r8K',
  // stripeKey:'pk_test_V9W0SjFP9qSEPlrmQTYS3zg800gOnHwKYw',
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies['@angular/core'],
    ngrx: packageJson.dependencies['@ngrx/store'],
    material: packageJson.dependencies['@angular/material'],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    angularCli: packageJson.devDependencies['@angular/cli'],
    typescript: packageJson.devDependencies['typescript']
  }
};
