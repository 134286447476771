import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { tap, filter} from 'rxjs/operators';

import { LocalStorageService } from '../local-storage/local-storage.service';
import { ActionIF } from '../core.interfaces';

import { AUTH_KEY, AUTH_LOGIN, AUTH_LOGOUT, AUTH_MISC } from './auth.reducer';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions<ActionIF>,
    private localStorageService: LocalStorageService,
  ) {
    this.localStorageService.setItem(AUTH_KEY, { isAuthenticated: false, token: '' })
  }
  @Effect({ dispatch: false })
  login(): Observable<ActionIF> {
    return this.actions$
//      .ofType(AUTH_LOGIN),
      .pipe(
        filter(action => action.type === AUTH_LOGIN), 
        tap(action => {
           //console.log(`auth: incoming data: ${JSON.stringify(action)}`);
          this.localStorageService.setItem(AUTH_KEY, { isAuthenticated: true, 
                           token: action.payload.token,
                            // activity: action.payload.activity
                          })
//          this.localStorageService.setItem(AUTH_KEY, { isAuthenticated: true, token: 'jwt'})
//           console.log("core.auth.auth.effect setting login info");
          }
        )
      );
  }

  @Effect({ dispatch: false })
  logout(): Observable<ActionIF> {
    return this.actions$
      //.ofType(AUTH_LOGOUT)
      .pipe(
        filter(action => action.type === AUTH_LOGOUT), 
        tap(action => {
           //console.log("core.auth.auth.effect setting logout info");
          this.localStorageService.setItem(AUTH_KEY, { isAuthenticated: false })
          }
        )
      );
  }
}
