import { ReduceAnimationDm } from './animation.reduce.model';
import { Action } from '@ngrx/store';

const INSERT= 'insert-reduce-animation';


export class InsertReduceAnimationAction implements Action {
    readonly type = INSERT;
    constructor(public payload: ReduceAnimationDm) { }
}


export function reduceAnimationReducer(State = {}, action: InsertReduceAnimationAction) {
    return action.type==INSERT? action.payload: {};
};

