import { Component, OnInit, OnDestroy, Input,Output, EventEmitter} from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';

import {InsertCardsDataUnit } from '../../store/cards.model';
import {trigger,state, style,transition,animate,query,stagger,keyframes} from '@angular/animations';
import { globalVars, downSizeImage, upSizeImage } from '../../../services/global.service';
import {MobileCountCardAction, MobileCardsDrawnAction, MobileCardsPeerErase} from '../../store/cards.action.reducer';
import {MobileSendKeyRoute} from '../../store/bottom.action.reducer';
import { FlashButtons, FilterType, START_FLASH,  FADEOUT, FADEIN,nFlash} from '../../../services/common.service';
import {   DEALER_DISPLAY_ID} from '../../../store/switchcards.model';
const DROP='drop-cards';
@Component({
  selector: 'mobile-base-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['../../styles.scss'],
  animations : [
    trigger('flash', [
      transition(`* => ${FADEIN}`, [
        style({ opacity: 0 }),
        animate(1200, style({ transform: 'translateX(0) scale(1)', opacity: 1 }))
      ]),
      transition(`* => ${FADEOUT}`, [
        animate(1900, style({ transform: 'translateX(0) scale(0.5)', opacity: 0 }))
      ])
    ]),
    trigger('blist', [
          transition('* => *', [
             // Initially the all cards are not visible
             query(':enter', style({ opacity: 0 }), { optional: true }),
               query(':enter', stagger('300ms', [
                 animate('1s ease-in', keyframes([
                   style({ opacity:  0, transform: 'translateX(-10px)', offset: 0 }),
                   style({ opacity:  0.5, transform: 'translateX(10px)', offset: 0.3 }),
                   style({ opacity:  1, transform: 'translateX(0)', offset: 1 }),
                 ]))]), { optional:  true }),

             query(':leave', stagger('300ms', [
               animate('1s ease-in', keyframes([
                 style({   opacity:  1, transform: 'translateX(50px)', offset: 0 }),
                 style({  opacity: .2, transform: 'translateX(300px)', offset: 0.7}),
                 style({  opacity: 0, transform: 'translateX(400px)', offset: 1}),

               ]))]), { optional:  true }),
             ])
     ]),
    trigger('list', [
          state('in', style({
                 opacity: 1,
                 transform: 'translateX(0)'
               })), // end of state
          transition('void => *', [
             style({
                     opacity: 0,
                     transform: 'translateX(-100px)'
                   }),
                      animate(300)
                        ]),
          transition(
              '* => void', [
                  animate(500, style({
                       transform: 'translateX(100px)',
                       //transform: 'rotate(180deg)',
                        opacity: 0
                  }))
          ])
    ]),


  ]

})

export class MobileBaseCardsComponent implements OnInit, OnDestroy  {

subscriptions=[];
cards=[];
badges=[];
cardsMargin='margin-right-';
savedList=[];
height = {base:0, length:0, image:0,  extra:0};
width = {base:0, length:0,image:0, extra:0};
display=0;
vars:any;
marginLeft=0;
cardCount=0;
reduced={enabled:false, badge:'4l', top:0, left:0, cls:''};
nflash=new nFlash();
mobileDevice=false;

@Input('id') id: number;
@Input() label=''
@Input('mode') mode='flat';
@Output() event = new EventEmitter<{}>();
labelClass='';
ilocation={};
cwidth=0;
cheight=0;
sbadge={enabled:false, value:'0'};
dealerBadgeMargin=0;
// bgClass="cards-makeup";
bgClass="";

// bgClass="";

constructor(public store: Store<any>) {  }
  ngOnInit():void {
    // endable the down arrow, disable up-arrow
    // MobileSendKeyRoute(this.store,[      {key:"switch"}    ]);

  this.cardsMargin += globalVars['netcount']? '4': '2';
  //console.log(`base-card, ngOnInit id:${this.id}`)
  this.vars = globalVars;
  //console.log(`vars[mobileDevice]: ${this.vars['mobileDevice']}`)
  this.marginLeft=globalVars['license']? 5:0;
  this.mobileDevice=globalVars['mobileDevice'];
  const players=(id)=> id!=DEALER_DISPLAY_ID;

  if (!players(this.id)) {
    this.labelClass="dealer-label";
  }

  this.subscriptions.push(
    this.store
      .select('mobileCard')
      .pipe(filter(event =>  event.typeof=== 'InsertCardsDataUnit'))
      .subscribe((data: InsertCardsDataUnit) => {


        // console.log(`id::${data.id}, this.id::${this.id}, msg:${data.msg}`);

        if (data.msg=='BACKGROUND') {
          this.bgClass="";
          return;
        }
        if (data.id!==this.id) return;
        switch (data.msg) {

          case 'PLAYER' : {
            this.cardsMargin="margin-5px";
             return;
          } case 'REPLACE': {
            this.cards[0] = data.cards[0]['card'];
            this.event.emit({id:this.id, action:'drawn'})
            return;
          } case 'DROP-1': {
            const length = this.cards.length;
            this.cards.splice(1,length-1);
            return;
          } case 'DROP': {
            this.erase();
            return;
          } case 'FLASH': {
            if (players(this.id) && !this.nflash.isActive()) {
                  this.flashit();
            }
            return;
          } case 'UNFLASH': {
            if (players(this.id) && this.nflash.isActive()) {
              this.unflash();
            }
            return;
          } case 'SBADGE': { // show badge
            setTimeout(() => {
              this.sbadge.enabled=true;
              this.sbadge.value=data.cards[0];
              this.dealerBadgeMargin=Math.floor((this.cwidth*this.cards.length)/2);
            }, globalVars['speed']+300);

            return;
          } case 'erase': {
            if (this.display) {
                this.erase();
           }
            return;
          }
        }
        if (data.cards) {
           this.process(data.cards);
           return;
         }
         this.pbadges(this.savedList,0);
      })
    );
    // this.calc();
    this.calBaseHeight();
  }
  flashit() {
    this.nflash.flash();
    this.labelClass="player-label";
  }
  unflash() {
    this.nflash.unflash();
    this.labelClass="player-label-none";

  }
  calBaseHeight() {
    const dims=globalVars['image']['dims'];
     this.cheight=dims.includes('80')? 100: 120;
    this.cwidth=dims.includes('80')? 66: 79;
    const factor=dims.includes('80') ? 13: 20;
    const base=dims.includes('80')? 40: 35;
    const leftMargin=dims.includes('80')?13:17;
    this.ilocation= {
      // 2: { top: base+factor, left: 20},
      // 3: { top: base+factor, left: 20+this.cwidth},
      // 4: { top: base+factor*2, left: 20},
      // 5: { top: base+factor*2, left: 20+this.cwidth},
      // 6: { top: base+factor*3, left: 20},
      // 7: { top: base+factor*3, left: 20+this.cwidth},

      2: { top: factor, left: leftMargin},
      3: { top: factor, left: leftMargin+this.cwidth+2},
      4: { top: factor*2, left: leftMargin},
      5: { top: factor*2, left: leftMargin+this.cwidth+2},
      6: { top: factor*3, left: leftMargin},
      7: { top: factor*3, left: leftMargin+this.cwidth+2},
      8: { top: factor*4, left: leftMargin},
      9: { top: factor*4, left: leftMargin+this.cwidth+2},
      10: { top: factor*5, left: leftMargin},
      11: { top: factor*5, left: leftMargin+this.cwidth+2},

    }
  }
  pbadges(list:any, speed:any):void {
    //console.log(`the list is ${JSON.stringify(list)}`);
    list=list.filter(x=> x.badge);
    // console.log(`the list after filter is ${JSON.stringify(list)}`);

    if (list.length==0) return;
    this.display++;
    this.reduced.left=this.leftMargin(list.length-1)+15;
    this.reduced.top=this.height.base+27;
    this.reduced.cls=globalVars['mobileDevice']? "numbers-nav-reduced-mobile": "numbers-nav-reduced";
    this.reduced.cls= "numbers-nav-reduced";

    // this.transform(list);
    setTimeout(()=>{
      for(let i=0; i!=list.length;i++) {
        let badge=list[i].value;
        if (badge.includes('/')) {
          const spbadge=badge.split('/');
          this.badges.push(spbadge[0]);
          this.reduced.badge=spbadge[1];
          setTimeout(() => {
            this.reduced.enabled=true;
          }, (list.length+1)*300);
        } else {
          this.reduced.enabled=false;
          this.badges.push(list[i].value);
        }
      }
    }, speed);
    setTimeout(()=>{
      MobileCardsDrawnAction(this.store);
    }, speed+500);

  }

  erase() :void{
    let timeout=0;
    this.sbadge.enabled=false;
    this.sbadge.value='0';
    if (this.display ==2 ) { // badges are displayed so remove badges first
      this.badges=[];
      this.reduced.enabled=false;
      timeout=1200;
      this.display-=1;
    }
    if (this.cards.length) {
      setTimeout(() => {
        this.cards=[];
        this.display-=1;
        // if (this.id==0) {
          // MobileCountCardAction(this.store, 'null');
            this.event.emit({id:this.id, action:'removed'});
            this.cardCount--;
            // console.log(`cardCount:${this.cardCount}`);
            // if (! this.cardCount) {
            //   this.event.emit({id:this.id, action:'allRemoved'});
            // }
        // }
      }, timeout);
    }

  }
  calc() :void{
    const height = this.height;
    const width = this.width;
    const extH = globalVars['testing']? 20:0;

    if (globalVars['mobileDevice']) {
      //image 80x100
      width.base=15;
      width.length = 30;
      height.base=40;
      // height.base=125;
      height.length=100+extH;
    } else {
      //image 100x120
      width.base=20;
      // width.base= Math.floor(window.innerWidth/8);
      width.length = 49; // cards-length
      width.extra=2;
      height.base=35;
      // height.base=175;
      height.length=120+extH;
    }
  }
 leftMargin(index:any):any {
   // return this.width.base+index*this.width.length+ (index?this.width.extra*index:0);
   const width = this.width;
   const image=globalVars['image'];
   const base = globalVars['mobileDevice']? 20: 15;
   const height = this.height;
   height.base=globalVars['desktop']? 35: 40;
   width.length=image.width-2*base;
   return base+index*image.width+index*5;
 }

  handleCards(elm) {
    const card = elm.card;
    this.cards.push(card);
    this.event.emit({id:this.id, action:'drawn'});
    this.cardCount++;
  }
  process(list:any) :void{
// {(id+1)*75+this.offset}
    // this.offset = globalVars['testing'] ? 70+this.id*30: this.id*30;
    // offset is the width
    //console.log(`the id:${this.id}`)

    // width.length =
    // const deviceOffset = globalVars['mobileDevice']? 40:0;
    // const offset = (globalVars['testing'] ? 70:45)+ deviceOffset;
    // this.offset = deviceOffset+ offset + (this.id+1)*75 + this.id*3;
    let speed=globalVars['speed'];
    this.display++;

    if (this.badges.length) {
      setTimeout(()=>this.badges=[], 500);
      speed+=500;
    }
    let i=globalVars['play']? 0: -1; //disable it if play is on
    for(const elm of list) {
      i+=1;
      if (i==0 && this.badges.length==0) {
        this.handleCards(elm);
        continue;
      }
      setTimeout(()=>this.handleCards(elm), speed);
      speed+=globalVars['speed'];
    }
    speed -= globalVars['speed'];
    if (globalVars['testing']) { // don't show the badges during testing
      this.savedList = list;
      return;
    }
    list=list.filter(x=> x.badge);
    if (list.length) {
      this.pbadges(list, speed);
    }
  }

  ngOnDestroy():void {
     for (const elm of this.subscriptions)
          elm.unsubscribe();
   }

}
