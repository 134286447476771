
import { Cntrl2SwitchSetupDm, Cntrl2switchDecisionDm, Switch2CntrlDm, Switch2CardDisplayDm,
          CardDisplay2SwtichDm, MobileResultDataUnit} from './switchcards.model';

import { Action } from '@ngrx/store';

 const INSERT_C2SS = 'c2ss-insert';
const INSERT_C2SA = 'c2a-insert';
const INSERT_S2C = 's2c-insert';
const INSERT_S2CARDS = 's2cards-insert';
const INSERT_CC2S = 'ccards2swtich_insert';
const INSERT_RESULT= 'mobile-result-insert';

const inserts=[INSERT_C2SS,INSERT_C2SA,INSERT_S2C,INSERT_S2CARDS,INSERT_CC2S,INSERT_RESULT];

class InsertPlayerMobileResultsAction implements Action {
    readonly type = INSERT_RESULT;
    constructor(public payload: MobileResultDataUnit) { }
}

export class InsertC2SSetupAction implements Action {
    readonly type = INSERT_C2SS;
    constructor(public payload: Cntrl2SwitchSetupDm) { }
}

export class InsertC2SAction implements Action {
    readonly type = INSERT_C2SA;
    constructor(public payload: Cntrl2switchDecisionDm) { }
}

export class InsertS2CAction implements Action {
    readonly type = INSERT_S2C;
    constructor(public payload: Switch2CntrlDm) { }
}

export class InsertSwitch2CardDisplayAction implements Action {
    readonly type = INSERT_S2CARDS;
    constructor(public payload: Switch2CardDisplayDm) { }
}
export class InsertCC2SwitchAction implements Action {
    readonly type = INSERT_CC2S;
    constructor(public payload: CardDisplay2SwtichDm) { }
}
type actions=InsertPlayerMobileResultsAction|InsertC2SSetupAction|InsertC2SAction|InsertS2CAction|InsertSwitch2CardDisplayAction|InsertCC2SwitchAction;

export function switchCardReducer(State = {}, action: InsertPlayerMobileResultsAction) {
    return inserts.includes(action.type)? action.payload: {};
};


export function SendPlayerCardDrawnAction(store) {
  store.dispatch(new InsertCC2SwitchAction(new CardDisplay2SwtichDm(-1, 'drawn')));
}
export function MobilePlayerResultAction(store, data) {
  store.dispatch(new InsertPlayerMobileResultsAction({typeof:'MobileResultDataUnit', data:data}));
}
