  <div style="margin-left:{{vars['center-width']}}px" *ngIf="pageEnabled">
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (afterChange)="afterChange($event)">
                <div ngxSlickItem class="slide" *ngIf="enabled['method']">
                         <mat-card class="example-card">
                           <!-- "Hover to display information on below."
        matTooltipPosition="below">  -->

                                <mat-card-actions style="width:100%">
                                  <div class="flex-column" style="width:100%">

                                     <button  style="height:50px" mat-raised-button  class="{{mouseClass['method']['advance']}} max-width margin-top-10px"
                                         (click)="clicked('method', 'advance')" >Revised high-low count</button>

                                      <button style="height:50px" mat-raised-button  class="{{mouseClass['method']['mit']}} max-width margin-top-10px"
                                       (click)="clicked('method', 'mit')"  >Classic high-low count</button>

                                      <button style="height:50px" mat-raised-button  class="{{mouseClass['method']['ten']}} max-width margin-top-10px"
                                       (click)="clicked('method', 'ten')" >Count-tens</button>


                                  </div>
                                </mat-card-actions>
                        </mat-card>
                </div>
                <div ngxSlickItem class="slide" *ngIf="enabled['voice']">

                           <mat-card class="example-card">
                                  <mat-card-header>
                                    <mat-card-title>Activate voice recognition</mat-card-title>
                                    <mat-card-subtitle>
                                      Give it a few free runs to see if it works for you
                                    </mat-card-subtitle>
                                  </mat-card-header>
                                  <mat-card-actions>
                                    <div class="flex-column" style="width:100%">
                                      <ng-container *ngFor="let rule of ['yes', 'no']; let i=index">
                                         <button mat-raised-button style="height:50px"   class="{{mouseClass['voice'][rule]}} max-width margin-top-10px"
                                           (click)="clicked('voice', rule)" >{{rule}}</button>
                                       </ng-container>
                                      </div>
                                  </mat-card-actions>
                          </mat-card>
                </div>

                <div ngxSlickItem class="slide" *ngIf="enabled['decks']">
                      <mat-card class="example-card">
                             <mat-card-actions style="width:100%">
                               <div class="flex-column" style="width:100%">
                                  <ng-container *ngFor="let deck of ['single', 'double', 'six']">
                                        <button mat-raised-button
                                           [disabled]='decks[deck]'
                                           style="height:50px"
                                               class="{{mouseClass['decks'][deck]}} max-width margin-top-10px" (click)="clicked('decks', deck)"> {{deck}} </button>
                                  </ng-container>
                              </div>
                             </mat-card-actions>
                   </mat-card>
                </div>
                <div ngxSlickItem class="slide" *ngIf="enabled['risk']">
                      <mat-card class="example-card">
                             <mat-card-actions style="width:100%">
                               <div class="flex-column" style="width:100%">
                                   <ng-container *ngFor="let risk of ['high', 'moderate', 'low'];let i=index">
                                        <button mat-raised-button   class="{{mouseClass[risk]}} max-width margin-top-10px"
                                            style="height:50px" (click)="clicked('risk', risk)" > {{risk}} </button>
                                    </ng-container>
                              </div>
                             </mat-card-actions>
                   </mat-card>
                </div>
                <div ngxSlickItem class="slide " *ngIf="enabled['cards']">

                     <mat-card class="example-card">
                            <mat-card-actions style="width:100%">
                              <div class="flex-column" style="width:100%">
                                <div class="flex-row" >
                                     <ng-container *ngFor="let card of ['3','4','5','6']">
                                          <button mat-raised-button
                                               [disabled]='false'
                                             class="{{mouseClass['cards'][card]}} cards-button-margin" (click)="clicked('cards', card)"> {{card}} </button>
                                      </ng-container>
                                </div>
                                <div class="flex-row" >
                                     <ng-container *ngFor="let card of ['7','8','9','10']">
                                          <button mat-raised-button
                                               [disabled]='ncards.disabled[card]'
                                               class="{{mouseClass['cards'][card]}} cards-button-margin" (click)="clicked('cards', card)"> {{card}} </button>
                                      </ng-container>
                                </div>
                                <div class="flex-row" >
                                     <ng-container *ngFor="let card of ['11','12','13','14']">
                                          <button mat-raised-button
                                               [disabled]='ncards.disabled[card]'
                                               class="{{mouseClass['cards'][card]}} cards-button-margin" (click)="clicked('cards', card)"> {{card}} </button>
                                      </ng-container>
                                </div>
                                <ng-container *ngIf="!gvars['mobileDevice']">
                                      <div class="flex-row" >
                                           <ng-container *ngFor="let card of ['15','16','17','18']">
                                                <button mat-raised-button
                                                     [disabled]='ncards.disabled[card]'
                                                    class="{{mouseClass['cards'][card]}} cards-button-margin" (click)="clicked('cards', card)"> {{card}} </button>
                                            </ng-container>
                                      </div>
                                      <div class="flex-row" >
                                           <ng-container *ngFor="let card of ['19','20','21','22']">
                                                <button mat-raised-button
                                                     [disabled]='ncards.disabled[card]'
                                                     class="{{mouseClass['cards'][card]}} cards-button-margin" (click)="clicked('cards', card)"> {{card}} </button>
                                            </ng-container>
                                      </div>
                                      <div class="flex-row" >
                                           <ng-container *ngFor="let card of ['23','24','25','26']">
                                                <button mat-raised-button
                                                     [disabled]='ncards.disabled[card]'
                                                     class="{{mouseClass['cards'][card]}} cards-button-margin" (click)="clicked('cards', card)"> {{card}} </button>
                                            </ng-container>
                                      </div>
                                </ng-container>



                             </div>
                            </mat-card-actions>



                     </mat-card>
                </div>
                <div ngxSlickItem class="slide" *ngIf="enabled['speed']">
                   <mat-card class="example-card">
                          <mat-card-actions style="width:100%">
                            <div class="flex-column" style="width:100%">
                                   <div class="flex-row" >
                                             <ng-container *ngFor="let spd of speedDistr(0)">
                                                  <button mat-raised-button
                                                        [disabled]='speed.disabled[spd]'
                                                       class="{{mouseClass['speed'][spd]}} cards-button-margin" (click)="clicked('speed', spd)"> {{spd}} </button>
                                              </ng-container>

                                   </div>
                                   <div class="flex-row" >
                                     <ng-container *ngFor="let spd of speedDistr(1)">
                                          <button mat-raised-button
                                                [disabled]='speed.disabled[spd]'
                                                 class="{{mouseClass['speed'][spd]}} cards-button-margin" (click)="clicked('speed', spd)"> {{spd}} </button>
                                      </ng-container>
                                   </div>
                                   <div class="flex-row" >
                                     <ng-container *ngFor="let spd of speedDistr(2)">
                                          <button mat-raised-button
                                                [disabled]='speed.disabled[spd]'
                                                 class="{{mouseClass['speed'][spd]}} cards-button-margin" (click)="clicked('speed', spd)"> {{spd}} </button>
                                      </ng-container>
                                   </div>
                                   <div class="flex-row" >
                                     <ng-container *ngFor="let spd of speedDistr(3)">
                                          <button mat-raised-button
                                                [disabled]='speed.disabled[spd]'
                                                 class="{{mouseClass['speed'][spd]}} cards-button-margin" (click)="clicked('speed', spd)"> {{spd}} </button>
                                      </ng-container>
                                   </div>
                                    <div class="flex-row" >
                                      <ng-container *ngFor="let spd of speedDistr(4)">
                                           <button mat-raised-button
                                                 [disabled]='speed.disabled[spd]'
                                                  class="{{mouseClass['speed'][spd]}} cards-button-margin" (click)="clicked('speed', spd)"> {{spd}} </button>
                                       </ng-container>
                                   </div>
                            </div>
                          </mat-card-actions>

                   </mat-card>
                </div>

                <div ngxSlickItem class="slide" *ngIf="enabled['soft17']">

                           <mat-card class="example-card">
                                  <mat-card-header>
                                    <mat-card-title>Select soft17 rule:</mat-card-title>
                                    <mat-card-subtitle>
                                      most casinos hits on soft17
                                    </mat-card-subtitle>
                                  </mat-card-header>
                                  <mat-card-content>
                                    <p> avoid those casinos hitting on soft17</p>
                                  </mat-card-content>

                                  <mat-card-actions>
                                    <div class="flex-column">
                                        <ng-container *ngFor="let rule of ['stay', 'hit']">
                                             <button mat-raised-button
                                                   [disabled]='false'
                                                    class="{{mouseClass['soft17'][rule]}} max-width margin-top-10px" (click)="clicked('soft17', rule)"> {{rule}} </button>
                                         </ng-container>
                                   </div>

                                  </mat-card-actions>
                          </mat-card>
                </div>
                <div ngxSlickItem class="slide" *ngIf="enabled['displayCount']">

                           <mat-card class="example-card">
                                  <mat-card-header>
                                    <mat-card-title>Select count display:</mat-card-title>
                                    <mat-card-subtitle>
                                       start with showing the count
                                    </mat-card-subtitle>
                                  </mat-card-header>
                                  <mat-card-content>
                                    <p> as you play compare your count with shown count</p>
                                  </mat-card-content>
                                  <mat-card-actions>
                                    <div class="flex-column">
                                        <ng-container *ngFor="let rule of ['show', 'hide']">
                                             <button mat-raised-button
                                                   [disabled]='false'
                                                   style="height:50px"
                                                    class="{{mouseClass['count'][rule]}} max-width margin-top-10px" (click)="clicked('count', rule)"> {{rule}} </button>
                                         </ng-container>
                                   </div>

                                  </mat-card-actions>


                          </mat-card>
                </div>
                <div ngxSlickItem class="slide" *ngIf="enabled['splits']">
                           <mat-card class="example-card">
                                  <mat-card-header>
                                    <mat-card-title>Select number of splits allowed:</mat-card-title>
                                    <mat-card-subtitle>
                                       most casino allow 2 splits
                                    </mat-card-subtitle>
                                  </mat-card-header>
                                  <mat-card-content>
                                    <p> avoid those casinos not allowing double down after split</p>
                                  </mat-card-content>

                                  <mat-card-actions>
                                    <div class="flex-column">
                                        <ng-container *ngFor="let rule of [2,3,4,5]">
                                             <button mat-raised-button
                                                   [disabled]='false'
                                                   style="height:50px"
                                                    class="{{mouseClass['splits'][rule]}} max-width margin-top-10px" (click)="clicked('splits', rule)"> {{rule}} </button>
                                         </ng-container>
                                   </div>

                                  </mat-card-actions>
                          </mat-card>
                    </div>
      </ngx-slick-carousel>

      <div class="flex-row"  style="margin-left:10%">
              <button mat-flat-button  (click)="click('backward')"
                [disabled]="disabled.backward" [color]="'warn'" class="scale-p9"> <mat-icon> skip_previous</mat-icon></button>

             <button mat-flat-button  style='margin-left:5px' (click)="click('forward')"
                  [disabled]="disabled.forward" [color]="'warn'"  class="scale-p9"> <mat-icon > skip_next </mat-icon></button>

                  <button mat-flat-button  style='margin-left:5px' (click)="click('done')"
                        [color]="'primary'"  class="scale-p9"> <mat-icon > done </mat-icon></button>
      </div>
</div>
