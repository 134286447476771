import { Component,  OnInit, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { globalVars,  RouterService} from '../services/global.service';
//import { prodServerPath } from '../services/user.service';
import { LocalStorageService } from '../core/local-storage/local-storage.service'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '../services/user.service';
@Component({
  selector: 'anms-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class videoComponent implements OnInit  {
  @ViewChild('video') video: ElementRef;
  label='';
  thumbs= {up:0, down:0};

  menu = {
    tabs: ["Over view", "train", "Review", "Hints"],
    maps: {
       'Over view': 'overview', 'train': 'train', 'Review': 'review', 'Hints': 'hints',
       0: 'overview', 1: 'train', 2: 'review', 3: 'hints',
    },
    ngmodel: {language: 'None', parent:'None', child: 'None'},
  //  languages: ['English', 'Spanish', 'Mandarin', 'Arabic', 'Farsi'],
    languages: ['English'],
   train: {
     keys: ['intro', 'fundamental', 'customization', 'learning', 'testing', 'rules', 'betting', 'playing'],
     intro: {keys:['intro'], 'intro': 'intro.mp4'},
     customization: {keys:['customization'], 'customization': 'customization.mp4'},
     fundamental: {keys:[ 'intro','single deck', 'double decks', 'six decks'],'intro': 'intro.mp4', 'single deck': 'single.mp4', 'double decks': 'double.mp4', 'six decks': 'six.mp4' },
     learning: {keys:[ 'Count up', 'Net count'],'Count up': 'countup.mp4', 'Net count': 'netcount.mp4' },
     testing:{keys: ['Count up', 'Net count', 'Net count(1)', 'Continuous', 'Certificate'],
             'Customize': 'customize.mp4', 'Count up': 'countup.mp4', 'Net count': 'netcount.mp4',
             'Net count(1)': 'netcountnumbers.mp4',
              'Continuous': 'continuous.mp4',
             'Certificate': 'ceritificate.mp4'},
    rules: {keys:[ 'Classification', 'rules-table'],
                   'Classification': 'classification.mp4',  'rules-table': 'table.mp4'
                   },
    betting: {keys:['summary', 'one-hand', 'two-hands', 'three-hands'], 'summary': 'summary.mp4', 'one-hand': '1hand.mp4',
                'two-hands': '2hands.mp4',  'three-hands': '3hands.mp4'},
    playing: {keys: ['Customize', 'betting', '1-hand', '2-hands', '3-hands'],
                'Customize': 'customize.mp4', 'betting': 'betting.mp4',  '1-hand':'fhand.mp4',  '2-hands': 'shand.mp4'
                      ,  '3-hands': 'thand.mp4'},
    None: {keys:[]},
   },
   overview: {
     keys: ['introduction', 'navigation'],
     introduction: { keys: ['intro-1', 'intro-2', 'casino', 'mobile', 'one day pass','summary'], 'one day pass': 'pass.mp4','mobile': 'smartphone.mp4', 'summary': 'summary.mp4', 'intro-1':'intro.mp4', 'intro-2': 'background.mp4', 'casino': 'casino.mp4'},
     navigation: { keys: ['top', 'buttons'], 'top':'top.mp4', 'buttons': 'buttons.mp4'},
     None: {keys:[]},
   },
   review : {
     counting: {keys:['single deck', 'double decks', 'six decks', 'net count', 'net count(double)'],
           'net count(double)': 'net2.mp4', 'single deck': 'single.mp4', 'double decks': 'double.mp4','net count': 'net.mp4', 'six decks': 'six.mp4'},
     rules: {keys:['rules'], 'rules': 'rule.mp4'},
     betting: {keys:['betting'], 'betting': 'betting.mp4'},
     keys: ['counting', 'rules', 'betting'],
      None: {keys:[]},
   },
   pkeys:[],
   ckeys:[],
  };

  tabSelected='overview';
  mp4: string;
  menuSelected='';
  lang="English";
  path='';
  width=800;
  ///////////////////
  ngModelReset() :void{
    const ngmodel = this.menu.ngmodel;
    for (const key in ngmodel) {
       ngmodel[key]='None';
    }
  }
  //////////////
  videoPath(path:any):any {
    this.path=`/video/${this.lang}/${path}`;
    return this.path;
  }
  ///////////////////
  tabChanged(event:any) :void{
   this.ngModelReset() ;
   const index=event.index;
   this.tabSelected = this.menu.maps[index];
   this.menu.pkeys=this.menu[this.tabSelected].keys;
   if (index==3) {
     this.play(this.videoPath('misc/discalimer.mp4'));
   }
  }
  //////////////////////////
  play(path:any):void {
    let done=false;
    this.httpFileRequest({action: 'get',video: this.path}, (data:any)=> {
       this.thumbs.up= data.liked;
       this.thumbs.down= data.disliked;
       this.video.nativeElement.src = path;
       this.video.nativeElement.load();
//       this.video.nativeElement.play();
     });
  }
///////////////////////
  getPath():any {
     let path;
      switch (this.tabSelected) {
        case 'overview': {
          const pSelection=this.menu.ngmodel.parent;
          const cSelection=this.menu.ngmodel.child;
          const mp4 = this.menu.overview[pSelection][cSelection];
          return this.videoPath(`${this.tabSelected}/${mp4}`);
        }
        case 'train': {
          const pSelection=this.menu.ngmodel.parent;
          const cSelection=this.menu.ngmodel.child;
          const mp4 = this.menu.train[pSelection][cSelection];
          return this.videoPath(`${this.tabSelected}/${pSelection}/${mp4}`);
        }
        case 'review': {
          const pSelection=this.menu.ngmodel.parent;
          const cSelection=this.menu.ngmodel.child;
          const mp4 = this.menu.review[pSelection][cSelection];
          return this.videoPath(`${this.tabSelected}/${mp4}`);
        }
       }
  }
///////////////////////
  selectionChanged(key:any):void { // m is a module
   if (key=='category') {
      this.menu.ckeys=this.menu[this.tabSelected][this.menu.ngmodel.parent].keys;
console.log(`menu.ngmodel.parent: ${this.menu.ngmodel.parent}`);
console.log(this.menu.ckeys);
      return;
   }
   let delay=0;
   if ( this.tabSelected=='overview') {
          this.play(this.getPath());
   } else {
     if (globalVars['credit']==0) {
        this.checkCredit(this.tabSelected);
        delay=3000;
     } else {
          this.play(this.getPath());
     }
   }
}
/////////////////////////
getEmail():any {
// const email= this.localStorageService.getItem('AUTH_MISC')['email'];
 const email = sessionStorage.getItem('email');
 return email;
}
  ////////////////////////
  checkCredit(m:any):void {
// first need to make sure user has logged in
    setTimeout(()=> {
     if (!this.getEmail()) {
        this.ngModelReset();
        alert("You need to sign in to view the videos in this section.");
        return ;
     }
      if (globalVars['credit']==0) {
          this.ngModelReset();
          alert("video-server:You are out of credit, you redirected to subscription page");
          this.routerService.navigate('/subscriptions');
       }  else {
          this.play(this.getPath());
       }
      console.log(`video-component:globalVars['credit']: ${globalVars['credit']}`);
   }, 1000);

  }
  constructor(
   private localStorageService: LocalStorageService,
   private http: HttpClient,
   private userService:UserService,
   private routerService: RouterService ) { }
  ngOnInit() :void{
   if (window.innerWidth < 900 ) this.width=600;
   this.menu.pkeys=this.menu['overview'].keys;
   setTimeout(()=> {
      this.video.nativeElement.addEventListener('loadeddata', ()=> {
console.log(' video was loaded');
           this.video.nativeElement.play();
       });
   }, 1000);

   }
/////////////////////
httpRequest( params:any, callback:any):void {
	/* this function is moved to youtube
  const path = prodServerPath('/api/like/video/');
  const subscribe = this.http.post(path, params).pipe(catchError((err:any)=>{
         return Observable.throw ( err );
     })).subscribe((data: any) => {
        callback(data);
        subscribe.unsubscribe();
     });
    */

}

///////////////
httpFileRequest(params:any, callback:any):void {
 let headers = new HttpHeaders();
 headers.set('range', 'bytes=0-');
 //const path = prodServerPath('/api/like/video/');
 const server = this.userService.getServer();
 const path = `${server}/api/like/video/`;
 //const subscribe = this.http.post(path, params, {headers}).pipe(catchError((err)=>{

 //const subscribe = this.http.post(path, {headers:{'range': 'bytes=0-'}, ...params}).pipe(catchError((err)=>{
 const subscribe = this.http.get(path, params).pipe(catchError((err:any)=>{
         return Observable.throw ( err );
     })).subscribe((data: any) => {
        callback(data);
        subscribe.unsubscribe();
     });
}

 thums(liked:any):void {

//   const email = this.getEmail();
//   if (email) {
// //console.log('making request');
//         this.httpRequest({ action: 'post', video: this.path, liked: liked, email: email}, (data:any)=> {
// //console.log(`receieved liked response`);
//            if (data.status =='duplicate') return;
//            if (data.down) {
//               if (liked) {
//                 this.thumbs.down -=1;
//                 this.thumbs.up +=1;
//               } else {
//                 this.thumbs.up -=1;
//                 this.thumbs.down +=1;
//               }
//            } else {
//               if (liked) {
//                 this.thumbs.up +=1;
//               } else {
//                 this.thumbs.down +=1;
//               }
//            }
//          });
//
//   } else {
// console.log('video::thums email is null');
//   }

 }
/////////////////////
  //////////////////
  languageClick(lang:any):void {
     this.lang=lang;
  }

}
