import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { isJack,MobileRouterService, globalVars,getMethodDesc } from '../../services/global.service';
import { Store } from '@ngrx/store';
import {BaseListComponent} from '../shared/list/component';
import {MobileSendKeyRoute,BottomAppleModify} from '../store/bottom.action.reducer';
import { CommonListAnimations} from '../shared/animations/mobile-animations';
import { GenericTopTopAction,GenericSlideConceptTrain,GenericSettingRestoreFromCach} from '../store/generic.action.reducer';
import {trigger,state,style,transition,animate,keyframes,query,stagger} from '@angular/animations';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { GenericDataUnitEnum, GenericDataUnit} from '../store/generic.model';
import { map, filter } from 'rxjs/operators';
import {KeyPressedDm} from '../store/bottom.model';


@Component({
  selector: 'anms-mobile-adding-strategy',
  templateUrl: './adding.strategy.component.html',
  styleUrls: ['../styles.scss','./adding.strategy.component.scss'],
  animations: [
        trigger('cardFlip', [
          state('default', style({
            transform: 'none'
          })),
          state('flipped', style({
            transform: 'rotateY(180deg)'
          })),
          transition('default => flipped', [
            animate('600ms')
          ]),
          transition('flipped => default', [
            animate('300ms')
          ])
        ])
  ]
})
export class MobileAddingStrategyComponent  implements OnDestroy, OnInit  {
//  @ViewChild('countStepper') private countStepper: MatStepper;
  // subscription = {};
    @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  subscriptions=[];
  vars=globalVars;
  disabled={forward:false, backward:true};
  method=globalVars['method'];
  margin={top:10};
    state: 'default'| 'flipped' ='default';
		settingCurrentlyActive=false;
  statements= {
    ten: [
      [// one set
        "Count starts at maximum number of high cards in the deck",
        "Single deck 20, double decks 40, six decks 120",
        "subtract 1 for each high-cards",
        "press next->"
       ],
       [ // second set
         "Single deck Count starts at '20'",
         "Note, the counts are in paranthesis",
         "Assume upcoming cards are: '3(20), 4(20), Ace(19), 10(18)'",
        "press next->"
       ],
       [ // second set
         "Double decks Count starts at '40'",
         "Note, the counts are in paranthesis",
         "Assume upcoming cards are: '3(40), 4(40), Ace(39), 10(38)'",
        "press next->"
       ],
       [ // second set
         "Six decks Count starts at '120'",
         "Note, the counts are in paranthesis",
         "Assume upcoming cards are: '3(120), 4(120), Ace(119), 10(118)'",
        "press next->"
       ],
    ],
    mit:[
          [// one set
            "Count starts at '0'",
            "add 1 for each low-cards",
            "subtract 1 for each high-cards",
            "press next->"
           ],
           [ // second set
             "Count starts at '0'",
             "Note, the counts are in paranthesis",
             "Assume upcoming cards are: '3(1), 4(2), Ace(1), 10(0)'",
            "press next->"
           ],
           [ // second set
             "Count starts at '0'",
             "Note, the counts are in paranthesis",
             "Assume upcoming cards are: 'Ace(-1), 10(-2), 4(-1), 5(0), 6(1)'",
           ],

         ],
    advance:[
           [
             "Note, the count starts a t '0'",
             "For every low or high cards add 1 to the count",
             "If the count contains only the low cards label it as 'L'",
             "and when it contains only the high cards label it as 'H'",
             "If the count contains mixture of low and high cards then,",
             "Use space to join them, High count <space> low count",
             "Example 2 high cards followd by 4 low cards",
             "gives the count of '2 4' not '24'",
             "press next->"
           ],

           [
             "Example of only low cards are:",
             " the counts are in paranthesis.",
             "Cards: 2(1L), 3(2L), 4(3L), 5(4L), 6(5L), 7(5L)",
              "press next->"
           ],
           [
             "Example of only high cards are:",
             " the counts are in paranthesis.",
             "Cards: Ace(1H), King(2H), Queen(3H), Jack(4H), 10(5H), 7(5H)",
              "press next->"
           ],

            [
              "Example of mixture of low and high cards",
              "Note the space is in your head, you don't announce it",
              "Cards: 2(1L), Ace(1 1), 3(1 2)",
              "press next->"
            ],
            [
              "Example of mixture of low and high cards",
              "Note the space is in your head, you don't announce it",
              "Cards: Ace(1H), 2(1 1), 3(1  2), 10(2 2)"

            ],

       ]


  }

  slidesCount=0;
  slideConfig= {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    arrows:false,
    "infinite": false,
     'swipeToSlide': true,

  };
  settingWasActive=false;
  constructor(
    public store: Store<any>,
     private router: MobileRouterService
            ) {
	      // globalVars['state'] = 'train-concept';
        // super(store);
      }

  ngOnDestroy() :void{
     for (const elm of this.subscriptions)
          elm.unsubscribe();
    MobileSendKeyRoute(this.store,[{key:"reset"}]);
   }
   flipit() {
       this.state= this.state=='default'? 'flipped': 'default';
   }
   clicked(key) {
     if (key=='forward') {
        this.slickModal.slickNext();
        return;
     }
     this.slickModal.slickPrev();
   }
   afterChange(event:any):void {
       if (!event.currentSlide) {
         this.disabled.forward=false;
         this.disabled.backward=true;
         return;
       }
       if ((this.slidesCount-1)==event.currentSlide) {
         this.disabled.forward=true;
         this.disabled.backward=false;
         return;
       }
       this.disabled.forward=false;
       this.disabled.backward=false;
   }
   istate() {
        let note='';
        let difficulty;
        this.method=globalVars['method'];
        const method=getMethodDesc('concept');
        if (isJack()) {
            note="Add 1 for any high or low cards";
            this.slidesCount=this.statements.advance.length;
            this.margin.top=10;
        } else if (globalVars['method']=='mit') {
          note="Add 1 for any low and -1 for any high card";
          this.slidesCount=this.statements.mit.length;
          this.margin.top=25;
        }
        else {
            note="Add 1 for any high cards";
            this.slidesCount=this.statements.ten.length;
            this.margin.top=25;
          }

        GenericTopTopAction(this.store, [method, note], 'tigger');
        BottomAppleModify(this.store, { enable:['backward','lastIcon'], disable:['forward'],lastIcon:'settings'});
   }

   tensDesc() {
     const count=globalVars['ndecks'] * 20;
     this.statements.ten.push(
       [// one set
          `Count starts at '${count}'`,
          "subtract 1 for each high-cards",
          "press next->"
         ],
         [ // second set
           `Count starts at '${count}'`,
          " Assume upcoming cards are: '3, 4, Ace, 10'",
          " the 3 and 4 are ignored",
          `subtract 1 for the Ace, the count becomes ${count-1}`,
          `subtract 1 for the 10, the count becomes ${count-2}`,
         ],
     )
   }
   ngOnInit() :void{
        globalVars['vpath']="concept/adding-count";
        if (globalVars['method']=='ten') this.tensDesc();
      this.istate();
        this.subscriptions.push(
            this.store
              .select('mobileTopBottom')
              .pipe(filter(data => data.typeof=== 'KeyPressedDm' && data.target=='child'))
              .subscribe((data: KeyPressedDm) => {
                  switch(data.key) {
                    case 'home' : {
                        setTimeout(() => {
                          this.router.navigate('mobile/home');
                        }, 200);
                      break;
                    }
                    case 'backward' : {
                       if(this.settingCurrentlyActive) { return; }
                           setTimeout(() => {
                             this.router.navigate('mobile/train');
                           }, 200);
                         break;
                       } case 'settings': {
                          // this.istate();
                           globalVars['setting'] = {
                            func: GenericSlideConceptTrain,
                            route:'mobile/adding-strategy'
                          };
                           this.router.navigate('mobile/setting-train');
                           break;
                       }
                  }
                           })
          );
         this.subscriptions.push(
               this.store
                 .select('genericReducer')
                 .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
                 .subscribe((data: GenericDataUnit) => {
                   if (data.action==GenericDataUnitEnum.die) {
                     return;
                   }


                 }
               )
          );




      }





  }
