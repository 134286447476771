 <div  style="margin-top:10px;margin-left:{{gvars['center-width']}}px;" >

     <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"  >
       <div ngxSlickItem class="slide">
         <mat-card class="example-card">
              <mat-card-actions>

                <div class='flex-column' *ngIf="gvars['mobileDevice'] else desktop">

                 <mat-table  [dataSource]="source">
                       <ng-container  *ngFor="let col of columns" [matColumnDef]="col" >
                         <mat-header-cell *matHeaderCellDef > {{col}} </mat-header-cell>
                         <mat-cell *matCellDef="let element">
                               <button mat-raised-button    class="{{mouseClass[element[col]]}}" (click)="clicked(element[col])">  {{element[col]}}</button>

                         </mat-cell>
                       </ng-container>
                       <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                       <mat-row *matRowDef="let row; columns: columns;"></mat-row>
                 </mat-table>
             </div>
             <ng-template #desktop>
                     <div class='flex-column'>
                       <ng-container *ngFor="let button of buttons">
                           <button mat-raised-button
                                  class="{{mouseClass[button]}} margin-top-10px curvey-border" (click)="clicked(button)">{{button}}</button>
                         </ng-container>


                  </div>
              </ng-template>
            </mat-card-actions>
        </mat-card>
       </div>

     </ngx-slick-carousel>
 </div>
