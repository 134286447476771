import { Component, OnInit, OnDestroy } from '@angular/core';
import { MobileRouterService, globalVars, decksMapping} from '../../services/global.service';
import { Store } from '@ngrx/store';
import { GenericTopTopAction,GenericSlideBetTrain,GenericSlideConceptTrain,GenericSettingRestoreFromCach} from '../store/generic.action.reducer';
import { GenericDataUnitEnum, GenericDataUnit} from '../store/generic.model';
import {trigger,state,style,transition,animate,keyframes,query,stagger} from '@angular/animations';
import { map, filter } from 'rxjs/operators';
import {KeyPressedDm} from '../store/bottom.model';
import {BottomAppleModify} from '../store/bottom.action.reducer';

enum SettingActiveEnum {
	active,
	idle
};
@Component({
  selector: 'anms-bet-summary',
  templateUrl: './bets.component.html',
  styleUrls: ['./bets.component.scss'],
  animations: [
      trigger('cardFlip', [
        state('default', style({
          transform: 'none'
        })),
        state('flipped', style({
          transform: 'rotateY(180deg)'
        })),
        transition('default => flipped', [
          animate('600ms')
        ]),
        transition('flipped => default', [
          animate('300ms')
        ])
      ])
    ]
})


export class MobileBetComponent  implements OnDestroy, OnInit  {
//  @ViewChild('countStepper') private countStepper: MatStepper;
  // subscription = {};
  //columns=['Count', 'High count', 'Low count', 'Net count', 'Spread', 'comments'];
  subscriptions=[];
  vars=globalVars;
  table: any;
  enabled = false;
  settingWasActive=false;
  columns = [	'type', 'hand', 'won', 'lost', 'net', 'pcnt'];
  tables={

 'count-10': [
    {type: 'one-hand', hand: 'first', 'won': 483, 'lost': 518, 'net': -35, 'pcnt': '-3%'},
    {type: 'two-hands', hand: 'first', 'won': 505, 'lost': 509, 'net': -4, 'pcnt': '0%'},
    {type: 'two-hands', hand: 'second', 'won': 504, 'lost': 511, 'net': -7, 'pcnt': '-1%'},
    {type: 'three-hands', hand: 'first', 'won': 485, 'lost': 500, 'net': -15, 'pcnt': '-2%'},
    {type: 'three-hands', hand: 'second', 'won': 502, 'lost': 484, 'net': 18, 'pcnt': '2%'},
    {type: 'three-hands', hand: 'third', 'won': 494, 'lost': 503, 'net': -9, 'pcnt': '-1%'},
 ],
 'count-12': [
    {type: 'one-hand', hand: 'first', 'won': 437, 'lost': 523, 'net': -86, 'pcnt': '-9%'},
    {type: 'two-hands', hand: 'first', 'won': 513, 'lost': 487, 'net': 26, 'pcnt': '3%'},
    {type: 'two-hands', hand: 'second', 'won': 493, 'lost': 484, 'net': 9, 'pcnt': '1%'},
    {type: 'three-hands', hand: 'first', 'won': 464, 'lost': 506, 'net': -42, 'pcnt': '-4%'},
    {type: 'three-hands', hand: 'second', 'won': 524, 'lost': 495, 'net': 29, 'pcnt': '3%'},
    {type: 'three-hands', hand: 'third', 'won': 504, 'lost': 475, 'net': 29, 'pcnt': '3%'},
 ],
 'count-14': [
    {type: 'one-hand', hand: 'first', 'won': 443, 'lost': 518, 'net': -75, 'pcnt': '-8%'},
    {type: 'two-hands', hand: 'first', 'won': 497, 'lost': 472, 'net': 25, 'pcnt': '3%'},
    {type: 'two-hands', hand: 'second', 'won': 472, 'lost': 477, 'net': -5, 'pcnt': '-1%'},
    {type: 'three-hands', hand: 'first', 'won': 516, 'lost': 471, 'net': 45, 'pcnt': '5%'},
    {type: 'three-hands', hand: 'second', 'won': 517, 'lost': 473, 'net': 44, 'pcnt': '4%'},
    {type: 'three-hands', hand: 'third', 'won': 496, 'lost': 477, 'net': 19, 'pcnt': '2%'},
 ],
};




  ndecks = globalVars['ndecks'];
  slideConfig :any= {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "dots": false,
   "infinite": false,
   'showArrow': false,
   'swipeToSlide': false,
   "arrows": false

 };
 state: 'default'| 'flipped' ='default';
 die=false;
 settingCurrentlyActive=false;
  constructor(
    public store: Store<any>,
     private router: MobileRouterService
            ) {
	      // globalVars['state'] = 'train-concept';
        // super(store);
      }

  ngOnDestroy() :void{
     for (const elm of this.subscriptions)
          elm.unsubscribe();
   }
   flipit() {
       this.state= this.state=='default'? 'flipped': 'default';
   }
   afterChange(event:any):void {
   }
   clicked(event) {
     //console.log(event.tab.textLabel)
   }
   // get(deck, strength, hand) DataIf [] {
   //   return this.tables[deck][strength][hand];
   // }
	 istate() {
				const msg=[`Betting numbers for double-decks`, 'For running-count of 10, 12 and 14'];
				GenericTopTopAction(this.store, msg);
				BottomAppleModify(this.store, { enable:['backward','lastIcon'], disable:['forward'],  lastIcon:'settings'});
				//BottomAppleModify(this.store, { enable:['backward','lastIcon'],  disable:['forward']});
	 }
   ngOnInit() :void{
		 this.istate();
     this.subscriptions.push(
         this.store
           .select('mobileTopBottom')
           .pipe(filter(data => data.typeof=== 'KeyPressedDm'))
           .subscribe((data: KeyPressedDm) => {
						   if (data.target=='parent') {
								  this.die=true;
									return;
							 }
               switch(data.key) {
								 case 'home' : {
										 this.die=true;
										 globalVars['isAuthenticated'] = false;
										 setTimeout(() => {
											 this.router.navigate('mobile/home');
										 }, 200);
									 break;
								 }
                 case 'backward' : {
                    if(this.settingCurrentlyActive) { return; }
                        this.die=true;
                        setTimeout(() => {
                          this.router.navigate('mobile/train');
                        }, 200);
                      break;
                    } case 'settings': {

												globalVars['setting'] = {
												 func: GenericSlideBetTrain,
												 route:'mobile/bets'
											 };
												this.router.navigate('mobile/setting-train');
                        break;
                    }
               }
                        })
       );
      this.subscriptions.push(
            this.store
              .select('genericReducer')
              .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
              .subscribe((data: GenericDataUnit) => {
                if (data.action==GenericDataUnitEnum.die) {
                  this.die=true;
                  return;
                }
                if (data.action==GenericDataUnitEnum.settingDone) {
                      this.istate();
                      this.flipit();
											this.settingCurrentlyActive=false;

                }

              }
            )
       );

    }
  }
