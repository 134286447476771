import { Component, OnInit, OnDestroy,EventEmitter,Output} from '@angular/core';
import { globalVars} from '../../services/global.service';
import { GenericDataUnitEnum, GenericDataUnit} from '../store/generic.model';
import {GenericTimerResetThumbAction} from '../store/generic.action.reducer';
import {  map, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {Shuttle} from '../shared/classes/common';


@Component({
  selector: 'anms-mobile-timer',
  templateUrl: './timer.component.html',
  // styleUrls: [],
})
export class TimerComponent implements OnInit, OnDestroy{
  @Output() timerEvent = new  EventEmitter();
  tick1sec:Shuttle;
  timer:any;
  button="stop";
  subscriptions=[];
  allowToggle=true;
  disabled=false;
  label="00:00";


  clicked(key="ctrl") {
    // if (key=='reset') {
    //    this.timer=0;
    //    GenericTimerResetAction(this.store);
    //    return;
    // }
    // this.tick1sec.toggle(); // stop the ticker
    // this.button=this.button=='stop'? 'start': 'stop';
    // this.timer = 0;
     GenericTimerResetThumbAction(this.store);
     setTimeout(() => {
       this.timer=0;
     }, 1000);

     return;

  }

  transform(val) {
    return val<10 ?`0${val}`: `${val}`;
  }
  toString() {
    const minutes = Math.floor(this.timer/60);
    const seconds= this.timer - minutes*60;
    this.label= `${this.transform(minutes)}:${this.transform(seconds)}`;
    // this.time=`${min} min. ${sec} sec`;
  }

  startTimer(timer, upDown):void {  // timer is seconds
    // start 1sec timer
    // add the callback to decrement time
    // extract the digit from timer and added to license.timer
    this.timer=timer;
    if (!this.allowToggle) { // restart the counter; the counter was stopped by time-out
      this.allowToggle=true;
      this.tick1sec.start();
      return;
    }

    this.tick1sec.add(
      upDown==GenericDataUnitEnum.downtimer?
      ()=> {
        if (this.timer)  {
          this.timer--;
          this.toString();
          return;
        } // timer has expired

        if (this.allowToggle) {
          this.timerEvent.emit('');
          this.clicked();
          this.allowToggle=false;
          return;
        }

      }
      : ()=> {
        this.timer++;
        this.toString();
      }
    );
  }


  constructor(private store:Store<any>) {  }


   ngOnInit():void {
     this.tick1sec = new Shuttle(1000);
     this.subscriptions.push(
           this.store
             .select('genericReducer')
             .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
             .subscribe((data: GenericDataUnit) => {
               // console.log(`rcvd timer:${JSON.stringify(data)}`)
                if (data.action==GenericDataUnitEnum.timer) {
                   const value= data.value;
                   let sec=0;
                   const tick=value['tick'];
                   if ('min' in value) {
                     sec+=value['min']*60;
                   } if ('sec' in value) {
                     sec+=value['sec'];
                   } if ('disable' in value) {
                     this.disabled=value['disable'];
                   }

                   if (tick==GenericDataUnitEnum.stoptimer || tick==GenericDataUnitEnum.starttimer ) {
                       this.clicked();
                       return;
                   }
                   this.startTimer(sec, value['tick']);
                   return;
                }
             }
           )
      );
   }

   ngOnDestroy() {
     for (const subs of this.subscriptions) {
       subs.unsubscribe();
     }
     if (this.tick1sec)
         this.tick1sec.destroy();
   }
}
