import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {trigger,state,style,transition,animate} from '@angular/animations';
import { Store } from '@ngrx/store';
import { GenericDataUnitEnum, GenericDataUnit} from '../../store/generic.model';
import { filter, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'anms-thumb',
  templateUrl: './component.html',
  styleUrls: [],
  animations:[
    trigger('rotateUp', [
    transition('void=>*',[style({transform:'rotate(0deg)'})]),
    transition ('* => *', [
      style({ transform: 'rotate(0deg)' }),
         animate ('600ms',style ({ transform: 'rotate(-25deg)' }))
    ])
  ]),
  trigger('rotateDown', [
  transition('void=>*',[style({transform:'rotate(0deg)'})]),
  transition ('* => *', [
    style({ transform: 'rotate(0deg)' }),
       animate ('600ms',style ({ transform: 'rotate(25deg)' }))
  ])
])
]

})



export class ThumbComponent implements OnInit {

  @Input() label='up';
  @Input() color='accent';
  icon='';
  value=-1;
  _value=0;
  subscription: any;
  constructor(public store: Store<any>) { }

   incr() {
    this._value++;
  }
done() {
  setTimeout(() => {
      this.value++;
  }, 200);

}
///////////////////////
  ngOnInit():void {
        this.icon=this.label=='up'? 'thumb_up': 'thumb_down';
        this.subscription =
                 this.store
                   .select('genericReducer')
                   .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
                   .subscribe((data: GenericDataUnit) => {

                       if (data.action==GenericDataUnitEnum.timerThumbReset) {
                             this.value=0;
                           return;
                         }
                   }

            );

  }
    ngOnDestroy():void {
      this.subscription.unsubscribe();
    }

}
